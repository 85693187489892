import React, { useRef } from "react";
import styles from "./contact.module.css";
import emailjs from "@emailjs/browser";

const bg = require("../../assets/new contact.png");
const bg1 = require("../../assets/contactc.jpg")
const email = require("../../assets/Untitled-1.png");
const fax = require("../../assets/fax.png");
const phone = require("../../assets/phon.png");

export default function Contact(params) {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_476s3ls",
        "template_ciqimjj",
        form.current,
        "bqX1ayzGcyH8IQTbM"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="container" style={{ marginTop: "3%" }}>
        <div className="row">
          <h1 className={styles.font_}>Quel est votre projet ?</h1>
          <p className={styles.font_p} style={{ fontSize: "20px" }}>
            Pour vous aider à trouver les meilleures solutions et faire les bons
            choix de produits,
            <br />
            notre équipe vous accompagne et met toute la puissance qu’il faut
            derrière votre process…
          </p>
        </div>
      </div>

      <div className="container" style={{ marginTop: "5%" }}>
        <div className="row ">
          <div className="col-sm">
            <div>
              <img src={email} style={{ float: "left" }} />
              <p
                className={styles.font2}
                style={{ float: "right", marginTop: "2%", marginRight: "32%" }}
              >
                info@approvisionneur.com
              </p>
            </div>
          </div>
          <div className="col-sm">
            <div>
              <img src={fax} style={{ float: "left" }} />
              <p
                className={styles.font2}
                style={{
                  float: "right",
                  marginTop: "1.5%",
                  marginRight: "45%",
                }}
              >
                +212 522 446 942
              </p>
            </div>
          </div>
          <div className="col-sm">
            <div>
              <img src={phone} style={{ float: "left" }} />
              <p
                className={styles.font2}
                style={{ float: "right", marginTop: "-1%", marginRight: "45%" }}
              >
                +212 522 440 884 /<br /> +212 522 307 746
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="container "
        style={{ width: "100%", height: "100%", marginTop: "5%" }}
      >
        <div class="row justify-content-between gy-4">
          <div class="col-lg-6 d-flex align-items-center" data-aos="fade-up">
            <img src={bg1} style={{ width: "100%" }} className={styles.anima} />
          </div>

          <div class="col-lg-5" data-aos="fade">
            <form
              ref={form}
              onSubmit={(ev) => {
                sendEmail(ev);
              }}
              enctype="multipart/form-data"
              class="php-email-form"
            >
              <div class="blue-gradient">
                <div class="row gy-3">
                  <div class="col-md-12">
                    <input
                      type="text"
                      name="fname"
                      class="form-control"
                      placeholder="Prénom"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <input
                      type="text"
                      name="lname"
                      class="form-control"
                      placeholder="Nom"
                      required
                    />
                  </div>

                  <div class="col-md-12 ">
                    <input
                      type="email"
                      class="form-control"
                      name="user_email"
                      placeholder="Email"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="user_phone"
                      placeholder="Phone"
                    />
                  </div>
                  <div class="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      name="entr"
                      placeholder="Entreprise"
                    />
                  </div>
                  <div class="col-md-12">
                    <input
                      type="text" 
                      class="form-control"
                      name="product"
                      placeholder="Product"
                    />
                  </div> 
                  <div class="col-md-12">
                    <textarea
                      class="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn"
                      style={{
                        width: "30%",
                        background: "#2B4A99",
                        color: "white",
                      }}
                    >
                      ENVOYER
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <div class="row mb-5">
                <div class="col-12 wow fadeInUp" data-wow-delay="0.1s">
                    <iframe class="w-100"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                        frameborder="0" style={{min: "450px", border:"0"}} allowfullscreen="" aria-hidden="false"
                        tabindex="0"></iframe>
                </div>
            </div> */}
    </div>
  );
}
