import React from "react";

const img = require("../../assets/test.jpg");

export default function societe(params) {
  return (
    <div style={{ marginTop: "3%", padding: "2%" }}>
      <div class="row">
        <div class="col-md-6 how-img">
          <img
            src={img}
            style={{ width: "90%", height: "60%", marginLeft: "2%" }}
            alt=""
          />
        </div>
        <div class="col-md-6">
          <h4>A propos de nous</h4>

          <p class="text-muted">
            L'Approvisionneur Technique est une société d'achat qui fournit des
            services d'approvisionnement technique. L'entreprise se spécialise
            dans la recherche et l'achat de produits, de matériaux et
            d'équipements nécessaires à divers projets techniques et
            industriels. Elle travaille avec ses clients pour comprendre leurs
            besoins et exigences spécifiques, puis utilise son expertise et son
            réseau de fournisseurs pour trouver les meilleurs produits aux
            meilleurs prix. L'Approvisionneur Technique s'engage à fournir des
            produits et des services de haute qualité à ses clients.
            L'entreprise s'efforce d'établir des relations durables avec ses
            clients et ses fournisseurs, et s'engage à fournir des produits et
            des services qui répondent ou dépassent les attentes de ses clients.
            En outre, l'entreprise s'attache à assurer la livraison des produits
            et des services dans les délais impartis, et dispose des outils de
            logistique et de gestion de la chaîne d'approvisionnement
            nécessaires pour garantir que les produits sont livrés à temps et en
            bon état.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h4>Notre équipe</h4>

          <p class="text-muted">
            Notre équipe de professionnels expérimentés se consacre à fournir
            des solutions d'achat de qualité pour les produits techniques. Notre
            équipe est composée d'experts ayant une connaissance approfondie des
            aspects techniques des produits que nous fournissons, ainsi que du
            processus d'achat. Nous sommes fiers de notre capacité à nous
            procurer des produits de haute qualité auprès de fournisseurs
            réputés, afin de garantir à nos clients les meilleurs produits
            possibles à des prix compétitifs. Notre équipe se tient au courant
            des derniers développements de l'industrie, ce qui nous permet
            d'offrir des solutions innovantes et rentables qui répondent aux
            besoins changeants de nos clients. À L'Approvisionneur Technique,
            nous croyons qu'il faut établir des relations solides avec nos
            clients, nos fournisseurs et nos partenaires. Notre équipe est
            toujours disponible pour répondre à toutes vos questions, vous
            offrir un soutien et assurer une expérience d'achat sans difficulté.
            Nous nous engageons à fournir un excellent service à la clientèle et
            à veiller à ce que tous nos clients soient satisfaits des produits
            et services que nous leur fournissons. Que vous cherchiez à acheter
            un seul article ou que vous ayez besoin d'une solution
            d'approvisionnement complète, L'Approvisionneur Technique possède
            l'expertise et les ressources nécessaires pour répondre à vos
            besoins. Contactez-nous dès aujourd'hui pour savoir comment nous
            pouvons vous aider à rationaliser votre processus d'achat et à
            obtenir les meilleurs produits possibles pour vos besoins
            techniques.
          </p>
        </div>
        <div class="col-md-6 how-img">
          <img src={img} style={{ width: "90%", height: "60%" }} alt="" />
        </div>
      </div>
    </div>
  );
}
