import { Link } from "react-router-dom";
import styles from "./product.module.css";

const card2 = require("../../assets/bg-labo.png");
const card1 = require("../../assets/maxresdefault.jpg");
const card3 = require("../../assets/bg-elec.png");
const card4 = require("../../assets/bg-port.png");
const card5 = require("../../assets/bg-epi.png")
const png1 = require("../../assets/electro-process.gif")
const png2 = require("../../assets/INSTRUMENTATION.gif")
const png3 = require("../../assets/PORTABLE.gif")
const png4 = require("../../assets/SAFETY1.gif")
const png5 = require("../../assets/chimique.gif")
const png6 = require("../../assets/LABORATOIRE.gif")


export default function Product(params) {
  return (
    <div className={styles.divise}>
      <h1 className={styles.font_} style={{ textAlign: "center" }}>
        Nos produits
      </h1>
      <h4 className={styles.second_font} style={{ textAlign: "center" }}>
        Améliorer la productivité à chaque étape du cycle de vie de l’automation
      </h4>
      <hr
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
          width: "50%",
        }}
      />
      <div class="container" style={{ marginTop: "5%" }}>
        <div class="row">
          <div class="col-md-4">
            <div class="card" style={{ backgroundColor: "#2B4A99" }} >
              <img className="lazy" src={png1} style={{ width: "100%", padding: "2%" }} />
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ textAlign: "center", color: "white" }}
                >
                  Les équipements process
                </h5>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link to="/equipement-process" class="btn btn-primary" style={{marginTop:"7%"}}>
                    EN SAVOIRE PLUS
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card" style={{ backgroundColor: "#2B4A99" }}>
              <img className="lazy" src={png6} style={{ width: "100%", padding: "2%" }} />
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ textAlign: "center", color: "white" }}
                >
                  Les équipements de laboratoire
                </h5>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link to="/equipements-laboratoire" class="btn btn-primary" style={{marginTop:"7%"}}>
                    EN SAVOIRE PLUS
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 ">
            <div class="card" style={{ backgroundColor: "#2B4A99" }}>
              <img className="lazy" src={png2} style={{ width: "100%", padding: "2%" }} />
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ textAlign: "center", color: "white" }}
                >
                  Les équipements électriques, d'instrumentations et d'automatisme
                </h5>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link to="/equipement-instrumenstation" class="btn btn-primary">
                    EN SAVOIRE PLUS
                  </Link>
                </div>
              </div>
            </div>  
          </div>
        </div>
        <div class="row" style={{ marginTop: "2%" }}>
          <div class="col-md-4">
            <div class="card" style={{ backgroundColor: "#2B4A99" }}>
              <img className="lazy" src={png3} style={{ width: "100%", padding: "2%" }} />
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ textAlign: "center", color: "white" }}
                >
                  L’appareillage portatif et outillage de mesure et d'analyse
                </h5>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link to="/cat4" class="btn btn-primary">
                    EN SAVOIRE PLUS
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card" style={{ backgroundColor: "#2B4A99" }}>
              <img className="lazy" src={png5} style={{ width: "100%", padding: "2%" }} />
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ textAlign: "center", color: "white" }}
                >
                  Les produits chimiques et consommables divers
                </h5>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link to="/chimique-diver" class="btn btn-primary">
                    EN SAVOIRE PLUS
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div class="card" style={{ backgroundColor: "#2B4A99" }}>
              <img className="lazy" src={png4} style={{ width: "100%", padding: "2%" }} />
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ textAlign: "center", color: "white" }}
                >
                  Equipements de protection individuelle (EPI) ET Panneaux de signalisation
                </h5>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Link to="/epi" class="btn btn-primary">
                    EN SAVOIRE PLUS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
