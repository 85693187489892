import React from "react";
import styles from "./test.module.css";

const img = require("../../assets/erf.png");

export default function Flex(params) {
  return (
    <div style={{ marginTop: "5%" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 how-img">
            <img src={img} style={{ width: "100%" }} />
            <a
              className={styles.top_right}
              href="#ed1"
              style={{ marginTop: "2%",opacity:"0%" }}
            >
              SAV
            </a>
            <a
              className={styles.top_right1}
              href="#ed2"
              style={{ marginTop: "2%",opacity:"0%" }}
            >
              prix
            </a>
            <a
              className={styles.top_right2}
              href="#ed3"
              style={{ marginTop: "2%",opacity:"0%" }}
            >
              flex
            </a>
            <a
              className={styles.top_right3}
              href="#ed4"
              style={{ marginTop: "2%",opacity:"0%" }}
            >
              quality
            </a>
            <a
              className={styles.top_right4}
              href="#ed5"
              style={{ marginTop: "2%",opacity:"0%" }}
            >
              detai
            </a>
          </div>
          <div className="col-md-6">
            <div id="ed1" className={styles.tab}>
              <div className="col">
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#ABD473",
                  }}
                >
                  SAV
                </h2>
                <div
                  className={styles.animated_quote}
                  style={{
                    marginTop: "2%",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <blockquote className={styles.blockquote}>
                    <p style={{ fontSize: "18px" }}>
                      Le service client est au cœur de toutes nos activités.
                      Notre équipe de professionnels expérimentés est disponible
                      pour répondre à toutes vos questions et résoudre toutes
                      les situations qui peuvent survenir avec vos achats.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>

            <div id="ed2" className={styles.tab}>
              <div className="col">
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#ABD473",
                  }}
                >
                  PRIX
                </h2>
                <div
                  className={styles.animated_quote}
                  style={{
                    marginTop: "2%",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <blockquote className={styles.blockquote}>
                    <p style={{ fontSize: "18px" }}>
                      Nous comprenons l'importance de la réduction maximale des
                      coûts pour votre entreprise. C'est pourquoi nous offrons
                      un avantage tarifaire unique à nos clients.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>

            <div id="ed3" className={styles.tab}>
              <div className="col">
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#ABD473",
                  }}
                >
                  FLEXIBILITE
                </h2>
                <div
                  className={styles.animated_quote}
                  style={{
                    marginTop: "2%",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <blockquote className={styles.blockquote}>
                    <p style={{ fontSize: "18px" }}>
                      Nous comprenons que chaque client a des besoins
                      spécifiques en matière d'achat. C'est pourquoi nous
                      offrons des solutions flexibles qui peuvent être adaptées
                      à vos besoins uniques.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>

            <div id="ed4" className={styles.tab}>
              <div className="col">
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#ABD473",
                  }}
                >
                  QUALITE
                </h2>
                <div
                  className={styles.animated_quote}
                  style={{
                    marginTop: "2%",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <blockquote className={styles.blockquote}>
                    <p style={{ fontSize: "18px" }}>
                      Nous comprenons que la qualité est un facteur essentiel
                      dans toute décision d'achat. C'est pourquoi nous nous
                      efforçons de fournir à nos clients des produits qui
                      respectent et dépassent les normes industrielles.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div id="ed5" className={styles.tab}>
              <div className="col">
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#ABD473",
                  }}
                >
                  DELAI
                </h2>
                <div
                  className={styles.animated_quote}
                  style={{
                    marginTop: "2%",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <blockquote className={styles.blockquote}>
                    <p style={{ fontSize: "18px" }}>
                      Grâce à notre vaste réseau logistique et à nos systèmes de
                      transport de pointe, nous pouvons vous garantir que vos
                      commandes vous parviendront dans les délais les plus
                      courts possibles.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
