import styles from "./style.module.css";

const pic1 = require("../../assets/chimique-.gif");

export default function Chqdevir(params) {
  return (
    <>
      <div className="container">
        <div className={styles.divise}>
          <div style={{ textAlign: "center" }}>
            <h1 className={styles.font_}>Produits chimiques</h1>
            
            <hr
              style={{
                width: "50%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
        </div>
        <div>
              <h2
                className={styles.font}
                style={{ textAlign: "center", marginTop: "3%" }}
              >
                Découvrez notre large sélection d'agitateurs de haute qualité
                sur le site de notre approvisionneur technique
              </h2>
            </div>
            <div class="row g-4 mt-5">
              <div>
                <p style={{ fontSize: "20px" }}>
                La société APPROVISIONNEUR TECHNIQUE INTERNATIONAL SA offre une
              large sélection de produits chimiques adaptés à diverses
              industries telles que la chimie, l'agriculture, les phosphates et
              le traitement des eaux.
                </p>
              </div>
              <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div
                  className={styles.team_item}
                  style={{
                    width: "60%",
                    height:"60%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{
                      padding: "10px",
                      border: "1px solid #abd473",
                      marginTop: "",
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "1" }}
                      src={pic1}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <ul className={styles.ul}>
                  <div className="row">
                    <li className={styles.li}>Floculant et coagulants</li>
                    <li className={styles.li}>Agents de clarification/adjuvants de filtration</li>
                    <li className={styles.li}>Anti mousses</li>
                    <li className={styles.li}>Catalyseurs</li>
                    <li className={styles.li}>Agents décolorants</li>
                    <li className={styles.li}>Agents de congélation et de refroidissement par contact</li>
                    <li className={styles.li}>
                      Agents de dessiccation/antiagglomérants
                    </li>
                    <li className={styles.li}>Enzymes</li>
                  </div>
                </ul>
              </div>
              {/* <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={ag_lit}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>
                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={ag_fond}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={ag_con}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div> */}
            </div>
        
      </div>
    </>
  );
}
