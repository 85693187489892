import { Breadcrumbs, Typography } from "@mui/material";
import styles from "./epi.module.css";
import { Link } from "react-router-dom";

const pro = require("../../assets/pro.png");
const pro1 = require("../../assets/pro1.png");
const pro2 = require("../../assets/pro2.png");
const pro3 = require("../../assets/pro3.png");
const pro4 = require("../../assets/yeux.png");
const pro5 = require("../../assets/main.png");
const pro6 = require("../../assets/voies_.png");
const pro7 = require("../../assets/kit.png");

export default function Epi(params) {
  return (
    <div>
      <div className={styles.divise}>
        <div style={{ textAlign: "center" }}>
          <h1 className={styles.font_}>
           Équipements de protection individuelle (EPI)
            <br /> et Panneaux de signalisation
          </h1>
          <br />
          <h2 className={styles.second_font}>
            L'APPROVISIONNEUR TECHNIQUE INTERNATIONAL SA vous présente une gamme
            complete des équipements de protection individuelle (EPI) et
            panneaux de signalisation
          </h2>
          <hr
            style={{
              width: "50%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <Breadcrumbs
            aria-label="breadcrumb"
            separator=">"
            sx={{
              "& ol": {
                justifyContent: "center",
                margin: "auto",
              },
            }}
          >

            <Link to="/epi" style={{ textDecoration: "none", color: "#3367ec" }}>
             Produits
            </Link>
    
            <Typography color="text.primary">
              Equipements de protection individuelle
            </Typography>

          </Breadcrumbs>
        </div>
      </div>
      <div class="container-xxl py-6">
        <div class="container">
          <div
            class="mx-auto text-center wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          ></div>
          <div class="row g-4 mt-5">
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <a href="#p1" style={{ textDecoration: "none" }}>
                <div className={styles.team_item}>
                  <h5
                    className={styles.team_item_h5}
                    style={{ color: "black" }}
                  ></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{
                      padding: "10px",
                      border: "1px solid #abd473",
                      marginTop: "",
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "1" }}
                      src={pro1}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center"></div>
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <a href="#p2" style={{ textDecoration: "none" }}>
                <div className={styles.team_item}>
                  <h5
                    className={styles.team_item_h5}
                    style={{ color: "black" }}
                  ></h5>

                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={pro3}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center"></div>
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <a href="#p3" style={{ textDecoration: "none" }}>
                <div className={styles.team_item}>
                  <h5
                    className={styles.team_item_h5}
                    style={{ color: "black" }}
                  ></h5>
                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={pro4}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center"></div>
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <a href="#p4" style={{ textDecoration: "none" }}>
                <div className={styles.team_item}>
                  <h5
                    className={styles.team_item_h5}
                    style={{ color: "black" }}
                  ></h5>

                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={pro2}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center"></div>
                </div>
              </a>
            </div>
          </div>
          {/* <div class="row g-4" style={{ marginTop: "3%" }}>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <a href="#p5" style={{ textDecoration: "none" }}>
                <div className={styles.team_item}>
                  <h5
                    className={styles.team_item_h5}
                    style={{ color: "black" }}
                  ></h5>
                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={pro5}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center"></div>
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <a href="#p6" style={{ textDecoration: "none" }}>
                <div className={styles.team_item}>
                  <h5
                    className={styles.team_item_h5}
                    style={{ color: "black" }}
                  ></h5>
                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={pro6}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center"></div>
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <a href="#p7" style={{ textDecoration: "none" }}>
                <div className={styles.team_item}>
                  <h5
                    className={styles.team_item_h5}
                    style={{ color: "black" }}
                  ></h5>

                  <div
                    className="img-fluid rounded-circle w-120 mt-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "93%", scale: "0.95" }}
                      src={pro7}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center"></div>
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <a href="#p8" style={{ textDecoration: "none" }}>
                <div className={styles.team_item}>
                  <h5
                    className={styles.team_item_h5}
                    style={{ color: "black" }}
                  ></h5>
                  <div
                    className="img-fluid rounded-circle w-120  mt-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={pro3}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center"></div>
                </div>
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div className={styles.divise} id="p1">
        <div style={{ padding: "3%" }}>
          <h1 className={styles.font_}>La Protection du corps</h1>
          <hr style={{ width: "34%" }} />
        </div>
        <div>
          <p
            className={styles.font_p}
            style={{
              fontSize: "20px",
              padding: "2%",
              backgroundColor: "#ABD473",
            }}
          >
            Le vêtement de protection peut dans certains cas être à usage unique
            Le vêtement de protection peut dans certains cas être à usage unique
            Il conviendra donc de déterminer la configuration des vêtements qui
            sera la plus adaptée aux contraintes d'utilisations et conditions de
            travail. Selon la demande, le vêtement reçoit le Logo du client. Les
            principaux critères sont:
            <br />
            <ul>
              <li style={{ listStyleType: "circle" }}>
                protection contre la chaleur ou flamme
              </li>
              <li style={{ listStyleType: "circle" }}>
                protection contre l'électricité statique
              </li>
              <li style={{ listStyleType: "circle" }}>
                protection contre les projection de liquide chimique
              </li>
              <li style={{ listStyleType: "circle" }}>
                protection contre le risque chimique
              </li>
              <li style={{ listStyleType: "circle" }}>
                protection contre le froid et les intempéries
              </li>
            </ul>
            Nous vous orienterons dans votre choix de combinaison, blouse,
            pantalon, coiffe, veste, parkas, gilet.
          </p>
        </div>
      </div>
      <div className={styles.divise} id="p2">
        <div style={{ padding: "3%" }}>
          <h1 className={styles.font_}>La Protection des pieds</h1>
          <hr style={{ width: "34%" }} />
        </div>
        <div>
          <p
            className={styles.font_p}
            style={{
              fontSize: "20px",
              padding: "2%",
              backgroundColor: "#ABD473",
            }}
          >
            La chaussure à usage professionnel 3 niveaux sont déclinés
            <br />
            <ul>
              <li style={{ listStyleType: "circle" }}>
                Chaussures de sécurité
              </li>
              <li style={{ listStyleType: "circle" }}>
                Chaussures de protection
              </li>
              <li style={{ listStyleType: "circle" }}>Chaussures de travail</li>
            </ul>
            Nous sommes en mesure de vous proposer indifféremment des chaussures
            pour votre personnel masculin ou féminin, pour les domaines de
            l'industrie mécanique, le BTP, l'agroalimentaire, forestier, chimie.
            Les normes ISO 20345 ISO 20346 ISO 20347 fixent les exigences
            respectivement des chaussures de sécurité, de protection et de
            travail.
          </p>
        </div>
      </div>
      <div className={styles.divise} id="p3">
        <div style={{ padding: "3%" }}>
          <h1 className={styles.font_}>La protection des yeux</h1>
          <hr style={{ width: "34%" }} />
        </div>
        <div>
          <p
            className={styles.font_p}
            style={{
              fontSize: "20px",
              padding: "2%",
              backgroundColor: "#ABD473",
            }}
          >
            La protection oculaire garantit contre 5 risques distincts:
            <br />
            <ul>
              <li style={{ listStyleType: "circle" }}>risques mécaniques</li>
              <li style={{ listStyleType: "circle" }}>risques chimiques</li>
              <li style={{ listStyleType: "circle" }}>
                risques liés aux rayonnements
              </li>
              <li style={{ listStyleType: "circle" }}>risques électriques</li>
              <li style={{ listStyleType: "circle" }}>risques thermiques</li>
            </ul>
            Nous pouvons vous fournir en lunettes, sur lunettes, masque, écrans
            faciaux, masque optoélectronique, masque soudure traditionnel
          </p>
        </div>
      </div>
      <div className={styles.divise} id="p4">
        <div style={{ padding: "3%" }}>
          <h1 className={styles.font_}>La Protection du visage</h1>
          <hr style={{ width: "34%" }} />
        </div>
        <div>
          <p
            className={styles.font_p}
            style={{
              fontSize: "20px",
              padding: "2%",
              backgroundColor: "#ABD473",
            }}
          >
            La protection de la face est souvent liée à la protection des yeux
            et à la protection respiratoire
            <br />
            <ul>
              <li style={{ listStyleType: "circle" }}>risques mécaniques</li>
              <li style={{ listStyleType: "circle" }}>risques chimiques</li>
              <li style={{ listStyleType: "circle" }}>
                risques liés aux rayonnements
              </li>
              <li style={{ listStyleType: "circle" }}>risques électriques</li>
              <li style={{ listStyleType: "circle" }}>risques thermiques</li>
              <li style={{ listStyleType: "circle" }}>
                protection contre les débris ferreux
              </li>
              <li style={{ listStyleType: "circle" }}>
                protection contre soudure
              </li>
            </ul>
            Nous pouvons vous fournir en lunettes, sur lunettes, masque, écrans
            faciaux, masque optoélectronique, masque soudure traditionnel
          </p>
        </div>
      </div>
      <div className={styles.divise} id="p5">
        <div style={{ padding: "3%" }}>
          <h1 className={styles.font_}>La Protection des mains</h1>
          <hr style={{ width: "34%" }} />
        </div>
        <div>
          <p
            className={styles.font_p}
            style={{
              fontSize: "20px",
              padding: "2%",
              backgroundColor: "#ABD473",
            }}
          >
            La protection des mains garantit contre 4 risques distincts:
            <br />
            <ul>
              <li style={{ listStyleType: "circle" }}>risques mécaniques</li>
              <li style={{ listStyleType: "circle" }}>risques chocs</li>
              <li style={{ listStyleType: "circle" }}>risques températures</li>
              <li style={{ listStyleType: "circle" }}>risques chimiques</li>
            </ul>
            Nous fournissons régulièrement à nos clients des gants en cuir,
            textile, polyamide, en Dyneema, enduction nitrile, Kevlar, Latex,
            PVC, pour haute ou basse température, protection électrique, etc...
            5 critères sont indispensables en premier lieu pour choisir un gant:
            <br />
            <ul>
              <li style={{ listStyleType: "circle" }}>
                Résistance à l'abrasion
              </li>
              <li style={{ listStyleType: "circle" }}>
                Résistance à la coupure par tranchage
              </li>
              <li style={{ listStyleType: "circle" }}>
                Résistance à la déchirure
              </li>
              <li style={{ listStyleType: "circle" }}>
                Résistance à la perforation
              </li>
              <li style={{ listStyleType: "circle" }}>
                Lieu d'utilisation (température et produit chimiques)
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div className={styles.divise}>
        <div style={{ padding: "3%" }}>
          <h1 className={styles.font_}>La Filtration d’air</h1>
          <hr style={{ width: "34%" }} />
        </div>
        <div>
          <p
            className={styles.font_p}
            style={{
              fontSize: "20px",
              padding: "2%",
              backgroundColor: "#ABD473",
            }}
          >
            Les dispositifs protègent en fonction de la technologie requise:
            <br />
            <ul>
              <li style={{ listStyleType: "circle" }}>poussières</li>
              <li style={{ listStyleType: "circle" }}>particules</li>
              <li style={{ listStyleType: "circle" }}>gaz</li>
              <li style={{ listStyleType: "circle" }}>vapeurs</li>
              <li style={{ listStyleType: "circle" }}>aérosols</li>
            </ul>
            Nous distribuons des masques jetables ou non, des demi-masques, des
            masques complets, des masques à ventilation assistée
          </p>
        </div>
      </div>
      <div className={styles.divise} id="p7">
        <div style={{ padding: "3%" }}>
          <h1 className={styles.font_}>La Protection auditive</h1>
          <hr style={{ width: "34%" }} />
        </div>
        <div>
          <p
            className={styles.font_p}
            style={{
              fontSize: "20px",
              padding: "2%",
              backgroundColor: "#ABD473",
            }}
          >
            La protection de l'ouïe est assurée par des équipements destinés à
            atténuer le bruit Le critère de choix de cet EPI est par conséquent
            le niveau d'atténuation moyen en dB par rapport à l'environnement
            Nous vous proposons des bouchons d'oreille à usage unique ou non,
            des protections à arceaux, casques antibruit , kit Forestier ...
          </p>
        </div>
      </div>
      <div className={styles.divise} id="p8">
        <div style={{ padding: "3%" }}>
          <h1 className={styles.font_}>La Filtration d’air</h1>
          <hr style={{ width: "34%" }} />
        </div>
        <div>
          <p
            className={styles.font_p}
            style={{
              fontSize: "20px",
              padding: "2%",
              backgroundColor: "#ABD473",
            }}
          >
            La protection antichute est assurée tant par le harnais que
            l'ensemble des dispositifs d'accrochage et de treuil. Nous
            fournissons harnais, ceinture, longe, coulisseau, anneau, enrouleur,
            potence, trépied, ligne de vie.
          </p>
        </div>
      </div>
    </div>
  );
}
