import React from "react";
import styles from "./about.module.css";



const appro = require("../../assets/appro0.jpg");
const pic = require("../../assets/exemple2.png");
const eq = require("../../assets/rem.png");

export default function About() {
  return (
    <div  style={{ height: "100%", width: "100%" }}>
      <div className={styles.divise}>
        <div style={{ marginTop: "3%", padding: "3%" }}>
          <div className="container">
          <div class="row">
            <div class="col-md-6 how-img">
              <h4 className={styles.font}>
                <b>Présentation</b>
              </h4>
              <hr />
              <p
                style={{
                  textAlign: "left",
                  borderRadius: "4px",
                  fontFamily: "Open Sans",
                  padding: "2%",
                  lineHeight: "22px",
                  
                }}
              >
                Fondée en 2006 , <b>ATIS</b> est une entreprise spécialisée dans la
                  fourniture d'équipements et de pièces de rechange pour
                  l'industrie. Depuis notre création, notre objectif principal
                  est de fournir des produits et des services de haute qualité à
                  nos clients, en mettant l'accent sur leur satisfaction et la
                  qualité des produits.
                  Grâce à notre engagement envers
                  l'excellence, <b>ATIS</b> a rapidement gagné en réputation en tant
                  que fournisseur de confiance pour les pièces de rechange et
                  les équipements industriels. Nous avons connu une croissance
                  rapide, élargissant notre portefeuille de produits pour
                  inclure une vaste gamme de composants et d'équipements
                  industriels, afin de répondre aux besoins variés de nos
                  clients. Notre engagement envers la qualité et la satisfaction
                  client nous a valu une reconnaissance dans l'industrie.
                  Aujourd'hui, <b>ATIS</b> est fier d'être un partenaire fiable pour de
                  nombreux secteurs, tels que l'industrie agroalimentaire,
                  minière et cimenteries, chimique et fertilisants, production
                  d'électricité, aciéries et laminoirs, aéronautique et
                  pharmaceutique. Nous sommes réputés pour notre capacité à
                  fournir des produits de qualité, un service exceptionnel et
                  des délais de livraison rapides. Grâce à notre engagement
                  envers l'excellence et la satisfaction client, <b>ATIS</b> continue
                  de croître et de prospérer dans un marché en constante
                  évolution.
              </p>
            </div>
            <div className="col-md-6">
              <img src={appro} className="lazy" style={{ width: "100%" }} />
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className={styles.divise} style={{ backgroundColor: "#ABD473" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text-center" style={{ marginTop: "7%" }}>
                <h2 className={styles.font_}>Pourquoi</h2>
                <br />
                <p style={{ fontSize: "35px" }}>travailler avec nous ?</p>
              </div>
            </div>
            <div class="col-md-6">
              <div id="accordion" style={{ padding: "5%" }}>
                <div
                  class="card"
                  style={{
                    borderRadius: "0px ",
                    color: "white",
                    backgroundColor: "#2B4A99",
                  }}
                >
                  <div class="card-header">
                    <a
                      class="btn"
                      data-bs-toggle="collapse"
                      href="#collapseOne"
                      style={{ color: "white" }}
                    >
                      Notre organisation
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    class="collapse show"
                    data-bs-parent="#accordion"
                  >
                    <div class="card-body">
                      L'approvisionneur technique est une entreprise spécialisée
                      dans la fourniture d'équipements et de pièces de rechange
                      pour l'industrie. Nous mettons à votre disposition notre
                      expertise et notre expérience pour répondre à tous vos
                      besoins en matière d'approvisionnement technique. Nous
                      avons développé des partenariats avec les plus grands
                      fabricants d'équipements industriels pour vous proposer
                      une gamme complète de produits de qualité. Nous disposons
                      d'un stock important de pièces de rechange pour répondre à
                      vos demandes les plus urgentes et minimiser vos temps
                      d'arrêt.
                    </div>
                  </div>
                </div>
                <div
                  class="card"
                  style={{
                    borderRadius: "0px ",
                    color: "white",
                    backgroundColor: "#2B4A99",
                  }}
                >
                  <div class="card-header">
                    <a
                      class="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapseTwo"
                      style={{ color: "white" }}
                    >
                      Notre mission
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div class="card-body">
                      Notre mission est de fournir des solutions de haute
                      qualité, adaptées aux besoins de chaque client, afin de
                      les aider à optimiser leur production et à améliorer leur
                      performance opérationnelle. Nous sommes déterminés à
                      offrir un service de qualité supérieure, en travaillant en
                      étroite collaboration avec nos clients pour comprendre
                      leurs besoins spécifiques et leur offrir des solutions
                      personnalisées qui répondent à leurs exigences.
                    </div>
                  </div>
                </div>
                <div
                  class="card"
                  style={{
                    borderRadius: "0px ",
                    color: "white",
                    backgroundColor: "#2B4A99",
                    
                  }}
                >
                  <div class="card-header">
                    <a
                      class="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapseThree"
                      style={{ color: "white" }}
                    >
                      Notre vision
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div class="card-body">
                      Notre vision est d'être le partenaire privilégié de nos
                      clients, en leur offrant une gamme complète de produits et
                      de services de qualité supérieure, tout en respectant les
                      normes les plus élevées en matière de qualité. Nous
                      croyons que notre succès en tant qu'entreprise repose sur
                      notre capacité à offrir des solutions personnalisées,
                      adaptées aux besoins spécifiques de chaque client, en
                      utilisant les dernières technologies et en restant à la
                      pointe de l'innovation. Notre rôle ne se limite pas à la
                      simple fourniture de produits, mais que nous avons
                      également la responsabilité de contribuer à la réussite de
                      nos clients en leur offrant un soutien technique complet
                      et en les aidant à résoudre les problèmes les plus
                      complexes.
                    </div>
                  </div>
                </div>
                <div
                  class="card"
                  style={{
                    borderRadius: "0px ",
                    color: "white",
                    backgroundColor: "#2B4A99",
                  }}
                >
                  <div class="card-header">
                    <a
                      class="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapseFour"
                      style={{ color: "white" }}
                    >
                      Nos valeurs
                    </a>
                  </div>
                  <div
                    id="collapseFour"
                    class="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div class="card-body">
                      Notre vision est d'être le partenaire privilégié de nos
                      clients, en leur offrant une gamme complète de produits et
                      de services de qualité supérieure, tout en respectant les
                      normes les plus élevées en matière de qualité. Nous
                      croyons que notre succès en tant qu'entreprise repose sur
                      notre capacité à offrir des solutions personnalisées,
                      adaptées aux besoins spécifiques de chaque client, en
                      utilisant les dernières technologies et en restant à la
                      pointe de l'innovation. Notre rôle ne se limite pas à la
                      simple fourniture de produits, mais que nous avons
                      également la responsabilité de contribuer à la réussite de
                      nos clients en leur offrant un soutien technique complet
                      et en les aidant à résoudre les problèmes les plus
                      complexes.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.divise}>
        <h1 className={styles.font_} style={{ padding: "5%" }}>
          Notre equipes
        </h1>
      </div>
      <div className="">
        <div className="row" style={{backgroundColor:"#2B4A99",width:"100.8%"}}>
          <div className="col-md-6">
            <p className={styles.font2} style={{ fontSize: "20px",padding:"5%" , color:"whitesmoke"}}>
              Notre équipe de professionnels expérimentés se consacre à fournir
              des solutions d'achat de qualité pour les équipements industriels.
              Notre équipe est composée de composée de 48 personnes dont des
              ingénieurs, des techniciens et des métiers support professionnels
              d'experts ayant une connaissance approfondie des aspects
              techniques des produits que nous fournissons, ainsi que du
              processus d'achat.
              <br/>
              Nous sommes fiers de notre capacité à nous procurer des produits
              de haute qualité auprès de fournisseurs réputés, afin de garantir
              à nos clients les meilleurs produits possibles à des prix
              compétitifs. Notre équipe se tient au courant des derniers
              développements de l'industrie, ce qui nous permet d'offrir des
              solutions innovantes et rentables qui répondent aux besoins
              changeants de nos clients.
              <br />
              Nous nous engageons à fournir un excellent service à la clientèle
              et à veiller à ce que tous nos clients soient satisfaits des
              produits et services que nous leur fournissons.
            </p>
          </div>
          <div className="col-md-6 how-img">
            <img className="lazy" src={eq} style={{width:"90%"}} />
          </div>
        </div>
      </div>
      

      <div className={styles.divise}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 how-img">
              <img
                src={pic}
                className="lazy"
                style={{
                  padding: "5%",
                  width: "100%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "12%",
                }}
              />
            </div>
            <div className="col">
              <p
                className={styles.font2}
                style={{ padding: "5%", lineHeight: "20px" }}
              >
                <b>
                  L’approvisionneur technique International SA Votre VOTRE
                  PARTENAIRE POUR DES ACHATS RÉUSSIS ! <br />
                </b>
                L'Approvisionneur Technique est une société spécialisée dans la
                fourniture des équipements et pièces de rechanges pour
                l'industrie, nous avons des marques que nous distribuons et nous
                assurons la fonction de centrale d'achat pour d'autres marques.
                Nous offrons une gamme complète de produits pour répondre à tous
                vos besoins en matière d'équipements et de pièces de rechange
                pour l'industrie.
                <br /> Nos principales familles de fournitures comprennent :
                <br />
                <br />
                <ul>
                  <li>
                    {" "}
                    Les équipements process : pompes, échangeurs, agitateurs,
                    broyeurs, filtres, et bien plus encore.
                  </li>
                  <br />
                  <li>
                    {" "}
                    Les équipements électriques, d'instrumentations et
                    d'automatisme pour tous les secteurs de l'industrie, tels
                    que la pétrochimie, la pharmaceutique, l'agroalimentaire, et
                    bien plus encore.
                  </li>
                  <br />
                  <li>
                    {" "}
                    Les équipements de laboratoire pour les industries
                    pharmaceutiques, cosmétiques, agroalimentaires, et plus
                    encore
                  </li>
                  <br />
                  <li>
                    {" "}
                    L’appareillage portatif et outillage de mesure et d'analyse
                    pour les professionnels de l'industrie.
                  </li>
                  <br />
                  <li>
                    {" "}
                    Les produits chimiques et consommables divers pour répondre
                    à tous vos besoins de maintenance et de production.
                  </li>
                </ul>
                Nous sommes convaincus que notre expertise, notre expérience et
                notre engagement envers l'excellence opérationnelle font de nous
                le choix idéal pour tous vos besoins en matière d'équipements et
                de pièces de rechange pour l'industrie. Contactez-nous dès
                maintenant pour en savoir plus sur nos produits et services.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
