import { Fab, Fade } from '@material-ui/core';
import {useEffect} from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { blue, pink } from '@mui/material/colors';


function ScrollTop(props) {
  const { children, window } = props;
  
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };
  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16,bgcolor:"black" }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default function Button_Top() {
  
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div>
      
      <ScrollTop>
        <Fab size="small" aria-label="scroll back to top" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }} style={{

          position: 'fixed',
          fontSize: '20px',
          bottom: '40px',
          right: '40px',
          color: '#fff',
          textAlign: 'center', 
                  
        }}>
          <KeyboardArrowUpIcon sx={{ color: blue[500] }} />
        </Fab>
      </ScrollTop>
    </div>
  );
}