import { Link } from "react-router-dom";

export default function PageNotFound(params) {
  return (
    <div style={{marginTop:"7%", marginBottom:"7%"}}>
      <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container text-center">
          <div class="row justify-content-center">
            <div class="col-lg-6 alert alert-warning">
              <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
              <h1 class="display-1">404</h1>
              <h1 class="mb-4">Page introuvable</h1>
              <p class="mb-4">
                Nous sommes désolés, la page que vous avez recherchée n'existe
                pas sur notre site Web ! Peut-être aller à notre page d'accueil
                !
              </p>
              <Link class="btn btn-primary py-3 px-5 w-50" to="/">
                Retour à l'accueil
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
