import { Link } from "react-router-dom";
import styles from "./broyeur.module.css";
import { Breadcrumbs, Typography } from "@mui/material";

const img1 = require("../../assets/broy-cyclon.png");
const img2 = require("../../assets/broy-mix.png");
const img3 = require("../../assets/broy-moch.png");
const img4 = require("../../assets/broy-mortier.png");
const img5 = require("../../assets/broy-plan.png");
const img6 = require("../../assets/vibro.png");
const broyeur = require("../../assets/broyeur.gif");

export default function Broyeur(params) {
  return (
    <div>
      <div class="container-xxl py-6">
        <div class="container">
          <div
            class="mx-auto text-center wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <div style={{ marginTop: "2%" }}>
              <h1 className={styles.font_}>Broyeur</h1>
              <hr />
              <Breadcrumbs
                aria-label="breadcrumb"
                separator=">"
                sx={{
                  "& ol": {
                    justifyContent: "center",
                    margin: "auto",
                  },
                }}
              >
                <Link
                  to="/equipement-process"
                  style={{ textDecoration: "none", color: "#3367ec" }}
                >
                  Produits
                </Link>

                <Link
                  to="/equipement-process"
                  style={{ textDecoration: "none", color: "#3367ec" }}
                >
                  equipement process
                </Link>

                <Typography color="text.primary">Broyeur</Typography>
              </Breadcrumbs>
            </div>
          </div>

          <div>
            <h2
              className={styles.font}
              style={{ textAlign: "center", marginTop: "3%" }}
            >
              Découvrez notre gamme complète de broyeurs, conçus pour répondre à
              tous vos besoins
            </h2>
          </div>

          <div class="row g-4 mt-5">
            <div>
              <p style={{ fontSize: "20px" }}>
                Explorez notre gamme et trouvez le broyeur parfait pour votre
                application spécifique.
              </p>
            </div>
            <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div
                className={styles.team_item}
                style={{
                  width: "50%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <h5 className={styles.team_item_h5}></h5>

                <div
                  className="img-fluid rounded-circle w-100 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "1" }}
                    src={broyeur}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center" style={{}}>
                  <p className={styles.team_item_h5}></p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <ul className={styles.ul}>
                <div className="row">
                  <li className={styles.li}>Concasseurs à mâchoires</li>
                  <li className={styles.li}>Broyeurs à rotor</li>
                  <li className={styles.li}>Broyeurs à couteaux</li>
                  <li className={styles.li}>Broyeurs-mixeurs à couteaux</li>

                  <li className={styles.li}>Broyeurs à mortier</li>
                  <li className={styles.li}>Broyeurs à disques</li>
                  <li className={styles.li}>Broyeurs à billes</li>
                </div>
              </ul>
            </div>
            {/* <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}>
                  BROYEURS-MIXEURS À COUTEAUX
                </h5>

                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "0.95" }}
                    src={img2}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}></p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}>CONCASSEURS À MÂCHOIRES</h5>
                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "0.95" }}
                    src={img3}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}></p>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div class="row g-4" style={{ marginTop: "3%" }}>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}>BROYEUR À MORTIER</h5>
                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "0.95" }}
                    src={img4}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}></p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}>
                  BROYEUR PLANÉTAIRE À BILLES
                </h5>
                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "0.95" }}
                    src={img5}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}></p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}>VIBRO-BROYEURS</h5>

                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "0.95" }}
                    src={img6}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}></p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div style={{ marginTop: "3%" }}>
          <div>
            <p style={{ fontSize: "20px" }}>Zone paragraph</p>
          </div>
          <ul className={styles.ul}>
            <div className="row">
              <div className="col-md-6">
                <li className={styles.li}>
                  Concasseurs à mâchoiresConcasseurs à mâchoires
                </li>
                <li className={styles.li}>Broyeurs à rotor</li>
                <li className={styles.li}>Broyeurs à couteaux</li>
                <li className={styles.li}>Broyeurs-mixeurs à couteaux</li>
              </div>
              <div className="col-md-6">
                <li className={styles.li}>Broyeurs à mortier</li>
                <li className={styles.li}>Broyeurs à disques</li>
                <li className={styles.li}>Broyeurs à billes</li>
              </div>
            </div>
          </ul>
        </div> */}
      </div>
    </div>
  );
}

/* Concasseurs à mâchoiresConcasseurs à mâchoires
Broyeurs à rotor
Broyeurs à couteaux
Broyeurs-mixeurs à couteaux
Broyeurs à mortier
Broyeurs à disques
Broyeurs à billes */
