import React from "react";
import styles from "./service.module.css";

const ser = require("../../assets/services.jpg");
const service = require('../../assets/service-rmv.png')

export default function services(params) {
  return (
    <div>
      <div className="container" style={{marginTop:"2%"}}>
        <div className="row">
          <div className="col-md-6 how-img">
            <img src={service} style={{width:"100%",marginTop:"5%"}} />
          </div>
          <div className="col-md-6">
            <p className={styles.font_p}>
            En plus des simples demandes de prix requis habituellement de nos
            clients, demandes par lesquelles le client souhaite avoir une
            substitution à l’identique des équipements ou pièces de rechange
            existant. Atis accompagne régulièrement ses clients par la
            définition du besoin et mise en place du solution technique afin
            d’assurer les performances et attentes notre périmètre
            d’intervention est :
            <ul>
              <li>l’etudes et conception des solutions techniques.</li>
              <li>
                sourcing et achat des unités, équipements et pièces de rechange.
              </li>
              <li>transport et acheminement sur site. </li>
              <li>mise en marche sur site.</li>
              <li>formation à l’utilisation et maintenance.</li>
            </ul>
            En résumé, nous sommes là pour vous aider à trouver les solutions
            les plus adaptées à vos besoins techniques, en vous fournissant des
            conseils, des services et des équipements de qualité.<br/> En faisant
            appel à notre société, vous pouvez économiser du temps et de
            l'argent, réduire les risques associés à l'approvisionnement et
            améliorer la qualité des biens et services que vous recevez.
            Contactez-nous dès aujourd'hui pour en savoir plus sur la façon dont
            nous pouvons aider votre entreprise !</p>
          </div>
        </div>
      </div>
    </div>
  );
}
