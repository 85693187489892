import React from "react";
import styles from "./testprojet.module.css";

const pr1 = require("../../assets/prj2.png");
const pr2 = require("../../assets/prj3.png");
const pr3 = require("../../assets/prj4.png");
const pr4 = require("../../assets/compr.png");
const pr5 = require("../../assets/ocp.png");
const pr6 = require("../../assets/suez.png");
const pr7 = require("../../assets/jorf.png");

export default function TestProjet(params) {
  return (
    <div className={styles.container_}>
      <div className={styles.square}>
        <img src={pr1} className={styles.mask} style={{width:"100%"}}/>
        <div className={styles.h1}>Is Apple a Design Company?</div>
        <p className={styles.p_}>
          Apple is more than a tech company; it became a culture unto itself, a
          passion of most of people and the birthplace of the world’s most
          revolutionized products.
        </p>

        <div>
          <a
            href="https://medium.com/@laheshk/is-apple-a-design-company-f5c83514e261"
            target="_"
            className={styles.button_}
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.card_}>
      <div className={styles.header_}>
        <div className={styles.img_box}>
          <img src={pr1} />
        </div>
        <h1 className={styles.title_}>Features</h1>
      </div>

      <div className={styles.content_}>
        <p className={styles.p_}>
          Lorem ipsum dolor sit amet, consectetur adipiscing it amet it...
        </p>

        <a className={styles.btn_link}>Read More...</a>
      </div>
    </div>
  );

  return (
    <div className={styles.container_}>
      <div className={styles.card_}>
        <div className={styles.face_}>
          <div className={styles.face1_}>
            <div className={styles.content_}>
              <img className={styles.img_} src={pr1} />
              <h3 className={styles.h3_}>Design</h3>
            </div>
          </div>
        </div>
        <div className={styles.face_}>
          <div className={styles.face2_}>
            <div className={styles.content_}>
              <p className={styles.p_}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                cum cumque minus iste veritatis provident at.
              </p>
              <a className={styles.a_} href="#">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.card_}>
        <div className={styles.face_}>
          <div className={styles.face1_}>
            <div className={styles.content_}>
              <img
                src="https://github.com/Jhonierpc/WebDevelopment/blob/master/CSS%20Card%20Hover%20Effects/img/code_128.png?raw=true"
                className={styles.img_}
              />
              <h3 className={styles.h3_}>Code</h3>
            </div>
          </div>
        </div>
        <div className={styles.face_}>
          <div className={styles.face2_}>
            <div className={styles.content_}>
              <p className={styles.p_}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                cum cumque minus iste veritatis provident at.
              </p>
              <a className={styles.a_} href="#">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.card_}>
        <div className={styles.face_}>
          <div className={styles.face1_}>
            <div className={styles.content_}>
              <img
                className={styles.img_}
                src="https://github.com/Jhonierpc/WebDevelopment/blob/master/CSS%20Card%20Hover%20Effects/img/launch_128.png?raw=true"
              />
              <h3 className={styles.h3_}>Launch</h3>
            </div>
          </div>
        </div>
        <div className={styles.face_}>
          <div className={styles.face2_}>
            <div className={styles.content_}>
              <p className={styles.p_}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                cum cumque minus iste veritatis provident at.
              </p>
              <a className={styles.a_} href="#">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <main className="main">
      <section className={styles.card_area}>
        <section className={styles.card_section}>
          <div className={styles.card_}>
            <div className={styles.flip_card}>
              <div className={styles.flip_card__container}>
                <div className={styles.card_front}>
                  <div className={styles.card_front__tp}>
                    <div className={styles.card_front__tp__city}>
                      <h2 className={styles.card_front__heading}>
                        Aspiration industrielle centralisée d’une unité de
                        concassage OCP
                      </h2>
                      <p className={styles.card_front__text_price}>
                        {/*From £29*/}
                      </p>
                    </div>
                  </div>

                  <div className={styles.card_front__bt}>
                    <p className={styles.card_front__text_view}>
                      <div className={styles.card_front__text_view__city}>
                        View me
                      </div>
                    </p>
                  </div>
                </div>
                <div className={styles.card_back}>
                  <div className={styles.video__container}>
                    <img src={pr1} style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inside_page}>
              <div className={styles.inside_page__container}>
                <h3 className={styles.inside_page__heading}>
                  <div className={styles.inside_page__heading__ski}>
                    Solution de ATIS :
                  </div>
                </h3>
                <p className={styles.inside_page__text}>
                  Développement et mise en place d’un système d’aspiration
                  centralisé...
                </p>
                <a href="#" className={styles.inside_page__btn}>
                  <div className={styles.inside_page__btn__city}>
                    EN SAVOIRE PLUS
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.card_section}>
          <div className={styles.card_}>
            <div className={styles.flip_card}>
              <div className={styles.flip_card__container}>
                <div className={styles.card_front}>
                  <div className={styles.card_front__tp}>
                    <div className={styles.card_front__tp__ski}>
                      <h2 className={styles.card_front__heading}>
                        Véhicules d’intervention Derichebourg
                      </h2>
                      <p className={styles.card_front__text_price}>
                        {/*From £199*/}
                      </p>
                    </div>
                  </div>

                  <div className={styles.card_front__bt}>
                    <p className="card_front__text_view card_front__text_view__ski">
                      View me
                    </p>
                  </div>
                </div>

                <div className={styles.card_back}>
                  <div className={styles.video__container}>
                    <img src={pr1} style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inside_page}>
              <div className={styles.inside_page__container}>
                <h3 className={styles.inside_page__heading}>
                  <div className={styles.inside_page__heading__ski}>
                    Solution de ATIS :
                  </div>
                </h3>
                <p className={styles.inside_page__text}>
                  Equipement des véhicules déjà existants chez DERICHEBOURG d’un
                  système...
                </p>
                <a href="#" className="inside_page__btn inside_page__btn__ski">
                  EN SAVOIRE PLUS
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.card_section}>
          <div className={styles.card_}>
            <div className={styles.flip_card}>
              <div className={styles.flip_card__container}>
                <div className={styles.card_front}>
                  <div className={styles.card_front__tp}>
                    <div className={styles.card_front__tp__beach}>
                      <h2 className={styles.card_front__heading}>
                        Station de lavage des isolateurs HTB
                      </h2>
                      <p className={styles.card_front__text_price}>
                        {/*From £229*/}
                      </p>
                    </div>
                  </div>

                  <div className={styles.card_front__bt}>
                    <p className="card_front__text_view card_front__text_view__beach">
                      View me
                    </p>
                  </div>
                </div>
                <div className={styles.card_back}>
                  <div className={styles.video__container}>
                    <img src={pr1} style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inside_page}>
              <div className={styles.inside_page__container}>
                <h3 class={styles.inside_page__heading}>
                  <div className={styles.inside_page__heading__ski}>
                    Solution de ATIS :
                  </div>
                </h3>
                <p className={styles.inside_page__text}>
                  mise en place des station mobile qui permettent le lavage
                  isolateurs HTB...
                </p>
                <a href="#" class="inside_page__btn inside_page__btn__beach">
                  EN SAVOIRE PLUS
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.card_section}>
          <div className={styles.card_}>
            <div className={styles.flip_card}>
              <div className={styles.flip_card__container}>
                <div className={styles.card_front}>
                  <div className={styles.card_front__tp}>
                    <div className={styles.card_front__tp__camping}>
                      <h2 className={styles.card_front__heading}>
                        Pompes anti incendie UNILEVER
                      </h2>
                      <p className={styles.card_front__text_price}>
                        {/*From £129*/}
                      </p>
                    </div>
                  </div>

                  <div className={styles.card_front__bt}>
                    <p className="card_front__text_view card_front__text_view__camping">
                      View me
                    </p>
                  </div>
                </div>
                <div className={styles.card_back}>
                  <div className={styles.video__container}>
                    <img src={pr1} style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.inside_page}>
              <div className={styles.inside_page__container}>
                <h3 className={styles.inside_page__heading}>
                  <div className={styles.inside_page__heading__ski}>
                    Solution de ATIS :
                  </div>
                </h3>
                <p className={styles.inside_page__text}>
                  Mise en place d’une station de pompage certifiée NFP20
                  ,développement et choix...
                </p>
                <a
                  href="#"
                  className="inside_page__btn inside_page__btn__camping"
                >
                  EN SAVOIRE PLUS
                </a>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
}
