import { Breadcrumbs, Typography } from "@mui/material";
import styles from "./filter.module.css";
import { Link } from "react-router-dom";

const flt1 = require("../../assets/filter1.png");
const flt2 = require("../../assets/filter2.png");
const flt3 = require("../../assets/filter3.png");
const flt4 = require("../../assets/filter4.png");
const filtre = require("../../assets/filtre.gif");

export default function Filter(params) {
  return (
    <div>
      <div class="container-xxl py-6">
        <div class="container">
          <div
            class="mx-auto text-center wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <div style={{ marginTop: "2%" }}>
              <h1 className={styles.font_}>Filtre</h1>
              <hr />
              <Breadcrumbs
                aria-label="breadcrumb"
                separator=">"
                sx={{
                  "& ol": {
                    justifyContent: "center",
                    margin: "auto",
                  },
                }}
              >
                <Link
                  to="/equipement-process"
                  style={{ textDecoration: "none", color: "#3367ec" }}
                >
                  Produits
                </Link>

                <Link
                  to="/equipement-process"
                  style={{ textDecoration: "none", color: "#3367ec" }}
                >
                  equipement process
                </Link>

                <Typography color="text.primary">Filtre</Typography>
              </Breadcrumbs>
            </div>
          </div>

          <div>
            <h2
              className={styles.font}
              style={{ textAlign: "center", marginTop: "3%" }}
            >
              Chez l'Approvisionneur Technique, nous mettons à votre disposition
              une gamme complète de filtres performants pour répondre à tous vos
              besoins.
            </h2>
          </div>

          <div class="row g-4 mt-5">
            <div>
              <p style={{ fontSize: "20px" }}>
                Que vous recherchiez des filtres pour la filtration de l'air, de
                l'eau, des gaz ou des liquides, nous avons la solution adaptée à
                votre activité. Nos filtres de haute qualité offrent une
                efficacité optimale et une durabilité exceptionnelle, assurant
                ainsi un fonctionnement fiable et une protection accrue de vos
                équipements.
              </p>
            </div>
            <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item} style={{width:"50%",display:"block",marginLeft:"auto",marginRight:"auto"}}>
                <h5 className={styles.team_item_h5}>
                  FILTRES LIQUIDES <br /> SANITAIRES
                </h5>

                <div
                  className="img-fluid rounded-circle w-100 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "1" }}
                    src={filtre}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center" style={{}}>
                  <p className={styles.team_item_h5}></p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <ul className={styles.ul}>
                <div className="row">
                  <div className="col-md-6">
                    <li className={styles.li}>Cyclones humides ou secs</li>
                    <li className={styles.li}>Filtres à manches</li>
                    <li className={styles.li}>Electrofiltres</li>
                    <li className={styles.li}>Filtres dépoussiéreurs pour centrales à béton à sec - DRYBATCH</li>
                    <li className={styles.li}>
                    Filtres dépoussiéreurs polygonaux à secouage mécanique - DUSTSHAKE  
                    </li>
                    <li className={styles.li}>Filtres de dégazage pour trémies - HOPPERJET</li>
                    <li className={styles.li}>Filtres dépoussiéreurs polygonaux - WAMAIR</li>
                    
                  </div>
                  <div className="col-md-6">
                    <li className={styles.li}>
                    Filtres polygonaux encastrables en dépression - WAMAIR 
                    </li>
                    <li className={styles.li}>Vacuum Filtres dépoussiéreurs ronds - WAMECO</li>
                    <li className={styles.li}>
                    Filtres ronds bridés - WAMFLO 
                    </li>
                    <li className={styles.li}>
                    Filtres dépoussiéreurs pour silo - SILOTOP 
                    </li>
                    <li className={styles.li}>
                    Fume and Dust Filter - TECU
                    </li>
                    <li className={styles.li}>
                    Filtre de dégazage pour doseurs dans les centrales à béton - HOPPERTOP
                    </li>
                    <li className={styles.li}>
                    Pneumatic Conveying System for Automatic Recovery of Dust from Fume Filters - RECOFIL
                    </li>
                    
                  </div>
                </div>
              </ul>
            </div>
            {/* <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div className={styles.team_item}>
                    <h5 className={styles.team_item_h5}>
                    FILTRES A HAUT TEMPERATURE
                    </h5>
    
                    <div
                      className="img-fluid rounded-circle w-120 mb-4"
                      style={{ padding: "10px", border: "1px solid #abd473" }}
                    >
                      <img
                        className="img-fluid"
                        style={{ width: "100%", scale: "0.74" }}
                        src={flt2}
                        alt=""
                      />
                    </div>
                    <div class="d-flex justify-content-center">
                      <p className={styles.team_item_h5}>
                        
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div className={styles.team_item}>
                    <h5 className={styles.team_item_h5}>FILTRES A HAUT<br/> DEBIT</h5>
                    <div
                      className="img-fluid rounded-circle w-120 mb-4"
                      style={{ padding: "10px", border: "1px solid #abd473" }}
                    >
                      <img
                        className="img-fluid"
                        style={{ width: "100%", scale: "0.95" }}
                        src={flt3}
                        alt=""
                      />
                    </div>
                    <div class="d-flex justify-content-center">
                      <p className={styles.team_item_h5}>
                        
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div className={styles.team_item}>
                    <h5 className={styles.team_item_h5}>
                    SYSTEME DE GESTION DES FILTRES

                    </h5>
    
                    <div
                      className="img-fluid rounded-circle w-120 mb-4"
                      style={{ padding: "10px", border: "1px solid #abd473" }}
                    >
                      <img
                        className="img-fluid"
                        style={{ width: "100%", scale: "0.85" }}
                        src={flt4}
                        alt=""
                      />
                    </div>
                    <div class="d-flex justify-content-center">
                      <p className={styles.team_item_h5}>
                        
                      </p>
                    </div>
                  </div>
                </div> */}
          </div>
        </div>
        {/* <div style={{ marginTop: "3%" }}>
          <div>
            <p style={{ fontSize: "20px" }}>
              Vannes et soupapes pour tous les fluides ou solides : neutre,
              corrosifs, chargée ou abrasifs:
            </p>
          </div>
          <ul className={styles.ul}>
            <div className="row">
              <div className="col-md-6">
                <li className={styles.li}>Cyclones humides ou secs</li>
                <li className={styles.li}>Filtres à manches</li>
                <li className={styles.li}>Vannes à passage direct</li>
                <li className={styles.li}>Vannes à papillon - VFS</li>
                <li className={styles.li}>Vannes papillon pour silos - VFF</li>
                <li className={styles.li}>Vannes à opercule</li>
                <li className={styles.li}>Vannes à membrane</li>
                <li className={styles.li}>
                  Vannes à guillotine - VG -VGR - VL - SINT®
                </li>

                <li className={styles.li}>
                  Vannes à boisseau sphérique - VSS - hémisphérique
                </li>
                <li className={styles.li}>Vannes à sphère</li>
                <li className={styles.li}>Vannes à piston</li>
              </div>
              <div className="col-md-6">
                <li className={styles.li}>
                  Vannes By-pass ou à volet - VAR - DVA
                </li>
                <li className={styles.li}>
                  Vannes alvéolaires à gravité - RVC
                </li>
                <li className={styles.li}>Vannes à manchon - VM</li>
                <li className={styles.li}>
                  Vannes by-pass pour transport pneumatique - VAD
                </li>
                <li className={styles.li}>Vannes doseuses inclinées - VDI</li>
                <li className={styles.li}>
                  Distributeurs alvéolaires à passage direct - RV - RVR
                </li>
                <li className={styles.li}>
                  Distributeurs alvéolaires à passage tangentiel - RVS
                </li>
                <li className={styles.li}>
                  Soupapes de contrôle de pression à ressorts - VCP
                </li>
                <li className={styles.li}>
                  Soupapes de contrôle de pression à membrane - VHS
                </li>
                <li className={styles.li}>Manifold</li>
                <li className={styles.li}>Sas à double clapets - VDC</li>
              </div>
            </div>
          </ul>
        </div> */}
      </div>
    </div>
  );
}

/* Cyclones humides ou secs
Filtres à manches
Electrofiltres
Filtres dépoussiéreurs pour centrales à béton à sec - DRYBATCH
Filtres dépoussiéreurs polygonaux à secouage mécanique - DUSTSHAKE  
Filtres de dégazage pour trémies - HOPPERJET
Filtres dépoussiéreurs polygonaux - WAMAIR
Filtres polygonaux encastrables en dépression - WAMAIR 
Vacuum Filtres dépoussiéreurs ronds - WAMECO 
Filtres ronds bridés - WAMFLO 
Filtres dépoussiéreurs pour silo - SILOTOP 
Fume and Dust Filter - TECU
Filtre de dégazage pour doseurs dans les centrales à béton - HOPPERTOP
Pneumatic Conveying System for Automatic Recovery of Dust from Fume Filters - RECOFIL */
