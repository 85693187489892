import React from "react";
import styles from "./iso.module.css";

const bg = require("../../assets/CERTIFICAT ISO.png");

export default function Iso() {
  return (
    <div style={{ marginTop: "5%" }}>
      <div className="container">
        <div style={{ textAlign: "center" }}>
          <h1 className={styles.font_}>Certification ISO 9001 version 2015</h1>
          <hr
            style={{
              width: "60%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            
          />
        </div>
        <div className="row">
          <div className="col-md-6 how-img">
            <img
              src={bg}
              className="lazy"
              style={{
                width: "54%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
          <div className="col-md-6">
            <p
              className={styles.font_p}
              style={{ marginTop: "4%", lineHeight: "35px" }}
            >
              Nous sommes fiers de vous annoncer que notre entreprise est
              certifiée ISO 9001 version 2015 dans le sourcing et
              l'approvisionnement des équipements, rechanges et consommables
              pour les unités industrielles. Cette certification est la preuve
              de notre engagement envers l'excellence et la satisfaction de nos
              clients. Notre politique qualité repose sur plusieurs principes
              clés : la compréhension des besoins et attentes de nos clients, la
              sélection rigoureuse de nos fournisseurs, la maîtrise des
              processus d'approvisionnement et la formation continue de nos
              collaborateurs. Nous nous engageons à fournir des produits et
              services de qualité supérieure à nos clients, en respectant les
              délais convenus et en veillant à leur entière satisfaction. Nous
              sommes convaincus que notre expérience, notre savoir-faire et
              notre engagement nous permettent de répondre aux exigences les
              plus strictes de nos clients. N'hésitez pas à nous contacter pour
              en savoir plus sur nos produits et services. Nous serons ravis de
              répondre à vos questions et de vous accompagner dans tous vos
              projets d'approvisionnement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
