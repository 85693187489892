import styles from "./page.module.css";

const pic1 = require("../../assets/remove1.png");
const pic2 = require("../../assets/remove2.png");
const pic3 = require("../../assets/remove3.png");
const pic4 = require("../../assets/remove4.png");
const pic5 = require("../../assets/remove5.png");
const pic6 = require("../../assets/remove6.png");
const pic7 = require("../../assets/remove7.png");
const pic8 = require("../../assets/remove8.png");
const portatif = require("../../assets/portatif.gif");

export default function Category4(params) {
  return (
    <>
      <div className={styles.divise}>
        <div class="container-xxl py-6">
          <div class="container">
            <div
              class="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <div style={{ marginTop: "2%" }}>
                <h1 className={styles.font_}>
                  L’appareillage portatif et outillage de mesure et d'analyse
                </h1>
                <hr />
              </div>
            </div>
            {/* <div class="row g-4 mt-5">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}> </h5>
                            
                            <div className="img-fluid rounded-circle w-100 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"100%",scale:"1"}} src={pic1} alt="" />
                            </div>
                            <div class="d-flex justify-content-center" style={{}}>
                                <p  className={styles.team_item_h5} ></p>
                            </div>
                        </div>
                    </div>                                                                  
                    <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}></h5>
                            
                            <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"100%",scale:"0.95"}} src={pic2} alt="" />
                            </div>
                            <div class="d-flex justify-content-center">
                            <p  className={styles.team_item_h5} ></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}></h5>
                            <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"100%",scale:"0.95"}} src={pic3} alt="" />
                            </div>
                            <div class="d-flex justify-content-center">
                                <p  className={styles.team_item_h5} ></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}></h5>
                            
                            <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"100%",scale:"0.95"}} src={pic4} alt="" />
                            </div>
                            <div class="d-flex justify-content-center">
                                <p  className={styles.team_item_h5} ></p>
                            </div>
                        </div>
                    </div>
                </div> */}

            <div>
              <h2
                className={styles.font}
                style={{ textAlign: "center", marginTop: "3%" }}
              >
                Découvrez notre large sélection d'agitateurs de haute qualité
                sur le site de notre approvisionneur technique
              </h2>
            </div>

            <div class="row g-4" style={{ marginTop: "3%" }}>
              <div>
                <p style={{ fontSize: "20px" }}>Zone paragraph</p>
              </div>
              <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div
                  className={styles.team_item}
                  style={{
                    width: "50%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <h5 className={styles.team_item_h5}></h5>
                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={portatif}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <ul className={styles.ul}>
                  <div className="row">
                    <li className={styles.li}>Mesure de température</li>
                    <li className={styles.li}>Mesure de vibration</li>
                    <li className={styles.li}>Mesure de débit</li>
                    <li className={styles.li}>Mesure de lumière</li>

                    <li className={styles.li}>Mesure d’épaisseur</li>
                    <li className={styles.li}>Analyse des liquides</li>
                    <li className={styles.li}>
                      Mesure du couple et de la force
                    </li>
                    <li className={styles.li}>Mesure de température</li>
                  </div>
                </ul>
              </div>
              {/* <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}></h5>
                            <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"100%",scale:"0.95"}} src={pic6} alt="" />
                            </div>
                            <div class="d-flex justify-content-center">
                                <p  className={styles.team_item_h5} ></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}></h5>
                            
                            <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"100%",scale:"0.95"}} src={pic7} alt=""/>
                            </div>
                            <div class="d-flex justify-content-center">
                                <p className={styles.team_item_h5}></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className={styles.team_item}>
                            <h5 className={styles.team_item_h5}></h5>
                            <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"100%",scale:"0.95"}} src={pic8} alt=""/>
                            </div>
                            <div class="d-flex justify-content-center">
                                <p  className={styles.team_item_h5} ></p>
                            </div>
                    </div>
                    </div> */}
            </div>
          </div>
          {/* <div style={{ marginTop: "3%" }}>
            <div>
              <p style={{ fontSize: "20px" }}>Zone paragraph</p>
            </div>
            <ul className={styles.ul}>
              <div className="row">
                <div className="col-md-6">
                  <li className={styles.li}>Mesure de température</li>
                  <li className={styles.li}>Mesure de vibration</li>
                  <li className={styles.li}>Mesure de débit</li>
                  <li className={styles.li}>Mesure de lumière</li>
                </div>
                <div className="col-md-6">
                  <li className={styles.li}>Mesure d’épaisseur</li>
                  <li className={styles.li}>Analyse des liquides</li>
                  <li className={styles.li}>Mesure du couple et de la force</li>
                  <li className={styles.li}>Mesure de température</li>
                </div>
              </div>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
}
