import styles from "./test.module.css";


const pic = require("../../assets/erf.png")

export default function TestEng(params) {
    return(
        <>
        <div className={styles.container}>
            <img src={pic} style={{width:"50%",display:"block",marginRight:"auto",marginLeft:"auto",padding:"20px"}} />
            <a className={styles.top_right} href="#ed" style={{marginTop:"2%",opacity:"0%"}}>SAV</a>
            <a className={styles.top_right1} href="#edd" style={{marginTop:"2%"}}>sr</a>
        </div>

        <div id="ed" className={styles.tab}>
            <h5>test2</h5>
        </div>
        <div id="edd" className={styles.tab}>
            <h5>test1</h5>
        </div>
        </>
    )
}