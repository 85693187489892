import React from "react";
import styles from "./compresseur.module.css";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

const cmp1 = require("../../assets/comp-pist.png");
const cmp2 = require("../../assets/cpmr-vis.png");
const cmp3 = require("../../assets/cmpr--.png");
const cmp4 = require("../../assets/compresseur_inso.png");
const cmp5 = require("../../assets/she-air.png");
const cmp6 = require("../../assets/sept.png");
const compresseur = require("../../assets/compresseur.gif");

export default function Compresseur(params) {
  return (
    <div>
      <div class="container-xxl py-6">
        <div class="container">
          <div
            class="mx-auto text-center wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <div style={{ marginTop: "2%" }}>
              <h1 className={styles.font_}>Compresseur</h1>
              <hr />
              <Breadcrumbs
                aria-label="breadcrumb"
                separator=">"
                sx={{
                  "& ol": {
                    justifyContent: "center",
                    margin: "auto",
                  },
                }}
              >
                <Link
                  to="/equipement-process"
                  style={{ textDecoration: "none", color: "#3367ec" }}
                >
                  Produits
                </Link>

                <Link
                  to="/equipement-process"
                  style={{ textDecoration: "none", color: "#3367ec" }}
                >
                  equipement process
                </Link>

                <Typography color="text.primary">Compresseur</Typography>
              </Breadcrumbs>
            </div>
          </div>

          <div>
            <h2
              className={styles.font}
              style={{ textAlign: "center", marginTop: "3%" }}
            >
              Découvrez notre gamme étendue de compresseurs, spécialement
              sélectionnés pour répondre à vos besoins spécifiques
            </h2>
          </div>

          <div class="row g-4 mt-5">
            <div>
              <p style={{ fontSize: "20px" }}>
              Consultez-nous dès aujourd'hui pour bénéficier de notre expertise et trouver le compresseur idéal qui répondra à vos exigences précises et améliorera l'efficacité de vos opérations.
              </p>
            </div>
            <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div
                className={styles.team_item}
                style={{
                  width: "50%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <h5 className={styles.team_item_h5}></h5>

                <div
                  className="img-fluid rounded-circle w-100 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "1" }}
                    src={compresseur}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center" style={{}}>
                  <p className={styles.team_item_h5}></p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <ul className={styles.ul}>
                <div className="row">
                  <li className={styles.li}>Compresseurs à piston</li>
                  <li className={styles.li}>Compresseurs à vis</li>
                  <li className={styles.li}>Compresseurs sans huile</li>

                  <li className={styles.li}>Compresseurs insonorisés</li>
                  <li className={styles.li}>Sécheurs d’air comprimé</li>
                  <li className={styles.li}>Séparateurs de condensat</li>
                </div>
              </ul>
            </div>
            {/* <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}>
                
                </h5>

                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "1" }}
                    src={cmp2}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}>
                    
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}></h5>
                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "60%", scale: "0.95" }}
                    src={cmp3}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}>
                   
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div class="row g-4" style={{ marginTop: "3%" }}>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}></h5>
                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "70%", scale: "0.95" }}
                    src={cmp4}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}>
                    
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}></h5>
                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "70%", scale: "0.95" }}
                    src={cmp5}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}>
                    
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className={styles.team_item}>
                <h5 className={styles.team_item_h5}></h5>

                <div
                  className="img-fluid rounded-circle w-120 mb-4"
                  style={{ padding: "10px", border: "1px solid #abd473" }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "70%", scale: "0.95" }}
                    src={cmp6}
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p className={styles.team_item_h5}>
                    
                  </p>
                </div>
              </div>
            </div>
            
          </div> */}
        </div>
        {/* <div style={{ marginTop: "3%" }}>
          <ul className={styles.ul}>
            <div className="row">
              <div className="col-md-6">
                <li className={styles.li}>Compresseurs à piston</li>
                <li className={styles.li}>Compresseurs à vis</li>
                <li className={styles.li}>Compresseurs sans huile</li>
              </div>
              <div className="col-md-6">
                <li className={styles.li}>Compresseurs insonorisés</li>
                <li className={styles.li}>Sécheurs d’air comprimé</li>
                <li className={styles.li}>Séparateurs de condensat</li>
              </div>
            </div>
          </ul>
        </div> */}
      </div>
    </div>
  );
}
