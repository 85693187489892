import React from "react";
import styles from "./ventilateur.module.css";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

const van_copape = require("../../assets/van-sopape.png");
const van_clapet = require("../../assets/van-clapet.png");
const van_pass = require("../../assets/van-pass.png");
const van = require("../../assets/vanvan.png");
const van_oper = require("../../assets/van-opper.png");
const van_sphere = require("../../assets/vanne-sphere.png");
const van_pist = require("../../assets/van_piston.png");
const van_moch = require("../../assets/van_moch.png");

export default function Ventilateur() {
  return (
    <div class="container-xxl py-6">
      <div class="container">
        <div
          class="mx-auto text-center wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <div style={{ marginTop: "2%" }}>
            <h1 className={styles.font_} style={{ fontSize: "50px" }}>
              Ventilateur
            </h1>
            <hr />
            <Breadcrumbs
              aria-label="breadcrumb"
              separator=">"
              sx={{
                "& ol": {
                  justifyContent: "center",
                  margin: "auto",
                },
              }}
            >
              <Link
                to="/equipement-process"
                style={{ textDecoration: "none", color: "#3367ec" }}
              >
                Produits
              </Link>

              <Link
                to="/equipement-process"
                style={{ textDecoration: "none", color: "#3367ec" }}
              >
                equipement process
              </Link>

              <Typography color="text.primary">Ventilateur</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div class="row g-4 mt-5">
          <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div
              className={styles.team_item}
              style={{
                width: "50%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <h5 className={styles.team_item_h5}></h5>

              <div
                className="img-fluid rounded-circle w-100 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "60%", scale: "1" }}
                  src={van_copape}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center" style={{}}>
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <ul className={styles.ul}>
              <div className="row">
                <li className={styles.li}>
                  Ventilateurs centrifuges à action simple ouïe
                </li>
                <li className={styles.li}>
                  Ventilateurs centrifuges à action double ouïe
                </li>
                <li className={styles.li}>
                  Ventilateurs centrifuges à réaction
                </li>
                <li className={styles.li}>Ventilateurs axiaux</li>

                <li className={styles.li}>Ventilateurs pour air chaud</li>
                <li className={styles.li}>Ventilateurs tangentiels</li>
                <li className={styles.li}>Moteurs à rotor extérieur</li>
              </div>
            </ul>
          </div>
          {/* <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item}>
              <h5 className={styles.team_item_h5}></h5>

              <div
                className="img-fluid rounded-circle w-120 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "70%", scale: "0.74" }}
                  src={van_clapet}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center">
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item}>
              <h5 className={styles.team_item_h5}></h5>
              <div
                className="img-fluid rounded-circle w-120 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "70%", scale: "0.7" }}
                  src={van_pass}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center">
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div style={{ marginTop: "3%" }}>
        <div>
          <p style={{ fontSize: "20px" }}>
            Vannes et soupapes pour tous les fluides ou solides : neutre,
            corrosifs, chargée ou abrasifs:
          </p>
        </div>
        <ul className={styles.ul}>
          <div className="row">
            <div className="col-md-6">
              <li className={styles.li}>
                Ventilateurs centrifuges à action simple ouïe
              </li>
              <li className={styles.li}>
                Ventilateurs centrifuges à action double ouïe
              </li>
              <li className={styles.li}>Ventilateurs centrifuges à réaction</li>
              <li className={styles.li}>Ventilateurs axiaux</li>
            </div>
            <div className="col-md-6">
              <li className={styles.li}>Ventilateurs pour air chaud</li>
              <li className={styles.li}>Ventilateurs tangentiels</li>
              <li className={styles.li}>Moteurs à rotor extérieur</li>
            </div>
          </div>
        </ul>
      </div> */}
    </div>
  );
}
