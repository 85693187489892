import styles from './header.module.css';
import { Link, NavLink } from 'react-router-dom';


const logo = require("../../assets/appro-logo.png");

export default function Header(){

  
  return(
    <nav className="navbar navbar-expand-lg bg-light navbar-light sticky-top px-4 px-lg-5" >
        <Link to="/" className="navbar-brand d-flex align-items-center">
            <h1 className="m-0"><img className="img-fluid me-3"  src={logo} width={170} alt="" /></h1>
        </Link>
        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto bg-light pe-4 py-3 py-lg-0 ">
                <Link to="/"  className="nav-item nav-link p-3">Acceuil</Link>
                
                <Link to="services" className="nav-item nav-link p-3">Services</Link>
                {/* <div className="nav-item dropdown">
                    <Link href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</Link>
                    <div className="dropdown-menu bg-light border-0 m-0">
                        <Link href="feature.html" className="dropdown-item">Features</Link>
                        <Link href="quote.html" className="dropdown-item">Free Quote</Link>
                        <Link href="team.html" className="dropdown-item">Our Team</Link>
                        <Link href="testimonial.html" className="dropdown-item">Testimonial</Link>
                        <Link href="404.html" className="dropdown-item">404 Page</Link>
                    </div>
                </div> */}
                
                <Link to="/products" className="nav-item nav-link p-3" >Produits</Link>
                <Link to="/nos-projets" className="nav-item nav-link p-3">Réalisations</Link>
                <Link to="/about" className="nav-item nav-link p-3">À propos</Link>
                <Link to="/iso" className="nav-item nav-link p-3">Iso</Link>
                <Link to="/contact-us" className="nav-item nav-link p-3">Contact</Link>
                
            </div>
            <div className="">
            <form class="d-flex">
            <Link class="btn btn-primary" to="/contact-us" style={{marginRight:"5%",fontSize:"15px" }} type="button">Demande devis</Link>
          </form>
            </div>
        </div>
    </nav>
  )

  return(

<nav class="navbar navbar-expand-sm navbar-dark bg-dark">
      <div class="container-fluid ">
        <NavLink class="navbar-brand"  to="/"><img src={logo} style={{width:"20%",height:"20%"}} /></NavLink>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse " id="mynavbar">
          <ul class="navbar-nav me-auto">
            <li class="nav-item ">
              <NavLink class="nav-link" className={styles.rub} to="/">Acceuil</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="/Societes">Societe</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="/services">Services</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="/marques">Marques</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="javascript:void(0)">Blogs</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="javascript:void(0)">About</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="/contact-us">Contact</NavLink>
            </li>
          </ul>
          <form class="d-flex">
            <button class="btn btn-primary" style={{marginRight:"5%",fontSize:"15px"}} type="button">Demande devis</button>
          </form>
        </div>
      </div>
    </nav>
  
  )


    return(

      <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
      <div class="container-fluid ">
        <NavLink class="navbar-brand"  to="/"><img src={logo} style={{width:"16%",height:"20%"}} /></NavLink>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse " id="mynavbar">
          <ul class="navbar-nav me-auto">
            <li class="nav-item ">
              <NavLink class="nav-link" className={styles.rub} to="/">Acceuil</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="/Societes">Societe</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="/services">Services</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="/marques">Marques</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="javascript:void(0)">Blogs</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="javascript:void(0)">About</NavLink>
            </li>
            <li class="nav-item">
              <NavLink class="nav-link" className={styles.rub} to="/contact-us">Contact</NavLink>
            </li>
          </ul>
          <form class="d-flex">
            <button class="btn btn-primary" style={{marginRight:"5%",fontSize:"15px"}} type="button">Demande devis</button>
          </form>
        </div>
      </div>
    </nav>
    
    )
return(
    <nav class="navbar navbar-expand-lg py-3 navbar-dark bg-dark shadow-sm">
  <div class="container">
    <a href="#" class="navbar-brand">
      <img src="https://bootstrapious.com/i/snippets/sn-nav-logo/logo.png" width="45" alt="" class="d-inline-block align-middle mr-2" />
      <span class="text-uppercase font-weight-bold">Company</span>
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span class="navbar-toggler-icon"></span>
        </button>

    <div id="mynavbar" class="collapse navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active"><a href="#" class="nav-link">Home <span class="sr-only">(current)</span></a></li>
        <li class="nav-item"><a href="#" class="nav-link">About</a></li>
        <li class="nav-item"><a href="#" class="nav-link">Services</a></li>
        <li class="nav-item"><a href="#" class="nav-link">Contact</a></li>
      </ul>
    </div>
  </div>
</nav>
  )
}