import { Link } from "react-router-dom";
import styles from "./pompe.module.css";
import { Breadcrumbs, Typography } from "@mui/material";

const pmp1 = require("../../assets/pmp1.png");
const ds1 = require("../../assets/pompe-centr-1.png");
const ds2 = require("../../assets/pompe-centr-2.png");
const pm1 = require("../../assets/sb.png");
const pm2 = require("../../assets/3-.png");
const pm3 = require("../../assets/4-.png");
const pm4 = require("../../assets/7-.png");
const pm5 = require("../../assets/5.png");
const pm6 = require("../../assets/8.png");
const bg = require("../../assets/backgr.jpg");
const bg1 = require("../../assets/accprod.jpg");
const pm7 = require("../../assets/11.png");
const pm8 = require("../../assets/10.png");
const pm9 = require("../../assets/9.png");
const pm10 = require("../../assets/100.png");
const pm11 = require("../../assets/27.png");
const emica = require("../../assets/emica.png");
const tecnium = require("../../assets/tecnium.png");
const pm12 = require("../../assets/pm15.png");
const pm13 = require("../../assets/pm16.png");
const pm14 = require("../../assets/17.png");
const pm15 = require("../../assets/pm19.png")


export default function PompeDetails(params) {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className={styles.divise}>
        <div style={{ textAlign: "center" }}>
          <h1 className={styles.font_}>
            L'APPROVISIONNEUR TECHNIQUE INTERNATIONAL SA
          </h1>
          <h4 className={styles.second_font}>
            offre une vaste gammede pompes et des solutions sur le mesure ou
            standards pour vos besoins en pompage
          </h4>
        </div>
        <hr
          style={{
            width: "90%",
            display: "block",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        />
        <Breadcrumbs
                aria-label="breadcrumb"
                separator=">"
                sx={{
                  "& ol": {
                    justifyContent: "center",
                    margin: "auto",
                  },
                }}
              >
                <Link
                  to="/equipement-process"
                  style={{ textDecoration: "none", color: "#3367ec" }}
                >
                  Product
                </Link>

                <Link
                  to="/equipement-process"
                  style={{ textDecoration: "none", color: "#3367ec" }}
                >
                  equipement process
                </Link>

                <Typography color="text.primary">Pompe</Typography>
              </Breadcrumbs>
        <div
          style={{ position: "relative", textAlign: "center", color: "white" }}
        >
          <img src={bg1} style={{ width: "100%" }} />

          <div className={styles.centered}>
            <div
              style={{
                textAlign: "center",
                backgroundColor: "white",
                opacity: "80%",
                backgroundSize: "cover",
              }}
            >
              <h1 className={styles.font_}>
                L'APPROVISIONNEUR TECHNIQUE INTERNATIONAL SA
              </h1>
              <h4 className={styles.second_font}>
                offre une vaste gammede pompes et des solutions sur le mesure ou
                standards pour vos besoins en pompage
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.divise}>
        <div style={{ textAlign: "left", padding: "5%" }}>
          <h3 className={styles.font_}>POMPES CENTRIFUGER EMICA</h3>
          <h4 className={styles.second_font}>
            distributeur officiel des pompes EMICA ( Fabricant espagnol )
          </h4>
          <Link to="https://www.emicabombas.com/fr">
            <img src={emica} style={{ float: "right", width: "10%" }} />
          </Link>
          <hr style={{ width: "60%" }} />
        </div>

        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1
                  className={styles.font2}
                  style={{
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "2%",
                  }}
                >
                  LES POMPES EMICA <br />
                  CONVIENNENT AUX POMPAGES DES
                </h1>
              </div>
              <div className="col-md-6">
                <p
                  style={{
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                    textAlign: "center",
                    lineHeight: "300%",
                  }}
                >
                  <center>
                    <ul
                      style={{
                        textAlign: "left",
                        listStyleType: "circle",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "inline-block",
                      }}
                      className={styles.font_p}
                    >
                      <li>
                        <h3>Eaux de mer</h3>
                      </li>

                      <li>
                        <h3>Eaux condensats</h3>
                      </li>

                      <li>
                        <h3>Eaux potables</h3>
                      </li>

                      <li>
                        <h3>Eaux usées</h3>
                      </li>
                    </ul>
                  </center>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className={styles.features_area}>
        <div className="container">
          <div
            className="row"
            style={{
              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
              padding: "40px 0",
              backgroundColor: "#abd473",
            }}
          >
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div className={styles.single_features}>
                <div class="f-icon">
                  <img
                    src={pm1}
                    style={{ width: "60%", marginTop: "25%" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div className={styles.single_features}>
                <div class="f-icon">
                  <img
                    src={pm2}
                    style={{ width: "75%", marginTop: "10%" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className={styles.single_features}>
                <div class="f-icon">
                  <img src={pm3} style={{ width: "50%" }} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div>
                <div class="f-icon">
                  <img
                    src={pm4}
                    style={{ width: "80%", marginTop: "15%" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={styles.divise}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1
                className={styles.font2}
                style={{
                  display: "block",
                  marginRight: "auto",
                  marginLeft: "auto",
                  marginTop: "10%",
                }}
              >
                ET BIEN D'AUTRE APPLICATIONS EN MILIEU INDUSTRIEL
              </h1>
            </div>
            <div className="col-md-6">
              <p
                style={{
                  display: "block",
                  marginRight: "auto",
                  marginLeft: "auto",
                  textAlign: "center",
                  lineHeight: "300%",
                }}
              >
                <center>
                  <ul
                    style={{
                      textAlign: "left",
                      listStyleType: "circle",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "inline-block",
                    }}
                    className={styles.font_p}
                  >
                    <li>
                      <h4>
                        Liquides corrosifs: acides, bases,chlorures....etc
                      </h4>
                    </li>

                    <li>
                      <h4>Pulpes abrasives</h4>
                    </li>

                    <li>
                      <h4>Liquides de refroidissement</h4>
                    </li>

                    <li>
                      <h4>Peintures</h4>
                    </li>
                    <li>
                      <h4>Huiles minErales</h4>
                    </li>
                  </ul>
                </center>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.divise}>
        <div style={{ textAlign: "left", padding: "5%" }}>
          <h2 className={styles.font_}>LA GAMME DES PRODUITS EMICA</h2>
          <hr style={{ width: "40%" }} />
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div
                  class="card"
                  style={{
                    width: "25rem",
                    height: "100%",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <img
                    src={pm2}
                    class="card-img-top"
                    style={{
                      width: "50%",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "3%",
                    }}
                    alt="..."
                  />
                  <div class="card-body" style={{ marginTop: "2%" }}>
                    <h5
                      class="card-title"
                      style={{
                        textAlign: "center",
                        color: "#abd473",
                        marginTop: "2%",
                      }}
                    >
                      LES POMPES CENTRIFUGER A PLAN DE JOINT
                    </h5>
                    <p
                      class="card-text"
                      style={{
                        textAlign: "left",
                        color: "#2b4a99",
                        marginTop: "2%",
                      }}
                    >
                      <ul>
                        <li>TYPE : RDL ET RDLV </li>
                        <li>
                          DEBUT : JUSQU'A 5000 M<sup>3</sup>
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  class="card"
                  style={{
                    width: "25rem",
                    height: "100%",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <img
                    src={pm5}
                    class="card-img-top"
                    style={{
                      width: "30%",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "3%",
                    }}
                    alt="..."
                  />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{
                        textAlign: "center",
                        color: "#abd473",
                        marginTop: "2%",
                      }}
                    >
                      LES POMPES CENTRIFUGER VERTICALES
                    </h5>
                    <p
                      class="card-text"
                      style={{
                        textAlign: "left",
                        color: "#2b4a99",
                        marginTop: "2%",
                      }}
                    >
                      <ul>
                        <li>TYPE : SEZ, WKB, EL, B/BF, VUA </li>
                      </ul>
                      Ces pompes sont adaptées pour des applications de pompage
                      à partir : des fosses, puits, cannivaux, surface ouvertes
                      (mer), rivières, bassin..etc
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="container" >
            <div className="row mt-2">
              <div className="col-md-6">
                <div
                  class="card"
                  style={{
                    width: "25rem",
                    height: "100%",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <img
                    src={pm4}
                    class="card-img-top"
                    style={{
                      width: "50%",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "3%",
                    }}
                    alt="..."
                  />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{
                        textAlign: "center",
                        color: "#abd473",
                        marginTop: "2%",
                      }}
                    >
                      LES POMPES CENTRIFUGES MULTICELLULAIRE HAUTE PRESSION
                    </h5>
                    <p
                      class="card-text"
                      style={{
                        textAlign: "left",
                        color: "#2b4a99",
                        marginTop: "2%",
                      }}
                    >
                      <ul>
                        <li>
                          Pompes centrifuges multicellulaires utilisées
                          fréquemment pour des applications avec des pressions
                          élevées.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  class="card"
                  style={{
                    width: "25rem",
                    height: "100%",
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <img
                    src={pm6}
                    class="card-img-top"
                    style={{
                      width: "30%",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "3%",
                    }}
                    alt="..."
                  />
                  <div class="card-body" style={{ marginTop: "2%" }}>
                    <h5
                      class="card-title"
                      style={{
                        textAlign: "center",
                        color: "#abd473",
                        marginTop: "2%",
                      }}
                    >
                      LES POMPES CENTRIFUGES HORIZONTALES
                    </h5>
                    <p
                      class="card-text"
                      style={{
                        textAlign: "left",
                        color: "#2b4a99",
                        marginTop: "2%",
                      }}
                    >
                      <ul>
                        <li>
                          Ce type des pompes est le plus utilisé pour des
                          applications de pompage des liquides claires ou
                          chargés en milieu industriel
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          
        </div>
      </div>

      <div className={styles.divise}>
        <div style={{ textAlign: "left", padding: "5%" }}>
          <h3 className={styles.font_}>
            <b>TECNIUM POMPES CENTRIFUGES POUR LIQUIDES CORASSIFS</b>
          </h3>
          <h4 className={styles.second_font}>
            L'APPROVISIONNEUR TECHINOUE ET DISTRIBUTEUR OFFICIEL DE LA MARQUE
            TECNIUM (FABRICANT ESPAGNOL)
          </h4>
          <Link to="https://www.tecnium.es/news-events/">
            <img src={tecnium} className={styles.logo_sites} />
          </Link>
          <hr style={{ width: "75%" }} />
        </div>
        <div style={{ textAlign: "center" }}>
          <h3 style={{ color: "#2b4a99" }}>
            POMPES EN MATÉRIAUX THERMOPLASTIQUES
          </h3>
          <h3 style={{ color: "#abd473" }}>PVC, PE, PP, PVDF, HALAR... ETC</h3>
          <h5>POUR LES LIQUIDES CORROSIFS</h5>
          <hr
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
            }}
          />
        </div>

        <div className="container" style={{ marginTop: "5%" }}>
          <div className="row" style={{ width: "100%" }}>
            <div className="col">
              <img
                src={pm7}
                style={{
                  padding: "2%",
                  width: "100%",
                  display: "block",
                  margin: "auto",
                  marginTop: "7%",
                }}
              />
            </div>
            <div className="col">
              <img
                src={pm8}
                style={{
                  padding: "2%",
                  width: "40%",
                  display: "block",
                  margin: "auto",
                }}
              />
            </div>
            <div className="col">
              <img
                src={pm9}
                style={{
                  padding: "2%",
                  width: "100%",
                  display: "block",
                  margin: "auto",
                  marginTop: "7%",
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "#2b4a99" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1
                  style={{
                    textAlign: "center",
                    color: "white",
                    margin: "auto",
                    display: "block",
                    marginTop: "7%",
                  }}
                  className={styles.fonts__}
                >
                  les pompes TECNIUM sont conçues pour les conditions les plus
                  sévéres et liquides les plus corrosifs{" "}
                </h1>
              </div>
              <div className="col-md-6">
                <p
                  style={{
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                    textAlign: "center",
                    lineHeight: "300%",
                  }}
                >
                  <center>
                    <ul
                      style={{
                        textAlign: "left",
                        listStyleType: "circle",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "inline-block",
                        color: "white",
                        marginTop: "5%",
                      }}
                      className={styles.font_p}
                    >
                      <li>
                        <h3>Acide sulfurique</h3>
                      </li>

                      <li>
                        <h3>Acide chlorhydrique</h3>
                      </li>

                      <li>
                        <h3>Acide nitrique</h3>
                      </li>

                      <li>
                        <h3>Soude coustique</h3>
                      </li>
                    </ul>
                  </center>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.divise}>
          <div style={{ textAlign: "left", padding: "5%" }}>
            <h2 className={styles.font_}>LA GAMME DES PRODUITS EMICA</h2>
            <hr style={{ width: "40%" }} />
          </div>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div
                    class="card"
                    style={{
                      width: "25rem",
                      height: "100%",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <img
                      src={pm7}
                      class="card-img-top"
                      style={{
                        width: "50%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "3%",
                      }}
                      alt="..."
                    />
                    <div class="card-body" style={{ marginTop: "2%" }}>
                      <h5
                        class="card-title"
                        style={{
                          textAlign: "center",
                          color: "#abd473",
                          marginTop: "2%",
                        }}
                      >
                        POMPES CENTRIFUGES HORIZONTALES PROCESSSÉRIE BN
                      </h5>
                      <p
                        class="card-text"
                        style={{
                          textAlign: "left",
                          color: "#2b4a99",
                          marginTop: "2%",
                        }}
                      >
                        <ul>
                          <li>DÉBIT : JUSQU'À 1500M/H</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    class="card"
                    style={{
                      width: "25rem",
                      height: "100%",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <img
                      src={pm9}
                      class="card-img-top"
                      style={{
                        width: "60%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "3%",
                      }}
                      alt="..."
                    />
                    <div class="card-body">
                      <h5
                        class="card-title"
                        style={{
                          textAlign: "center",
                          color: "#abd473",
                          marginTop: "2%",
                        }}
                      >
                        POMPES CENTRIFUGES HORIZONTALES SÉRIE BHC
                      </h5>
                      <p
                        class="card-text"
                        style={{
                          textAlign: "left",
                          color: "#2b4a99",
                          marginTop: "2%",
                        }}
                      >
                        <ul>
                          <li>DÉBIT : JUSQU'À 250M3/H</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>

              <div className="container" >
              <div className="row mt-2">
                <div className="col-md-6">
                  <div
                    class="card"
                    style={{
                      width: "25rem",
                      height: "100%",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <img
                      src={pm10}
                      class="card-img-top"
                      style={{
                        width: "50%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "3%",
                      }}
                      alt="..."
                    />
                    <div class="card-body">
                      <h5
                        class="card-title"
                        style={{
                          textAlign: "center",
                          color: "#abd473",
                          marginTop: "2%",
                        }}
                      >
                        POMPES CENTRIFUGES VERTICALES EN LIGNE SÉRIE BVS
                      </h5>
                      <p
                        class="card-text"
                        style={{
                          textAlign: "left",
                          color: "#2b4a99",
                          marginTop: "2%",
                        }}
                      >
                        <ul>
                          <li>DÉBIT : JUSQU'À 1000M3/H</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    class="card"
                    style={{
                      width: "25rem",
                      height: "100%",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <img
                      src={pm11}
                      class="card-img-top"
                      style={{
                        width: "18%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "3%",
                      }}
                      alt="..."
                    />
                    <div class="card-body" style={{ marginTop: "2%" }}>
                      <h5
                        class="card-title"
                        style={{
                          textAlign: "center",
                          color: "#abd473",
                          marginTop: "2%",
                        }}
                      >
                        POMPES CENTRIFUGES VERTICALES SÉRIE BT / BA
                      </h5>
                      <p
                        class="card-text"
                        style={{
                          textAlign: "left",
                          color: "#2b4a99",
                          marginTop: "2%",
                        }}
                      >
                        <ul>
                          <li>DÉBIT : JUSQU'À 450M3/H</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            
          </div>
        </div>
      </div>
      <div className={styles.divise}>
        
        <div className="row" style={{ width: "100%" }}>
          <div className={styles.testimonials}>
            <div className={styles.testimonials_card}>
              <div className={styles.testimonials_card_layer}></div>
              <div className={styles.testimonials_card_content}>
                <div className={styles.testimonials_image}>
                  <img
                    src={pm12}
                    style={{ width: "100%", scale: "0.87" }}
                    alt=""
                  />
                </div>
                <div>
                  <h2 className={styles.testimonials_details_h2} style={{marginTop:"10%"}}>
                    POMPES VIDE FÜT
                    <br />
                  </h2>
                </div>
                <p className={styles.testimonials_content_p}>
                  Ces pompes conviennent au pompage des fluides à faible
                  viscosité ou fluides corrosifs à partir des fûts et
                  conte-neurs.
                </p>
              </div>
            </div>

            <div className={styles.testimonials_card}>
              <div className={styles.testimonials_card_layer}></div>
              <div className={styles.testimonials_card_content}>
                <div className={styles.testimonials_image}>
                  <img
                    src={pm13}
                    style={{ width: "100%", scale: "0.64", marginTop: "-10%" }}
                    alt=""
                  />
                </div>
                <div>
                  <h2 className={styles.testimonials_details_h2} style={{marginTop:"10%"}}>
                    POMPES À MEMBRANE
                    <br/>
                  </h2>
                </div>
                <p className={styles.testimonials_content_p}>
                  Pompes pneumatiques à membranes. Grace à leurs conception, ces
                  pompes peuvent être installées à n'importe quelle position.
                  Pompes disponibles en plastique, en métal et aussi en version
                  alimentaire et ATEX
                </p>
              </div>
            </div>
            <div className={styles.testimonials_card}>
              <div className={styles.testimonials_card_layer}></div>
              <div className={styles.testimonials_card_content}>
                <div className={styles.testimonials_image}>
                  <img
                    src={pm14}
                    style={{ width: "100%", scale: "0.9", marginTop: "0%" }}
                    alt=""
                  />
                </div>
                <div>
                  <h2 className={styles.testimonials_details_h2} style={{marginTop:"10%"}}>
                    POMPES À PULPE
                    <br />
                  </h2>
                </div>
                <p className={styles.testimonials_content_p}>
                  Pompes pour pulpe abrasive avec différentes conceptions
                  possibles selon l'utilisation: métallique / avec revêtement en
                  caoutchouc, Garnitures mécaniques, tresse, expeller...etc
                </p>
              </div>
            </div>
            <div className={styles.testimonials_card}>
              <div className={styles.testimonials_card_layer}></div>
              <div className={styles.testimonials_card_content}>
                <div className={styles.testimonials_image}>
                  <img src={pm14} style={{ width: "100%", scale: "0.9", marginTop: "0%" }} alt="" />
                </div>
                <div>
                  <h2 className={styles.testimonials_details_h2} style={{marginTop:"10%"}}>
                    POMPES DOSEUSES
                    <br />
                  </h2>
                </div>
                <p className={styles.testimonials_content_p}>
                  Pompes utilisées pour le contôle des débits avec précision
                  pour différentes applications
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row" style={{ width: "100%" ,marginTop:"0%"}}>
          <div className={styles.testimonials}>
            <div className={styles.testimonials_card}>
              <div className={styles.testimonials_card_layer}></div>
              <div className={styles.testimonials_card_content}>
                <div className={styles.testimonials_image}>
                  <img src={pm13} alt="" />
                </div>
                <div>
                  <h2 className={styles.testimonials_details_h2} style={{marginTop:"10%"}}>
                    POMPES À VIS EXCENTRÉE
                    <br />
                  </h2>
                </div>
                <p className={styles.testimonials_content_p}>
                  Pompes adaptées pour les applications qui requièrent un débit
                  stable quelle que soit la viscosité du produit à pomper ou la
                  pression.
                </p>
              </div>
            </div>

            <div className={styles.testimonials_card}>
              <div className={styles.testimonials_card_layer}></div>
              <div className={styles.testimonials_card_content}>
                <div className={styles.testimonials_image}>
                  <img src={pm13} alt="" />
                </div>
                <div>
                  <h2 className={styles.testimonials_details_h2} style={{marginTop:"10%"}}>
                    POMPES PÉRISTALTIQUES
                    <br />
                  </h2>
                </div>
                <p className={styles.testimonials_content_p}>
                  Ces pompes offrent l'avantage d'une maintenance aisée et un
                  pompage doux des produits particulièrement visqueux,
                  corrosifs, abrasifs et liquides sensibles aux cisaiNement"
                  polymères, huiles ...etc"
                </p>
              </div>
            </div>
            <div className={styles.testimonials_card}>
              <div className={styles.testimonials_card_layer}></div>
              <div className={styles.testimonials_card_content}>
                <div className={styles.testimonials_image}>
                  <img src={pm13} alt="" />
                </div>
                <div>
                  <h2 className={styles.testimonials_details_h2} style={{marginTop:"10%"}}>
                    POMPES À ENGRENAGE ET À PALETTE
                    <br />
                  </h2>
                </div>
                <p className={styles.testimonials_content_p}>
                  Pompes capables de véhiculer une large gamme de liquides
                  depuis les gaz liquéfiés aux produits très visqueux.
                </p>
              </div>
            </div>
            <div className={styles.testimonials_card}>
              <div className={styles.testimonials_card_layer}></div>
              <div className={styles.testimonials_card_content}>
                <div className={styles.testimonials_image}>
                  <img src={pm13} alt="" />
                </div>
                <div>
                  <h2 className={styles.testimonials_details_h2} style={{marginTop:"10%"}}>
                    POMPES À PISTON HAUTE PRESSION
                    <br />
                  </h2>
                </div>
                <p className={styles.testimonials_content_p}>
                  Pompes capables d'acheminer des liquides et de la pulpe à des
                  pressions très élevées, jusqu'a 3500bar
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
