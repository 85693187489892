import React from "react";
import styles from "./vanne.module.css";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

const van_copape = require("../../assets/van-sopape.png");
const van_clapet = require("../../assets/van-clapet.png");
const van_pass = require("../../assets/van-pass.png");
const van = require("../../assets/vanvan.png");
const van_oper = require("../../assets/van-opper.png");
const van_sphere = require("../../assets/vanne-sphere.png");
const van_pist = require("../../assets/van_piston.png");
const van_moch = require("../../assets/van_moch.png");
const vanne = require("../../assets/vannes.gif");

export default function Vanne() {
  return (
    <div class="container-xxl py-6">
      <div class="container">
        <div
          class="mx-auto text-center wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <div style={{ marginTop: "2%" }}>
            <h1 className={styles.font_} style={{ fontSize: "50px" }}>
              Vanne
            </h1>
            <hr />
            <Breadcrumbs
              aria-label="breadcrumb"
              separator=">"
              sx={{
                "& ol": {
                  justifyContent: "center",
                  margin: "auto",
                },
              }}
            >
              <Link
                to="/equipement-process"
                style={{ textDecoration: "none", color: "#3367ec" }}
              >
                Produits
              </Link>

              <Link
                to="/equipement-process"
                style={{ textDecoration: "none", color: "#3367ec" }}
              >
                equipement process
              </Link>

              <Typography color="text.primary">Vannes</Typography>
            </Breadcrumbs>
          </div>
        </div>

        <div>
            <h2
              className={styles.font}
              style={{ textAlign: "center", marginTop: "3%" }}
            >
              Découvrez notre gamme complète de vannes, conçus pour répondre à tous vos besoins
            </h2>
        </div>

        <div class="row g-4 mt-5">
          <div>
            <p style={{ fontSize: "20px" }}>
              Nos vannes de haute qualité offrent une performance
              exceptionnelle, une fiabilité éprouvée et une durabilité
              supérieure. Faites confiance à notre expertise et laissez-nous
              vous fournir la solution idéale pour optimiser votre processus et
              assurer un fonctionnement fluide de vos équipements.
            </p>
          </div>
          <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div
              className={styles.team_item}
              style={{
                width: "50%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <h5 className={styles.team_item_h5}></h5>

              <div
                className="img-fluid rounded-circle w-100 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "60%", scale: "1" }}
                  src={vanne}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center" style={{}}>
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <ul className={styles.ul}>
              <div className="row">
                <div className="col-md-6">
                  <li className={styles.li}>Vannes à soupape</li>
                  <li className={styles.li}>Vannes à clapet</li>
                  <li className={styles.li}>Vannes à passage direct</li>
                  <li className={styles.li}>Vannes à papillon - VFS</li>
                  <li className={styles.li}>
                    Vannes papillon pour silos - VFF
                  </li>
                  <li className={styles.li}>Vannes à opercule</li>
                  <li className={styles.li}>Vannes à membrane</li>
                  <li className={styles.li}>
                    Vannes à guillotine - VG -VGR - VL - SINT®
                  </li>

                  <li className={styles.li}>
                    Vannes à boisseau sphérique - VSS - hémisphérique
                  </li>
                  <li className={styles.li}>Vannes à sphère</li>
                  <li className={styles.li}>Vannes à piston</li>
                </div>
                <div className="col-md-6">
                  <li className={styles.li}>
                    Vannes By-pass ou à volet - VAR - DVA
                  </li>
                  <li className={styles.li}>
                    Vannes alvéolaires à gravité - RVC
                  </li>
                  <li className={styles.li}>Vannes à manchon - VM</li>
                  <li className={styles.li}>
                    Vannes by-pass pour transport pneumatique - VAD
                  </li>
                  <li className={styles.li}>Vannes doseuses inclinées - VDI</li>
                  <li className={styles.li}>
                    Distributeurs alvéolaires à passage direct - RV - RVR
                  </li>
                  <li className={styles.li}>
                    Distributeurs alvéolaires à passage tangentiel - RVS
                  </li>
                  <li className={styles.li}>
                    Soupapes de contrôle de pression à ressorts - VCP
                  </li>
                  <li className={styles.li}>
                    Soupapes de contrôle de pression à membrane - VHS
                  </li>
                  <li className={styles.li}>Manifold</li>
                  <li className={styles.li}>Sas à double clapets - VDC</li>
                </div>
              </div>
            </ul>
          </div>
          {/* <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item}>
              <h5 className={styles.team_item_h5}></h5>

              <div
                className="img-fluid rounded-circle w-120 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "100%", scale: "0.74" }}
                  src={van_clapet}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center">
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item}>
              <h5 className={styles.team_item_h5}></h5>
              <div
                className="img-fluid rounded-circle w-120 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "100%", scale: "0.7" }}
                  src={van_pass}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center">
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item}>
              <h5 className={styles.team_item_h5}></h5>

              <div
                className="img-fluid rounded-circle w-120 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "100%", scale: "0.85" }}
                  src={van}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center">
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item}>
              <h5 className={styles.team_item_h5}></h5>

              <div
                className="img-fluid rounded-circle w-120 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "100%", scale: "0.85" }}
                  src={van_oper}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center">
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item}>
              <h5 className={styles.team_item_h5}></h5>

              <div
                className="img-fluid rounded-circle w-120 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "100%", scale: "0.85" }}
                  src={van_sphere}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center">
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item}>
              <h5 className={styles.team_item_h5}></h5>

              <div
                className="img-fluid rounded-circle w-120 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "100%", scale: "0.85" }}
                  src={van_pist}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center">
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item}>
              <h5 className={styles.team_item_h5}></h5>

              <div
                className="img-fluid rounded-circle w-120 mb-4"
                style={{ padding: "10px", border: "1px solid #abd473" }}
              >
                <img
                  className="img-fluid"
                  style={{ width: "100%", scale: "0.85" }}
                  src={van_moch}
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-center">
                <p className={styles.team_item_h5}></p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div style={{ marginTop: "3%" }}>
        <div>
          <p style={{ fontSize: "20px" }}>
            Vannes et soupapes pour tous les fluides ou solides : neutre,
            corrosifs, chargée ou abrasifs:
          </p>
        </div>
        <ul className={styles.ul}>
          <div className="row">
            <div className="col-md-6">
              <li className={styles.li}>Vannes à soupape</li>
              <li className={styles.li}>Vannes à clapet</li>
              <li className={styles.li}>Vannes à passage direct</li>
              <li className={styles.li}>Vannes à papillon - VFS</li>
              <li className={styles.li}>Vannes papillon pour silos - VFF</li>
              <li className={styles.li}>Vannes à opercule</li>
              <li className={styles.li}>Vannes à membrane</li>
              <li className={styles.li}>
                Vannes à guillotine - VG -VGR - VL - SINT®
              </li>

              <li className={styles.li}>
                Vannes à boisseau sphérique - VSS - hémisphérique
              </li>
              <li className={styles.li}>Vannes à sphère</li>
              <li className={styles.li}>Vannes à piston</li>
            </div>
            <div className="col-md-6">
              <li className={styles.li}>
                Vannes By-pass ou à volet - VAR - DVA
              </li>
              <li className={styles.li}>Vannes alvéolaires à gravité - RVC</li>
              <li className={styles.li}>Vannes à manchon - VM</li>
              <li className={styles.li}>
                Vannes by-pass pour transport pneumatique - VAD
              </li>
              <li className={styles.li}>Vannes doseuses inclinées - VDI</li>
              <li className={styles.li}>
                Distributeurs alvéolaires à passage direct - RV - RVR
              </li>
              <li className={styles.li}>
                Distributeurs alvéolaires à passage tangentiel - RVS
              </li>
              <li className={styles.li}>
                Soupapes de contrôle de pression à ressorts - VCP
              </li>
              <li className={styles.li}>
                Soupapes de contrôle de pression à membrane - VHS
              </li>
              <li className={styles.li}>Manifold</li>
              <li className={styles.li}>Sas à double clapets - VDC</li>
            </div>
          </div>
        </ul>
      </div> */}
    </div>
  );
}
