import { Breadcrumbs, Typography } from "@mui/material";
import styles from "./prelev.module.css";
import { Link } from "react-router-dom";

const ph = require("../../assets/liquide.png");
const app = require("../../assets/filtration.png");
const app_fl = require("../../assets/banc.png");
const ftir = require("../../assets/compresseur.png");
const pl = require("../../assets/reg-temp.png");
const cl = require("../../assets/souff.png");
const ds = require("../../assets/age.png");
const vs = require("../../assets/chaleur.png");
const didac = require("../../assets/lab-didactiq.gif");

export default function Didac(params) {
  return (
    <>
      <div>
        <div class="container-xxl py-6">
          <div class="container">
            <div
              class="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <div style={{ marginTop: "2%" }}>
                <h1 className={styles.font_}>
                  Matériels de recherches et didactiques
                </h1>
                <hr />
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator=">"
                  sx={{
                    "& ol": {
                      justifyContent: "center",
                      margin: "auto",
                    },
                  }}
                >
                  <Link
                    to="/equipements-laboratoire"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    Produits
                  </Link>

                  <Link
                    to="/equipements-laboratoire"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    Equipement laboratoire
                  </Link>

                  <Typography color="text.primary">
                    Matériels de recherches et didactiques
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
            {/* <div class="row g-4 mt-5">
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "0.95" }}
                      src={ph}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "0.95" }}
                      src={app}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "0.95" }}
                      src={app_fl}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{
                      padding: "1px",
                      border: "1px solid #abd473",
                      marginTop: "25%",
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "62%", scale: "1.5" }}
                      src={ftir}
                      alt=""
                    />
                  </div>

                  <div
                    class="d-flex justify-content-center"
                    style={{ marginTop: "18%" }}
                  >
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>
            </div> */}

            <div>
              <h2
                className={styles.font}
                style={{ textAlign: "center", marginTop: "3%" }}
              >
                Découvrez notre gamme complète de vannes, conçus pour répondre à
                tous vos besoins
              </h2>
            </div>

            <div class="row g-4" style={{ marginTop: "3%" }}>
              <div>
                <p style={{ fontSize: "20px" }}>
                  Vannes et soupapes pour tous les fluides ou solides : neutre,
                  corrosifs, chargée ou abrasifs:
                </p>
              </div>
              <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div
                  className={styles.team_item}
                  style={{
                    width: "70%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "1" }}
                      src={didac}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <ul className={styles.ul}>
                  <div className="row">
                    <li className={styles.li}>Extraction liquide-liquide</li>
                    <li className={styles.li}>Unité de filtration à sable</li>
                    <li className={styles.li}>
                      Banc d’essai pour convection libre et forcée
                    </li>
                    <li className={styles.li}>Unité compresseur bi étage</li>

                    <li className={styles.li}>Régulation de température</li>
                    <li className={styles.li}>Souffleries subsoniques</li>
                    <li className={styles.li}>Régulation de pression</li>
                    <li className={styles.li}>Pompe à chaleur réversible</li>
                  </div>
                </ul>
              </div>
              {/* <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "0.95" }}
                      src={cl}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "0.95" }}
                      src={ds}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "0.95" }}
                      src={vs}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div style={{ marginTop: "3%" }}>
            <div>
              <p style={{ fontSize: "20px" }}>
                Vannes et soupapes pour tous les fluides ou solides : neutre,
                corrosifs, chargée ou abrasifs:
              </p>
            </div>
            <ul className={styles.ul}>
              <div className="row">
                <div className="col-md-6">
                  <li className={styles.li}>Extraction liquide-liquide</li>
                  <li className={styles.li}>Unité de filtration à sable</li>
                  <li className={styles.li}>
                    Banc d’essai pour convection libre et forcée
                  </li>
                  <li className={styles.li}>Unité compresseur bi étage</li>
                </div>
                <div className="col-md-6">
                  <li className={styles.li}>Régulation de température</li>
                  <li className={styles.li}>Souffleries subsoniques</li>
                  <li className={styles.li}>Régulation de pression</li>
                  <li className={styles.li}>Pompe à chaleur réversible</li>
                </div>
              </div>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
}
