import React from "react";
import styles from "./parag.module.css";

const img = require("../../assets/jorf.png")

export default function Parag(params) {
   return(
    <div>
      
    </div>
   )
}
