import { Breadcrumbs, Typography } from "@mui/material";
import styles from "./prelev.module.css";
import { Link } from "react-router-dom";

const pic = require("../../assets/echant.png");
const atclv = require("../../assets/autoclave.png");
const bry = require("../../assets/broyeur.png");
const recep = require("../../assets/recip.png");
const bain = require("../../assets/bain.png");
const prelev = require("../../assets/prevel.gif");

export default function Prelev(params) {
  return (
    <>
      <div>
        <div class="container-xxl py-6">
          <div class="container">
            <div
              class="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <div style={{ marginTop: "2%" }}>
                <h1 className={styles.font_}>
                  Matériels de prélèvement et de préparation des échantillons
                </h1>
                <hr />
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator=">"
                  sx={{
                    "& ol": {
                      justifyContent: "center",
                      margin: "auto",
                    },
                  }}
                >
                  <Link
                    to="/equipements-laboratoire"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    Produits
                  </Link>

                  <Link
                    to="/equipements-laboratoire"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    Equipement laboratoire
                  </Link>

                  <Typography color="text.primary">
                    Matériels de mesure, analyse et dosage
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>

            <div>
              <h2
                className={styles.font}
                style={{ textAlign: "center", marginTop: "3%" }}
              >
                Découvrez notre gamme complète de vannes, conçus pour répondre à
                tous vos besoins
              </h2>
            </div>

            <div class="row g-4 mt-5">
              <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div
                  className={styles.team_item}
                  style={{
                    width: "70%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "60%", scale: "0.95" }}
                      src={prelev}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <ul className={styles.ul}>
                  <div className="row">
                    <li className={styles.li}>
                      Echantillonneurs manuel ou automatiques pour fluides et
                      solides
                    </li>
                    <li className={styles.li}>
                      Récipients de stockage métalliques
                    </li>
                    <li className={styles.li}>Broyeur</li>
                    <li className={styles.li}>Centrifugeuse</li>
                    <li className={styles.li}>Autoclave</li>

                    <li className={styles.li}>Bain marie</li>
                    <li className={styles.li}>Réacteurs de digestions</li>
                    <li className={styles.li}>Diviseurs</li>
                    <li className={styles.li}>
                      Agitateurs magnétiques ou à hélice
                    </li>
                    <li className={styles.li}>Filtres</li>
                  </div>
                </ul>
              </div>
              {/* <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "0.95" }}
                      src={recep}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "0.95" }}
                      src={bry}
                      alt=""
                    />
                  </div>

                  <div
                    class="d-flex justify-content-center"
                    style={{ marginTop: "18%" }}
                  >
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{ padding: "1px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "62%", scale: "1.5" }}
                      src={atclv}
                      alt=""
                    />
                  </div>

                  <div
                    class="d-flex justify-content-center"
                    style={{ marginTop: "18%" }}
                  >
                    <p className={styles.team_item_h5}></p>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div class="row g-4" style={{marginTop:"3%"}}>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}> </h5>
                            
                            <div className="img-fluid rounded-circle w-100 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"80%",scale:"0.95"}} src={bain} alt="" />
                            </div>
                            
                            <div class="d-flex justify-content-center">
                                <p className={styles.team_item_h5}></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}></h5>
                            
                            <div className="img-fluid rounded-circle w-100 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"80%",scale:"0.95"}} src={pic} alt="" />
                            </div>
                            
                            <div class="d-flex justify-content-center">
                                <p className={styles.team_item_h5}></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}></h5>
                            
                            <div className="img-fluid rounded-circle w-100 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"80%",scale:"0.95"}} src={pic} alt="" />
                            </div>
                            
                            <div class="d-flex justify-content-center">
                                <p className={styles.team_item_h5}></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className={styles.team_item} >
                            <h5 className={styles.team_item_h5}></h5>
                            
                            <div className="img-fluid rounded-circle w-100 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                                <img className="img-fluid" style={{width:"80%",scale:"0.95"}} src={pic} alt="" />
                            </div>
                            
                            <div class="d-flex justify-content-center">
                                <p className={styles.team_item_h5}></p>
                            </div>
                        </div>
                    </div>
                </div> */}
          </div>
          {/* <div style={{ marginTop: "3%" }}>
            <div>
              <p style={{ fontSize: "20px" }}>
                Vannes et soupapes pour tous les fluides ou solides : neutre,
                corrosifs, chargée ou abrasifs:
              </p>
            </div>
            <ul className={styles.ul}>
              <div className="row">
                <div className="col-md-6">
                  <li className={styles.li}>
                    Echantillonneurs manuel ou automatiques pour fluides et
                    solides
                  </li>
                  <li className={styles.li}>
                    Récipients de stockage métalliques
                  </li>
                  <li className={styles.li}>Broyeur</li>
                  <li className={styles.li}>Centrifugeuse</li>
                  <li className={styles.li}>Autoclave</li>
                </div>
                <div className="col-md-6">
                  <li className={styles.li}>Bain marie</li>
                  <li className={styles.li}>Réacteurs de digestions</li>
                  <li className={styles.li}>Diviseurs</li>
                  <li className={styles.li}>
                    Agitateurs magnétiques ou à hélice
                  </li>
                  <li className={styles.li}>Filtres</li>
                </div>
              </div>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
}
