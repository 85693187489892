import { Link } from "react-router-dom";
import styles from "./page.module.css";


const card1 = require("../../assets/material.gif")
const card2 = require("../../assets/analyseur.gif")
const card3 = require("../../assets/lab-didactiq.gif")

export default function Category2(params) {
    return(
        <div style={{marginTop:"3%",width:"100%",height:"100%"}}>
            <h1 className={styles.font_} style={{textAlign:"center"}}>LES ÉQUIPEMENTS DE LABORATOIRE</h1>
            <h4 className={styles.second_font} style={{textAlign:"center"}}>La puissance derrière votre process</h4>
            <hr style={{marginLeft:"auto",marginRight:"auto",display:"block",width:"50%"}}/>
        <div class="container" style={{marginTop:"5%"}}>
            <div class="row">
              <div class="col-md-4">
                <div className={styles.card}>
                <div class="card" style={{backgroundColor:"#ABD473"}}>
                  <img src={card1} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      Matériels de prélèvement et de préparation des échantillons
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Link to="/materiels-de-prelevement" class="btn btn-primary">
                        EN SAVOIRE PLUS
                      </Link>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card" style={{ backgroundColor: "#ABD473" }}>
                  <img src={card2 } style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body" >
                    <h5 class="card-title" style={{textAlign:"center",color:"white",marginTop:"4%"}}>
                    Matériels de mesure, analyse et dosage
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center",marginTop:"4%" }}> 
                    <Link to="/mesure" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 ">
                <div className="card"  style={{backgroundColor: "#ABD473"}}>
                  <img src={card3} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body" >
                    
                    <h5 class="card-title" style={{ textAlign: "center",color:"white",marginTop:"4%"}}>
                    Matériels de recherches et didactiques
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center",marginTop:"4%" }}> 
                    <Link to="/didactique" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
    )
}