import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";

const logo = require("../../assets/appro-logo.png");
const fb = require("../../assets/facebook.png");
const lk = require("../../assets/linkden.png");
const yt = require("../../assets/yt.png");

export default function Footer(params) {
  return (
    <footer style={{ marginTop: "2%" ,backgroundColor:"#213556"}}>
      <div class="container" >
        <div class="row">
          <div class="col-sm">
            <div>
              <img src={logo} style={{ width: "70%", height: "70%" }} />
              <p class="mb-30 footer-desc">
                L'Approvisionneur Technique est une société d'achat qui fournit
                des services d'approvisionnement technique. L'entreprise se
                spécialise dans la recherche et l'achat de produits, de
                matériaux et d'équipements nécessaires à divers projets
                techniques et industriels. Elle travaille avec ses clients pour
                comprendre leurs besoins et exigences spécifiques, puis utilise
                son expertise et son réseau de fournisseurs pour trouver les
                meilleurs produits aux meilleurs prix.
              </p>
            </div>
          </div>
          <div class="col-sm" style={{ marginTop: "10%" }}>
            <div>
              <h4>Quick Link</h4>
              <ul class="list-unstyled" style={{ lineHeight: "50px" }}>
                <li>
                  <Link to="/" class="text-decoration-none">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" class="text-decoration-none">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/services" class="text-decoration-none">
                    Service
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" class="text-decoration-none">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-sm" style={{ marginTop: "3%" }}>
            <div>
              <h4>Newsletter</h4>
              <div>
                <label for="Newsletter" class="form-label">
                  Subscribe To Our Newsletter
                </label>
                <input
                  type="text"
                  class="form-control"
                  Placeholder="Entree votre email"
                />
                <button class="btn btn-danger mt-3">Subscribe</button>
              </div>
              <div style={{ marginTop: "20%" }}>
                <p>
                  L’APPROVISIONNEUR TECHNIQUE Sarl
                  <br />
                  744, Zone Industrielle SAPINO, Nouaceur 20240, Casablanca
                  MAROC
                  <br />
                  Email : info@approvisionneur.com <br />
                  Tel : +212 522 440 884 / +212 522 307 746 <br /> Fax : +212
                  522 446 942
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.info} data-aos="fade-up">
          
          <div className={styles.sociallogos}>
            <div class={styles.logobox}>
              <a className={styles.logobox_a} href="#" class="fa fa-instagram">
                <img src={fb} />
              </a>
              <a className={styles.logobox_a} href="https://ma.linkedin.com/company/l-approvisionneur-technique-international?original_referer=https%3A%2F%2Fwww.google.com%2F" class="fa fa-linkedin">
                <img src={lk} />
              </a>
              <a className={styles.logobox_a} href="https://www.facebook.com/approvisonneur.technique.international.sa/" class="fa fa-facebook">
                <img src={yt} />
              </a>
            </div>
          </div>
          
        </div>
        <div class="d-flex justify-content-center" style={{ marginTop: "3%" }}>
          <div class="copyright">
            {/* <div
              className="row"
              style={{
                width: "50%",
                display: "block",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <div className="col">
                <a href=""></a>
              </div>
              <div className="col">
                <a href=""></a>
              </div>
              <div className="col">
                <a href=""></a>
              </div>
            </div> */}
            <p>
              @Copyright 2023 <a>L’APPROVISIONNEUR TECHNIQUE SA </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );

  return (
    <>
      <div class="main_footer">
        <div class="logoinfo" data-aos="fade-up">
          <h2 className={styles.main_footer_h2}>Responsive Footer</h2>
          <p>By Nikhil</p>

          <div className={styles.contact_details}>
            <h1 className={styles.main_footer_h1}>Contact Us</h1>
            <li className={styles.contact_details_li}>
              <div class="fa fa-phone"></div>
              <a
                className={styles.contact_details_li_a}
                href="tel:+919326048690"
              >
                +91 0987654321
              </a>
            </li>
            <li className={styles.contact_details_li}>
              <div class="fa fa-envelope"></div>
              <a
                className={styles.contact_details_li_a}
                href="mailto:yourmail@gmail.com"
              >
                yourmail@gmail.com
              </a>
            </li>
          </div>
        </div>
        <div className={styles.com} data-aos="fade-up">
          <h1 className={styles.main_footer_h1}>About</h1>
          <ul className={styles.main_footer_ul}>
            <li>
              <a className={styles.main_footer_ul_li_a} href="#">
                Home
              </a>
            </li>
            <li>
              <a className={styles.main_footer_ul_li_a} href="#">
                About
              </a>
            </li>
            <li>
              <a className={styles.main_footer_ul_li_a} href="#">
                Projects
              </a>
            </li>
            <li>
              <a className={styles.main_footer_ul_li_a} href="#">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.info} data-aos="fade-up">
          <h1>Social Media</h1>
          <div className={styles.sociallogos}>
            <div class={styles.logobox}>
              <a
                className={styles.logobox_a}
                href="#"
                class="fa fa-instagram"
              ></a>
              <a
                className={styles.logobox_a}
                href="#"
                class="fa fa-linkedin"
              ></a>
              <a
                className={styles.logobox_a}
                href="#"
                class="fa fa-facebook"
              ></a>
              <a
                className={styles.logobox_a}
                href="#"
                class="fa fa-youtube-play"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
