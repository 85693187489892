import { Link } from "react-router-dom";
import styles from "./echangeur.module.css";
import { Breadcrumbs, Typography } from "@mui/material";

const ech1 = require("../../assets/ech-plaque.png");
const ech2 = require("../../assets/ech2.png");
const ech3 = require("../../assets/ech-pla-join.png");
const ech4 = require("../../assets/ech4.png");
const ech5 = require("../../assets/ech5.png");
const ech6 = require("../../assets/ech6.png");
const echangeur = require("../../assets/echangeur.gif")

export default function Echangeur(params) {
  return (
    <>
      <div>
        <div class="container-xxl py-6">
          <div class="container">
            <div
              class="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <div style={{ marginTop: "2%" }}>
                <h1 className={styles.font_}>Echangeur</h1>
                <hr />
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator=">"
                  sx={{
                    "& ol": {
                      justifyContent: "center",
                      margin: "auto",
                    },
                  }}
                >
                  <Link
                    to="/equipement-process"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    Produits
                  </Link>

                  <Link
                    to="/equipement-process"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    equipement process
                  </Link>

                  <Typography color="text.primary">Echangeur</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div>
              <h2
                className={styles.font}
                style={{ textAlign: "center", marginTop: "3%" }}
              >
                L’approvisionneur technique vous offre une gamme complète
                d'échangeurs adaptés à vos besoins spécifiques.
              </h2>
            </div>
             
            


            <div class="row g-4 mt-5">
            <div>
              <p style={{ fontSize: "20px" }}>
                Nos échangeurs sont conçus pour offrir une performance optimale,
                une fiabilité exceptionnelle et une efficacité énergétique
                remarquable. Faites confiance à notre expertise et à notre
                engagement envers la qualité pour trouver l'échangeur parfait
                pour votre projet.
              </p>
            </div>
            <div class="col-md-6"  data-wow-delay="0.3s">
                    <div className={styles.team_item} style={{width:"50%",display:"block",marginLeft:"auto",marginRight:"auto"}}>
                        <h5 className={styles.team_item_h5}></h5>
                        
                        <div className="img-fluid rounded-circle w-100 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                            <img className="img-fluid" style={{width:"60%",scale:"1"}} src={echangeur} alt="" />
                        </div>
                        <div class="d-flex justify-content-center" >
                            <p  className={styles.team_item_h5} ></p>
                        </div>
                    </div>
            </div>   
            <div className="col-md-6">
            <ul className={styles.ul}>
              <div className="row">
                
                  <li className={styles.li}>
                    ECHANGEUR À PLAQUES SOUDÉES PLATULAIRE
                  </li>
                  <li className={styles.li}>ECHANGEUR PLATULAIRE PCV</li>
                  <li className={styles.li}>ECHANGEUR À PLAQUES ET JOINTS</li>
                
                
                  <li className={styles.li}>ECHANGEUR À PLAQUES BRASÉES</li>
                  <li className={styles.li}>ECHANGEUR TUBULAIRE</li>
                  <li className={styles.li}>ECHANGEUR TUBULAIRE SPIRALÉ</li>
                
              </div>
            </ul>
            </div>                                                               
                {/* <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item} >
                        <h5 className={styles.team_item_h5}></h5>
                        
                        <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                            <img className="img-fluid" style={{width:"80%",scale:"0.9",marginLeft:"15%"}} src={ech2} alt="" />
                        </div>
                        <div class="d-flex justify-content-center">
                        <p  className={styles.team_item_h5} ></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item} >
                        <h5 className={styles.team_item_h5}></h5>
                        <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473",}}>
                            <img className="img-fluid" style={{width:"72%",scale:"1.2",marginLeft:"22%"}} src={ech3} alt="" />
                        </div>
                        <div class="d-flex justify-content-center">
                            <p  className={styles.team_item_h5} ></p>
                        </div>
                    </div>
                </div> */}
                
            </div> 
            {/* <div class="row g-4" style={{marginTop:"3%"}}>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className={styles.team_item} >
                        <h5 className={styles.team_item_h5}></h5>
                        <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                            <img className="img-fluid" style={{width:"50%",scale:"1.1"}} src={ech4} alt="" />
                        </div>
                        <div class="d-flex justify-content-center">
                        <p  className={styles.team_item_h5} ></p>
                        </div>
            </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item} >
                        <h5 className={styles.team_item_h5}></h5>
                        <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                            <img className="img-fluid" style={{width:"100%",scale:"0.85"}} src={ech5} alt="" />
                        </div>
                        <div class="d-flex justify-content-center">
                            <p  className={styles.team_item_h5} ></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item} >
                        <h5 className={styles.team_item_h5}></h5>
                        
                        <div className="img-fluid rounded-circle w-120 mb-4" style={{padding: "10px",border: "1px solid #abd473"}}>
                            <img className="img-fluid" style={{width:"20%",scale:"1.1"}} src={ech6} alt=""/>
                        </div>
                        <div class="d-flex justify-content-center">
                            <p className={styles.team_item_h5}></p>
                        </div>
                    </div>
                </div>
                
            </div> */}
          </div>
          {/* <div style={{ marginTop: "3%" }}>
            <div>
              <p style={{ fontSize: "20px" }}>
                Nos échangeurs sont conçus pour offrir une performance optimale,
                une fiabilité exceptionnelle et une efficacité énergétique
                remarquable. Faites confiance à notre expertise et à notre
                engagement envers la qualité pour trouver l'échangeur parfait
                pour votre projet.
              </p>
            </div>
            <ul className={styles.ul}>
              <div className="row">
                <div className="col-md-6">
                  <li className={styles.li}>
                    ECHANGEUR À PLAQUES SOUDÉES PLATULAIRE
                  </li>
                  <li className={styles.li}>ECHANGEUR PLATULAIRE PCV</li>
                  <li className={styles.li}>ECHANGEUR À PLAQUES ET JOINTS</li>
                </div>
                <div className="col-md-6">
                  <li className={styles.li}>ECHANGEUR À PLAQUES BRASÉES</li>
                  <li className={styles.li}>ECHANGEUR TUBULAIRE</li>
                  <li className={styles.li}>ECHANGEUR TUBULAIRE SPIRALÉ</li>
                </div>
              </div>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
}
