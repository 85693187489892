import React from "react";
import { Link } from "react-router-dom";
import styles from "./acceuil.module.css";

const img3 = require("../../assets/1.png");
const img4 = require("../../assets/3.png");
const client = require("../../assets/cl.png");
const appro = require("../../assets/appro.jpg");
const card2 = require("../../assets/bg-labo.png");
const card1 = require("../../assets/maxresdefault.jpg");
const card3 = require("../../assets/bg-elec.png");
const card4 = require("../../assets/bg-port.png");
const card5 = require("../../assets/bg-epi.png");
const pg = require("../../assets/7712574.png");
const solution = require("../../assets/solution--.png");
const png1 = require("../../assets/electro-process.gif");
const png2 = require("../../assets/INSTRUMENTATION.gif");
const png3 = require("../../assets/PORTABLE.gif");
const png4 = require("../../assets/SAFETY1.gif");
const png5 = require("../../assets/chimique.gif");
const png6 = require("../../assets/LABORATOIRE.gif");
const acc = require("../../assets/moteurs.jpg");
const moteur = require("../../assets/cover.png");

export default function Acceuil(params) {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div class="container-fluid px-0 mb-5">
        <div
          id="header-carousel"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="w-100" src={img4} alt="Image" />
              <div class="container">
                <div
                  class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
                  style={{ marginLeft: "5%" }}
                >
                  <div class="container">
                    <div class="row justify-content-start">
                      <div class="col-10 col-lg-8">
                        <h5 class="text-white text-uppercase mb-2 animated slideInDown"></h5>
                        <h1 class="display-3 text-white animated slideInDown mb-4">
                          Fourniture industrielle
                          {/* <span class="text-primary">Logistics</span> Solution */}
                        </h1>
                        <p class="fs-5 fw-medium text-white mb-4 pb-2">
                          Désormais, vos urgences
                        </p>
                        <Link
                          to="/about"
                          class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                          style={{ marginTop: "-2%" }}
                        >
                          EN SAVOIRE PLUS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="w-100" src={moteur} alt="Image" />
              <div class="container">
                <div
                  class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
                  style={{ marginLeft: "5%" }}
                >
                  <div class="container">
                    <div class="row justify-content-start">
                      <div class="col-10 col-lg-8">
                        <h5 class="text-white text-uppercase mb-2 animated slideInDown"></h5>
                        <h1 class="display-3 text-white animated slideInDown mb-4">
                          Nos moteurs électrique
                          {/* <span class="text-primary">Logistics</span> Solution */}
                        </h1>
                        <p class="fs-5 fw-medium text-white mb-4 pb-2">
                          ISO (paragraph...)
                        </p>
                        <Link
                          to="/about"
                          class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                          style={{ marginTop: "-2%" }}
                        >
                          EN SAVOIRE PLUS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        <div></div>
      </div>

      {/*  <div class="container-fluid p-0 pb-5">
        <div class="owl-carousel header-carousel position-relative mb-5">
            <div class="owl-carousel-item position-relative">
                <img class="img-fluid" src={img3} alt="" />
                <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{background: "rgba(6, 3, 21, .5)"}}>
                    <div class="container">
                        <div class="row justify-content-start">
                            <div class="col-10 col-lg-8">
                                <h5 class="text-white text-uppercase mb-3 animated slideInDown">Transport & Logistics Solution</h5>
                                <h1 class="display-3 text-white animated slideInDown mb-4">#1 Place For Your <span class="text-primary">Logistics</span> Solution</h1>
                                <p class="fs-5 fw-medium text-white mb-4 pb-2">Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no. Kasd rebum ipsum et diam justo clita et kasd rebum sea elitr.</p>
                                <a href="" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Read More</a>
                                <a href="" class="btn btn-secondary py-md-3 px-md-5 animated slideInRight">Free Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="owl-carousel-item position-relative">
                <img class="img-fluid" src={img4} alt="" />
                <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{background: "rgba(6, 3, 21, .5)"}}>
                    <div class="container">
                        <div class="row justify-content-start">
                            <div class="col-10 col-lg-8">
                                <h5 class="text-white text-uppercase mb-3 animated slideInDown">Transport & Logistics Solution</h5>
                                <h1 class="display-3 text-white animated slideInDown mb-4">#1 Place For Your <span class="text-primary">Transport</span> Solution</h1>
                                <p class="fs-5 fw-medium text-white mb-4 pb-2">Vero elitr justo clita lorem. Ipsum dolor at sed stet sit diam no. Kasd rebum ipsum et diam justo clita et kasd rebum sea elitr.</p>
                                <a href="" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Read More</a>
                                <a href="" class="btn btn-secondary py-md-3 px-md-5 animated slideInRight">Free Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div> */}

      <div className={styles.divise}>
        <div style={{ marginTop: "3%", padding: "2%" }}>
          <div className="container">
            <div class="row">
              <div class="col-md-6 how-img">
                <h4 className={styles.font}>
                  <b>Présentation</b>
                </h4>
                <hr />
                <h3 className={styles.second_font} style={{ padding: "2%" }}>
                  Des achats avisés grâce à 17 ans d'expérience
                </h3>
                <p
                  style={{
                    textAlign: "left",
                    borderRadius: "4px",
                    fontFamily: "Open Sans",
                    padding: "2%",
                    lineHeight: "40px",
                  }}
                >
                  Fondée en 2006,<b>ATIS</b> est une entreprise spécialisée dans la
                  fourniture d'équipements et de pièces de rechange pour
                  l'industrie. Depuis notre création, notre objectif principal
                  est de fournir des produits et des services de haute qualité à
                  nos clients, en mettant l'accent sur leur satisfaction et la
                  qualité des produits.
                </p>
                <Link
                  className="btn btn-primary w-30 ml-4"
                  to="/about"
                  role="button"
                  style={{ marginLeft: "20%" }}
                >
                  EN SAVOIRE PLUS
                </Link>
              </div>
              <div className="col-md-6">
                <img src={appro} style={{ width: "100%" }} />
              </div>
              {/* <div class="col-md-6">
              <h2 style={{ padding: "2%" }}>NOS VALEURS</h2>
              <div id="accordion">
                <div class="card">
                  <div class="card-header">
                    <a
                      class="btn"
                      data-bs-toggle="collapse"
                      href="#collapseOne"
                    >
                      PRIX ATTRACTIFS
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    class="collapse show"
                    data-bs-parent="#accordion"
                  >
                    <div class="card-body">
                      Chez l’approvisionneur technique, nous comprenons
                      l'importance de la réduction maximale des coûts pour votre
                      entreprise. C'est pourquoi nous offrons un avantage
                      tarifaire unique à nos clients. Nos partenariats
                      stratégiques avec les principaux fournisseurs et notre
                      force d'achat nous permettent d'obtenir les meilleurs prix
                      pour les produits dont vous avez besoin.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a
                      class="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapseTwo"
                    >
                      QUALITÉ SUPÉRIEURE
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div class="card-body">
                      Chez l’approvisionneur technique, nous comprenons que la
                      qualité est un facteur essentiel dans toute décision
                      d'achat. C'est pourquoi nous nous efforçons de fournir à
                      nos clients des produits qui respectent et dépassent les
                      normes industrielles. Notre engagement en faveur de la
                      qualité se manifeste dans tous les aspects de notre
                      activité, de l'approvisionnement en matériaux aux
                      procédures rigoureuses d'inspection.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a
                      class="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapseThree"
                    >
                      DÉLAIS DE LIVRAISON OPTIMISÉS
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div class="card-body">
                      Chez l’approvisionneur technique, nous comprenons
                      l'importance d'une livraison rapide lorsqu'il s'agit de
                      vos besoins d'achat. C'est pourquoi nous sommes fiers
                      d'offrir l'un des services de livraison les plus rapides
                      et les plus fiables du secteur. Grâce à notre vaste réseau
                      logistique et à nos systèmes de transport de pointe, nous
                      pouvons vous garantir que vos commandes vous parviendront
                      dans les délais les plus courts possibles.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a
                      class="collapsed btn"
                      data-bs-toggle="collapse"
                      href="#collapseFore"
                    >
                      SAV RÉACTIF ET PERFORMANT
                    </a>
                  </div>
                  <div
                    id="collapseFore"
                    class="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div class="card-body">
                      Chez l’approvisionneur technique, le service client est au
                      cœur de toutes nos activités. Nous comprenons l'importance
                      d'offrir une expérience client positive, et c'est pourquoi
                      nous en avons fait une priorité absolue. Notre équipe de
                      professionnels expérimentés est disponible pour répondre à
                      toutes vos questions et résoudre toutes les situations qui
                      peuvent survenir avec vos achats.
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.divise} style={{ backgroundColor: "#ABD473" }}>
        <div style={{ padding: "5%" }}>
          <h2 className={styles.font_}>
            <b>Nos produits</b>
          </h2>
          <p style={{ fontSize: "23px" }}>
            Nous proposons des produits fiables, sûrs, et approuvés
          </p>
          <div class="container" style={{ marginTop: "5%" }}>
            <div class="row">
              <div class="col-md-4">
                <div class="card" style={{ backgroundColor: "#2B4A99" }}>
                  <img src={png1} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      Les équipements process
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Link
                        to="/equipement-process"
                        class="btn btn-primary"
                        style={{ marginTop: "7%" }}
                      >
                        EN SAVOIRE PLUS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card" style={{ backgroundColor: "#2B4A99" }}>
                  <img src={png6} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      Les équipements de laboratoire
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Link
                        to="/equipements-laboratoire"
                        class="btn btn-primary"
                        style={{ marginTop: "7%" }}
                      >
                        EN SAVOIRE PLUS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card" style={{ backgroundColor: "#2B4A99" }}>
                  <img src={png2} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      Les équipements électriques, d'instrumentations et
                      d'automatisme
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Link
                        to="/equipement-instrumenstation"
                        class="btn btn-primary"
                      >
                        EN SAVOIRE PLUS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div class="row" style={{ marginTop: "2%" }}>
              <div class="col-md-4">
                <div class="card" style={{ backgroundColor: "#2B4A99" }}>
                  <img src={png3} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      L’appareillage portatif et outillage de mesure et
                      d'analyse
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Link to="/cat4" class="btn btn-primary">
                        EN SAVOIRE PLUS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card" style={{ backgroundColor: "#2B4A99" }}>
                  <img src={png5} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      Les produits chimiques et consommables divers
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Link to="/chimique-diver" class="btn btn-primary">
                        EN SAVOIRE PLUS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="card" style={{ backgroundColor: "#2B4A99" }}>
                  <img src={png4} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      Equipements de protection individuelle (EPI) ET Panneaux
                      de signalisation
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Link to="/epi" class="btn btn-primary">
                        EN SAVOIRE PLUS
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.divise}>
        <h1 className={styles.font_} style={{ textAlign: "center" }}>
          <b>Nos solutions et services</b>
        </h1>
        <div className="container">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-6 how-img">
              <img src={solution} style={{ width: "80%", padding: "5%" }} />
            </div>
            <div className="col-md-6">
              <p className={styles.font_p} style={{ marginTop: "10%" }}>
                En plus des simples demandes de prix requis habituellement de
                nos clients, demandes par lesquelles le client souhaite avoir
                une substitution à l’identique des équipements ou pièces de
                rechange existant. Atis accompagne régulièrement ses clients par
                la définition du besoin et mise en place du solution technique
                afin d’assurer les performances et attentes notre périmètre
                d’intervention est :<br />
                <ul>
                  <li>
                    <b>l’etudes et conception des solutions techniques.</b>
                  </li>
                  <li>
                    <b>
                      sourcing et achat des unités, équipements et pièces de
                      rechange.
                    </b>
                  </li>
                  <li>
                    <b>transport et acheminement sur site.</b>
                  </li>
                  <li>
                    <b>mise en marche sur site.</b>
                  </li>
                  <li>
                    <b>formation à l’utilisation et maintenance.</b>
                  </li>
                </ul>
                En résumé, nous sommes là pour vous aider à trouver les
                solutions les plus adaptées à vos besoins techniques, en vous
                fournissant des conseils, des services et des équipements de
                qualité.
                <br />
                En faisant appel à notre société, vous pouvez économiser du
                temps et de l'argent, réduire les risques associés à
                l'approvisionnement et améliorer la qualité des biens et
                services que vous recevez. Contactez-nous dès aujourd'hui pour
                en savoir plus sur la façon dont nous pouvons aider votre
                entreprise !
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.divise} style={{ backgroundColor: "#2B4A99" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div style={{ color: "white", marginTop: "10%", padding: "5%" }}>
                <h1 className={styles.font_com}>
                  Comment pouvons-nous vous aidez ?
                </h1>
                <p
                  style={{ fontSize: "20px", marginTop: "5%" }}
                  className={styles.font1}
                >
                  Nous sommes là pour vous aider et nous nous engageons à
                  fournir un service de qualité supérieure à tous nos clients.
                  N'hésitez pas à nous contacter si vous avez besoin
                  d'assistance ou de conseils sur les produits que nous offrons
                </p>
              </div>
            </div>
            <div className="col-md-6 how-img">
              <img src={pg} style={{ width: "100%", padding: "5%" }} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.divise}>
        <h1
          style={{ color: "#2B4A99", textAlign: "center" }}
          className={styles.font}
        >
          <b>Nos engagements</b>
        </h1>
        <div className="container">
          <div className="row" style={{ padding: "5%", width: "100%" }}>
            <div className="col">
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#ABD473",
                }}
              >
                QUALITE
              </h2>
              <div
                className={styles.animated_quote}
                style={{ marginTop: "2%" }}
              >
                <blockquote className={styles.blockquote}>
                  <p style={{ fontSize: "16px" }}>
                    Nous comprenons que la qualité est un facteur essentiel dans
                    toute décision d'achat. C'est pourquoi nous nous efforçons
                    de fournir à nos clients des produits qui respectent et
                    dépassent les normes industrielles.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="col">
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#ABD473",
                }}
              >
                PRIX
              </h2>
              <div
                className={styles.animated_quote}
                style={{ marginTop: "2%" }}
              >
                <blockquote className={styles.blockquote}>
                  <p style={{ fontSize: "16px" }}>
                    Nous comprenons l'importance de la réduction maximale des
                    coûts pour votre entreprise. C'est pourquoi nous offrons un
                    avantage tarifaire unique à nos clients.
                  </p>
                </blockquote>
              </div>
            </div>
            <div className="col">
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#ABD473",
                }}
              >
                DELAI
              </h2>
              <div
                className={styles.animated_quote}
                style={{ marginTop: "2%" }}
              >
                <blockquote className={styles.blockquote}>
                  <p style={{ fontSize: "16px" }}>
                    Grâce à notre vaste réseau logistique et à nos systèmes de
                    transport de pointe, nous pouvons vous garantir que vos
                    commandes vous parviendront dans les délais les plus courts
                    possibles.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="col">
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#ABD473",
                }}
              >
                SAV
              </h2>
              <div
                className={styles.animated_quote}
                style={{ marginTop: "2%" }}
              >
                <blockquote className={styles.blockquote}>
                  <p style={{ fontSize: "16px" }}>
                    Le service client est au cœur de toutes nos activités. Notre
                    équipe de professionnels expérimentés est disponible pour
                    répondre à toutes vos questions et résoudre toutes les
                    situations qui peuvent survenir avec vos achats.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="col">
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#ABD473",
                }}
              >
                FLEXIBILITE
              </h2>
              <div
                className={styles.animated_quote}
                style={{ marginTop: "2%" }}
              >
                <blockquote className={styles.blockquote}>
                  <p style={{ fontSize: "16px" }}>
                    Nous comprenons que chaque client a des besoins spécifiques
                    en matière d'achat. C'est pourquoi nous offrons des
                    solutions flexibles qui peuvent être adaptées à vos besoins
                    uniques.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className={styles.features_area}>
        <div className="container">
          <div
            className="row"
            style={{
              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
              padding: "40px 0",
            }}
          >
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div className={styles.single_features}>
                <div class="f-icon">
                  <img src="img/features/f-icon1.png" alt="" />
                </div>
                <h6>84 millions</h6>
                <p>de dirhams de chiffre d’affaire</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div className={styles.single_features}>
                <div class="f-icon">
                  <img src="img/features/f-icon2.png" alt="" />
                </div>
                <h6>27 millions</h6>
                <p>de dirhams de capitale</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className={styles.single_features}>
                <div class="f-icon">
                  <img src="img/features/f-icon3.png" alt="" />
                </div>
                <h6>48</h6>
                <p>Ingénieurs et techniciens</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className={styles.single_features}>
                <div class="f-icon">
                  <img src="img/features/f-icon4.png" alt="" />
                </div>
                <h6>3100 m²</h6>
                <p>de surface couverte</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className={styles.divise} style={{ backgroundColor: "#ABD473" }}>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-6">
            <div style={{ marginTop: "8%", padding: "5%",display:"block",marginLeft:"auto",marginRight:"auto" }}>
              <h1 className={styles.font_}>Catalogue</h1>
              <h4 className={styles.font_p} style={{ marginTop: "4%" }}>
                Découvrez nos produits en détail pour faire le meilleur choix
              </h4>
              <button className={styles.btn} style={{ marginTop: "4%" }}>
                CONSULTER NOS CATALOGUES
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={ctg}
              style={{
                padding: "5%",
                width: "300px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
        </div>
      </div> */}

      {/* <div className={styles.divise} style={{ backgroundColor: "gray" }}>
        <section id="facts" className="facts-area dark-bg">
          <div className="container">
            <div className="facts-wrapper">
              <div className="row">
                <div className="col-md-3 col-sm-6 ts-facts">
                  <div className="ts-facts-img">
                    <img
                      loading="lazy"
                      src={trophy}
                      style={{
                        width: "30%",
                        height: "30%",
                        marginLeft: "35%",
                        marginTop: "10%",
                      }}
                      alt="facts-img"
                    />
                  </div>
                  <div className="ts-facts-content">
                    <h2
                      className="ts-facts-num"
                      style={{ textAlign: "center" }}
                    >
                      <span className="counterUp" data-count="68.4">
                        68.4
                      </span>
                    </h2>
                    <h3
                      className="ts-facts-title"
                      style={{ textAlign: "center" }}
                    >
                      million de dirhams de chiffre d’affaire
                    </h3>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-sm-0">
                  <div className="ts-facts-img">
                    <img
                      loading="lazy"
                      src={team}
                      style={{ width: "40%", height: "40%", marginLeft: "30%" }}
                      alt="facts-img"
                    />
                  </div>
                  <div className="ts-facts-content">
                    <h2
                      className="ts-facts-num"
                      style={{ textAlign: "center" }}
                    >
                      <span className="counterUp" data-count="27">
                        27
                      </span>
                    </h2>
                    <h3
                      className="ts-facts-title"
                      style={{ textAlign: "center" }}
                    >
                      Ingénieurs et techniciens
                    </h3>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0">
                  <div className="ts-facts-img">
                    <img
                      loading="lazy"
                      src={trans}
                      style={{ width: "40%", height: "40%", marginLeft: "30%" }}
                      alt="facts-img"
                    />
                  </div>
                  <div className="ts-facts-content">
                    <h2
                      className="ts-facts-num"
                      style={{ textAlign: "center" }}
                    >
                      <span className="counterUp" data-count="3400">
                        135{" "}
                      </span>
                    </h2>
                    <h3
                      className="ts-facts-title"
                      style={{ textAlign: "center" }}
                    >
                      clients au Maroc et à l’etranger
                    </h3>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0">
                  <div className="ts-facts-img">
                    <img
                      loading="lazy"
                      src={trans}
                      style={{ width: "40%", height: "40%", marginLeft: "30%" }}
                      alt="facts-img"
                    />
                  </div>
                  <div className="ts-facts-content">
                    <h2
                      className="ts-facts-num"
                      style={{ textAlign: "center" }}
                    >
                      <span className="counterUp" data-count="1400">
                        1400
                      </span>
                    </h2>
                    <h3
                      className="ts-facts-title"
                      style={{ textAlign: "center" }}
                    >
                      commandes livrées
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> */}

      {/* <div className={styles.divise}>
        <h3 style={{ textAlign: "center", marginTop: "5%", color: "#909090" }}>
          Nous sommes des spécialistes dans
        </h3>
        <h1 style={{ textAlign: "center" }}>ce que nous font :</h1>
        <div
          class="row"
          style={{ marginTop: "5%", padding: "2%", width: "100%" }}
        >
          <div class="col-md-6 how-img">
            <div class="ts-service-box d-flex">
              <div class="ts-service-box-img">
                <img loading="lazy" src={home} alt="service-icon" />
              </div>
              <div class="ts-service-box-info" style={{ marginLeft: "5%" }}>
                <h3 class="service-box-title">
                  <a>Logistique </a>
                </h3>
                <p>
                  Nos services logistiques offrent aux clients une solution
                  complète pour la livraison des biens et des services. Notre
                  équipe d'experts se chargera de tous les aspects du processus
                  de livraison, de la négociation des meilleurs tarifs
                  d'expédition au suivi de l'envoi jusqu'à sa destination
                  finale.
                </p>
              </div>
            </div>

            <div class="ts-service-box d-flex">
              <div class="ts-service-box-img">
                <img loading="lazy" src={home} alt="service-icon" />
              </div>
              <div class="ts-service-box-info" style={{ marginLeft: "5%" }}>
                <h3 class="service-box-title">
                  <a>Contrôle de la qualité</a>
                </h3>
                <p>
                  Nos services de contrôle de la qualité garantissent que les
                  clients ne reçoivent que des biens et services de la plus
                  haute qualité. Notre équipe d'experts effectuera des
                  inspections et des audits pour s'assurer que les fournisseurs
                  respectent les normes requises, et travaillera avec les
                  clients pour résoudre tout problème éventuel.
                </p>
              </div>
            </div>
            <div class="ts-service-box d-flex">
              <div class="ts-service-box-img">
                <img loading="lazy" src={home} alt="service-icon" />
              </div>
              <div class="ts-service-box-info" style={{ marginLeft: "5%" }}>
                <h3 class="service-box-title">
                  <a>Traitement des paiements</a>
                </h3>
                <p>
                  Nos services de traitement des paiements simplifient le
                  processus de facturation et de paiement pour les clients, en
                  veillant à ce que les factures soient payées rapidement et
                  avec précision. Notre équipe d'experts se charge de toutes les
                  tâches liées aux paiements, ce qui permet aux clients de se
                  concentrer sur d'autres aspects importants de leur activité.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="ts-service-box d-flex">
              <div class="ts-service-box-img">
                <img loading="lazy" src={home} alt="service-icon" />
              </div>
              <div class="ts-service-box-info" style={{ marginLeft: "5%" }}>
                <h3 class="service-box-title">
                  <a>Approvisionnement </a>
                </h3>
                <p>
                  {" "}
                  Nos services d'approvisionnement permettent aux clients
                  d'accéder à un large <br /> éventail de fournisseurs fiables.
                </p>
              </div>
            </div>
            <br />
            <br />
            <div class="ts-service-box d-flex">
              <div class="ts-service-box-img">
                <img loading="lazy" src={home} alt="service-icon" />
              </div>
              <div class="ts-service-box-info" style={{ marginLeft: "5%" }}>
                <h3 class="service-box-title">
                  <a>Gestion des contrats </a>
                </h3>
                <p>
                  {" "}
                  Nos services de gestion des contrats garantissent que tous les
                  contrats avec <br /> les fournisseurs sont équitables,
                  transparents et conformes aux normes du secteur.{" "}
                </p>
              </div>
            </div>
            <br />
            <br />
            <div class="ts-service-box d-flex">
              <div class="ts-service-box-img">
                <img loading="lazy" src={home} alt="service-icon" />
              </div>
              <div class="ts-service-box-info" style={{ marginLeft: "5%" }}>
                <h3 class="service-box-title">
                  <a>Optimisation de la chaîne d'approvisionnemen </a>
                </h3>
                <p>
                  Nos services d'optimisation de la chaîne d'approvisionnement
                  aident les clients à rationaliser leurs processus
                  d'approvisionnement, à réduire les coûts et à améliorer
                  l'efficacité.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className={styles.divise}>
        <h1
          className={styles.font_}
          style={{ textAlign: "center", marginTop: "5%" }}
        >
          <b>Nos clients</b>
        </h1>
        <img
          src={client}
          style={{
            width: "80%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </div>
      <div className={styles.divise}></div>
    </div>
  );
}
