import styles from './home.module.css';
import React from 'react';


const img1 =require("../../assets/carousel-1.jpg");
const img2 =require("../../assets/carousel-2.jpg");
const img3 =require("../../assets/pexels-thisisengineering-3862632.jpg");
const down = require("../../assets/down.png");
const trophy = require("../../assets/trophy.png");
const trans = require("../../assets/trans.png");




export default function Home(params) {



    return(

      <div >

        <section >
      <div class="container-fluid px-0 mb-5">
      <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
              <div class="carousel-item active">
                  <img class="w-100" src={img3} alt="Image" />
                  
              </div>
              <div class="carousel-item">
                  <img class="w-100" src={img3} alt="Image"/>
                  
              </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
          </button>
      </div>

      <div>
        
      </div>
   </div></section>
   
    <section>
   <div style={{marginTop:"5%",marginLeft:"10%"}}>
           <h1 style={{textAlign:"left",fontSize:"20px",fontFamily:"revert",marginLeft:"2%"}}>A PROPOS DE NOUS :</h1>
           <div style={{marginTop:"3%"}} className={styles.card} >
            <h1 style={{padding:"2%"}}>Title ....</h1>
           <p style={{textAlign:"left",borderRadius:"4px",fontFamily:"Open Sans",padding:"2%",lineHeight: "26px"}}>
            <b>L'Approvisionneur Technique</b> est une société d'achat qui<br/> fournit des services d'approvisionnement technique. 
            L'entreprise se spécialise dans la recherche<br/> et l'achat de produits, de matériaux et d'équipements nécessaires à
            divers projets techniques et industriels.<br/> Elle travaille avec ses clients pour comprendre
            leurs besoins et exigences spécifiques,
            puis utilise son expertise et son réseau<br/> de fournisseurs pour trouver les meilleurs<br/> produits aux meilleurs prix.
           </p>
           </div>
           <div style={{marginTop:"3%",marginLeft:"14%",width:"100%"}} className={styles.card} >
            <div >
               <h2 style={{padding:"2%"}}>NOS VALEURS</h2>
               <div id="accordion">
    <div class="card">
      <div class="card-header">
        <a  class="btn" data-bs-toggle="collapse" href="#collapseOne">
          PRIX ATTRACTIFS    
        </a>
      </div>
      <div id="collapseOne" class="collapse show" data-bs-parent="#accordion">
        <div class="card-body">
        Chez l’approvisionneur technique, nous comprenons l'importance de la réduction maximale des coûts pour votre entreprise. C'est pourquoi nous offrons un avantage tarifaire unique à nos clients. Nos partenariats stratégiques avec les principaux fournisseurs et notre force d'achat nous permettent d'obtenir les meilleurs prix pour les produits dont vous avez besoin.

        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <a class="collapsed btn" data-bs-toggle="collapse" href="#collapseTwo">
        QUALITÉ SUPÉRIEURE
      </a>
      </div>
      <div id="collapseTwo" class="collapse" data-bs-parent="#accordion">
        <div class="card-body">
        Chez l’approvisionneur technique, nous comprenons que la qualité est un facteur essentiel dans toute décision d'achat. C'est pourquoi nous nous efforçons de fournir à nos clients des produits qui respectent et dépassent les normes industrielles. Notre engagement en faveur de la qualité se manifeste dans tous les aspects de notre activité, de l'approvisionnement en matériaux aux procédures rigoureuses d'inspection.
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <a class="collapsed btn" data-bs-toggle="collapse" href="#collapseThree">
        DÉLAIS DE LIVRAISON OPTIMISÉS
        </a>
      </div>
      <div id="collapseThree" class="collapse" data-bs-parent="#accordion">
        <div class="card-body">
        Chez l’approvisionneur technique, nous comprenons l'importance d'une livraison rapide lorsqu'il s'agit de vos besoins d'achat. C'est pourquoi nous sommes fiers d'offrir l'un des services de livraison les plus rapides et les plus fiables du secteur.
Grâce à notre vaste réseau logistique et à nos systèmes de transport de pointe, nous pouvons vous garantir que vos commandes vous parviendront dans les délais les plus courts possibles.

        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <a class="collapsed btn" data-bs-toggle="collapse" href="#collapseFore">
        SAV RÉACTIF ET PERFORMANT
        </a>
      </div>
      <div id="collapseFore" class="collapse" data-bs-parent="#accordion">
        <div class="card-body">
        Chez l’approvisionneur technique, le service client est au cœur de toutes nos activités. Nous comprenons l'importance d'offrir une expérience client positive, et c'est pourquoi nous en avons fait une priorité absolue.
Notre équipe de professionnels expérimentés est disponible pour répondre à toutes vos questions et résoudre toutes les situations qui peuvent survenir avec vos achats.
        </div>
      </div>
    </div>
    </div>
            </div>
          </div>

      </div>
      
      
   </section> 
   
   {/* Nos chifres cles */ }

  
  








  {/* Footer 
   <div class="container">
  <footer class="py-5">
    <div class="row">
      <div class="col-6 col-md-2 mb-3">
        <h5>Section</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Home</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Features</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Pricing</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">FAQs</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">About</a></li>
        </ul>
      </div>

      <div class="col-6 col-md-2 mb-3">
        <h5>Section</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Home</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Features</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Pricing</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">FAQs</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">About</a></li>
        </ul>
      </div>

      <div class="col-6 col-md-2 mb-3">
        <h5>Section</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Home</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Features</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Pricing</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">FAQs</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">About</a></li>
        </ul>
      </div>

      <div class="col-md-5 offset-md-1 mb-3">
        <form>
          <h5>Subscribe to our newsletter</h5>
          <p>Monthly digest of what's new and exciting from us.</p>
          <div class="d-flex flex-column flex-sm-row w-100 gap-2">
            <label for="newsletter1" class="visually-hidden">Email address</label>
            <input id="newsletter1" type="text" class="form-control" placeholder="Email address" />
            <button class="btn btn-primary" type="button">Subscribe</button>
          </div>
        </form>
      </div>
    </div>

    <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
      <p>© 2022 Company, Inc. All rights reserved.</p>
      <ul class="list-unstyled d-flex">
        <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"></svg></a></li>
        <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"></svg></a></li>
        <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"></svg></a></li>
      </ul>
    </div>
  </footer>
</div>*/ }
  
  </div>

  
    )
    
    
    return (
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img1} style={{width:"90%",marginLeft:"5%" ,height:"100%",backgroundSize:"cover"}} class="d-block w-60 h-50" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={img2} style={{width:"90%",marginLeft:"5%" ,height:"100%",backgroundSize:"cover"}} class="d-block w-60 h-50" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={img3} style={{width:"90%",marginLeft:"5%" ,height:"100%",backgroundSize:"cover"}} class="d-block w-60 h-50" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    )
    
}