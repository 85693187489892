import { Breadcrumbs, Typography } from "@mui/material";
import styles from "./moteur.module.css";
import { Link } from "react-router-dom";

const dimotor = require("../../assets/dimotor.gif");
const dimotorpng = require("../../assets/DIMOTOR.png");
const serie_ta = require("../../assets/serie-ta.png")
const serie_tc = require("../../assets/serie-tc.png")
const serie_mt = require("../../assets/serie-mt-ma.png");


export default function Moteur(params) {
  return (
    <>
      <div>
        <div class="container-xxl py-6">
          <div class="container">
            <div
              class="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <div style={{ marginTop: "2%" }}>
                <h1 className={styles.font_}>Moteur</h1>
                <hr />
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator=">"
                  sx={{
                    "& ol": {
                      justifyContent: "center",
                      margin: "auto",
                    },
                  }}
                >
                  <Link
                    to="/equipement-process"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    Produits
                  </Link>

                  <Link
                    to="/equipement-process"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    equipement process
                  </Link>

                  <Typography color="text.primary">Moteur</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div>
              <h2
                className={styles.font}
                style={{ textAlign: "center", marginTop: "3%" }}
              >
                Découvrez notre large sélection des moteurs de haute qualité sur
                le site de notre approvisionneur technique
              </h2>
            </div>
            <div class="row g-4 mt-5">
              <div class="row">
                <div class="col-md-8">
                  <p style={{ fontSize: "20px" }}>
                    Que vous travailliez dans les moteurs electriques, nous
                    avons les moteurs adaptés à vos besoins spécifiques.
                  </p>
                </div>
              </div>
              <div class="row">
                <h4 className={styles.font} style={{ textAlign: "center" }}>
                  Moteur asynchrone triphasé en aluminium.
                </h4>
                <hr />
                <p style={{ fontSize: "20px" }}>
                MOTEUR TECHTOP SERIE TA
                </p>
                
                <div class="col-md-4">
                <div className={styles.service_item1}>
                    <div></div>
                    <p className={styles.service_item_p}>
                  <ul style={{ listStyleType: "circle" }}>
                    <li>boîtier en aluminium</li>
                    <li>Tailles du 56 au 200</li>
                    <li>Puissances de 0,09kW à 22kW</li>
                    <li>2, 4, 6 et 8 pôles</li>
                    <li>Efficacité IE1, IE2, IE3, IE4</li>
                    <li>Marquage UL-CSA en standard</li>
                    <li>Sondes PTC en standard</li>
                    <li>Classe d'isolation F</li>
                    <li>classe de température B</li>
                    <li>Prestation S1</li>
                    <li>Protection IP55</li>
                    <li>Réfrigération IC411</li>
                  </ul>
                  </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <img src={serie_ta} style={{width:"100%"}} />
                </div>
                <div class="col-md-4">
                  <div className={styles.service_item1}>
                    <div></div>
                    <div style={{ marginTop: "10%" }}>
                      <h4 className={styles.service_item_h4}>Facultatif:</h4>
                      <p className={styles.service_item_p}>
                        <ul style={{ listStyleType: "circle" }}>
                          <li>Protection IP56 ou IP65</li>
                          <li>Certification GL‐DNV</li>
                          <li>Certification Atex Zones 2 et 22</li>
                          <li>Thermistance PT100 et PTO dans l'enroulement</li>
                          <li>Thermistance PT100 dans les roulements</li>
                          <li>résistance anti-condensation</li>
                          <li>roulement isolé</li>
                          <li>Tension/fréquence spéciale</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row" style={{marginTop:"3%"}}>
                <h4 className={styles.font} style={{ textAlign: "center" }}>
                Moteur asynchrone triphasé en fonte
                </h4>
                <hr />
                <p style={{ fontSize: "20px" }}>
                MOTEUR TECHTOP SÉRIE TC
                </p>
                
                <div class="col-md-4">
                <div className={styles.service_item1}>
                    <div></div>
                    <p className={styles.service_item_p}>
                  <ul style={{ listStyleType: "circle" }}>
                    <li>carter en fonte</li>
                    <li>Tailles de 80 à 355</li>
                    <li>Puissances de 0,18kW à 710kW</li>
                    <li>2, 4, 6 et 8 pôles</li>
                    <li>Efficacité IE1, IE2, IE3, IE4</li>
                    <li>Marquage UL-CSA en standard</li>
                    <li>Sondes PTC standard - Classe d'isolation F</li>
                    <li>classe de température B</li>
                    <li>Prestation S1</li>
                    <li>Protection IP55</li>
                    <li>Réfrigération IC411</li>
                    <li>Roulements NSK</li>
                  </ul>
                  </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <img src={serie_tc} style={{width:"100%"}} />
                </div>
                <div class="col-md-4">
                  <div className={styles.service_item1}>
                    <div></div>
                    <div style={{ marginTop: "10%" }}>
                      <h4 className={styles.service_item_h4}>Facultatif:</h4>
                      <p className={styles.service_item_p}>
                        <ul style={{ listStyleType: "circle" }}>
                          <li>Protection IP56 ou IP65</li>
                          <li>Certification GL‐DNV</li>
                          <li>Certification Atex Zones 2 et 22</li>
                          <li>Thermistance PT100 et PTO dans l'enroulement</li>
                          <li>Thermistance PT100 dans les roulements</li>
                          <li>résistance anti-condensation</li>
                          <li>roulement isolé</li>
                          <li>Tension/fréquence spéciale</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style={{marginTop:"3%"}}>
                <h4 className={styles.font} style={{ textAlign: "center" }}>
                Moteur à châssis en aluminium monophasé à démarreur non renforcé
                </h4>
                <hr />
                <p style={{ fontSize: "20px" }}>
                MOTEUR TECHTOP SÉRIE MY/MYT
                </p>
                
                <div class="col-md-4">
                <div className={styles.service_item1}>
                    <div></div>
                    <p className={styles.service_item_p}>
                  <ul style={{ listStyleType: "circle" }}>
                    <li>boîtier en aluminium</li>
                    <li>Tailles du 56 au 100</li>
                    <li>Puissances de 0,09kW à 3kW - 2, 4 et 6 pôles</li>
                    <li>Classe d'isolation F</li>
                    <li>Prestation S1</li>
                    <li>Protection IP55</li>
                    <li>Réfrigération IC411</li>
                    <li>Montage IM B3, B5, B35, B14</li>
                  </ul>
                  </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <img src={serie_mt} style={{width:"100%"}} />
                </div>
                
              </div>
              {/* <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div
                  className={styles.team_item}
                  style={{
                    width: "50%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{
                      padding: "10px",
                      border: "1px solid #abd473",
                      marginTop: "",
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "80%", scale: "1" }}
                      src={dimotor}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-6">
                <ul className={styles.ul}>
                  <div className="row">
                    <li className={styles.li}>MOTEUR TECHTOP SERIE ML</li>
                    <li className={styles.li}>MOTEUR TECHTOP SÉRIE MSBCCL</li>
                    <li className={styles.li}>MOTEUR TECHTOP SERIE TA</li>
                    <li className={styles.li}>MOTEUR TECHTOP SÉRIE TC</li>
                    <li className={styles.li}>MOTEUR TECHTOP SÉRIE MY/MYT</li>
                    <li className={styles.li}>MOTEUR TECHTOP SERIE EC</li>
                  </div>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
