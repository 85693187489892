import styles from "./details.module.css";

const prj1 = require("../../assets/projet2.png");
const prj2 = require("../../assets/projet3.png");
const prj3 = require("../../assets/projet5.png");
const prj4 = require("../../assets/projet6.png");
const prj5 = require("../../assets/projet7.png");
const prj6 = require("../../assets/projet8.png");
const prj7 = require("../../assets/projet9.png");
const prj8 = require("../../assets/projet10.png");
const prj9 = require("../../assets/projet11.png");
const prj10 = require("../../assets/projet12.png");
const prj11 = require("../../assets/projet15.png");
const prj12 = require("../../assets/projet16.png");

export default function DetailsProject(params) {
  return (
    <div>
      <div>
        <div style={{ textAlign: "center", marginTop: "3%" }}>
          <h1 className={styles.font_}>Nos Réalisations</h1>
          <hr
            style={{
              width: "30%",
              display: "block",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />
        </div>
        <div style={{ marginTop: "3%" }}>
          <div style={{ padding: "3%" }}>
            <h2 className={styles.font_}>
              Aspiration industrielle centralisée d’une unité de concassage OCP
            </h2>
            <hr style={{ width: "67%" }} />
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <img src={prj1} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj2} style={{ width: "100%", height: "92%" }} />
              </div>
              <div className="col">
                <img src={prj1} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div></div>
          <div style={{ marginTop: "3%", backgroundColor: "#ABD473" }}>
            <div style={{ padding: "3%" }}>
              <ul>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Client : OCP Groupe –Site YOUSSOUFIA MZINDA
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Besoin : solution complète « clé en main » pour le nettoyage
                    d’une unité de concassage
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Solution de ATIS : Développement et mise en place d’un
                    système d’aspiration centralisé par vide avec des conduites
                    et ramification sur l’ensemble des points critiques de
                    l’unité de concassage « bâtiment de 4 étages » , solution
                    composée d’un groupe d’aspiration 55 kw , de cyclofiltre ,
                    de conduites et bouches d’aspiration. <br />
                    Prestations incluses : <br />
                    <ul>
                      <li style={{ listStyleType: "" }}>
                        Réalisation du génie civil
                      </li>
                      <li style={{ listStyleType: "" }}>
                        Montage du système d’aspiration complet
                      </li>
                      <li style={{ listStyleType: "" }}>
                        Raccordement électrique
                      </li>
                      <li style={{ listStyleType: "" }}>Montage</li>
                      <li style={{ listStyleType: "" }}>
                        Démarrage et formation de l’équipe d’exploitation à
                        l’utilisation et à la maintenance
                      </li>
                    </ul>
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Budget de réalisation : 1,7 M DH{" "}
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Délai de réalisation : 6 Mois
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>Année : 2019 </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "3%" }}>
          <div style={{ padding: "3%" }}>
            <h2 className={styles.font_}>
              Véhicules d’intervention Derichebourg
            </h2>
            <hr style={{ width: "67%" }} />
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <img src={prj4} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj5} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj3} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3%", backgroundColor: "#ABD473" }}>
            <div style={{ padding: "3%" }}>
              <ul>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Client : Derichebourg- Casablanca
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Besoin : Doter les équipes d’intervention de solution
                    technique qui permettra le nettoyage rapide en parfaite
                    autonomie
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Solution de ATIS : Equipement des véhicules déjà existants
                    chez DERICHEBOURG d’un système de nettoyage autonome et
                    déplaçable qui se compose de : Nettoyeur haute pression à
                    eau chaude autonome, Pompe à vide pour transfert des eaux
                    sales , Groupe électrogène diesel , cuves de
                    transfert-stockage et lances et cloches de nettoyage ATIS a
                    assuré le montage et adaptation des équipements au niveau
                    des véhicules et aussi à la mise en marche et formation à
                    l’utilisation des équipements
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Budget de réalisation : 1,94 M DH{" "}
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Délai de réalisation : 4 Mois
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>Année : 2019 </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "3%" }}>
          <div style={{ padding: "3%" }}>
            <h2 className={styles.font_}>
              Station de lavage des isolateurs HTB
            </h2>
            <hr style={{ width: "67%" }} />
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <img src={prj7} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj8} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj6} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3%", backgroundColor: "#ABD473" }}>
            <div style={{ padding: "3%" }}>
              <ul>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>Client : ONEE- Maroc</h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Besoin : stations de lavage des isolateurs sous tension HTB
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Solution de ATIS : mise en place des station mobile qui
                    permettent le lavage des isolateurs HTB « sous tension » en
                    toute sécurité :
                    <ul>
                      <li>Fourniture des stations de lavage HTB </li>
                      <li>
                        Montage et adaptation de ces stations aux niveau des
                        camions de l’ONEE
                      </li>
                      <li>
                        Formation à l’utilisation et à la maintenance au profit
                        des équipes ONEE d’exploitation (Site CASABLANCA ,
                        AGADIR){" "}
                      </li>
                    </ul>
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>Montant : plus de 3 M DH</h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>Années : 2018-2019-2020 </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "3%" }}>
          <div style={{ padding: "3%" }}>
            <h2 className={styles.font_}>Pompes anti incendie UNILEVER</h2>
            <hr style={{ width: "67%" }} />
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <img src={prj9} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj10} style={{ width: "100%", height: "85%" }} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3%", backgroundColor: "#ABD473" }}>
            <div style={{ padding: "3%" }}>
              <ul>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Client : UNILEVER - Casablanca
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Besoin : Rénovation du circuit de lutte anti-incendie en
                    réponse aux exigences des assureurs
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Solution de ATIS : Mise en place d’une station de pompage
                    certifiée NFP20 ,développement et choix de solution
                    technique en tenant compte des contraintes du lieu
                    d’installation « mise en place de pompe verticales : pompage
                    à partir de bassin enterré » . L’unité est composée d’une
                    pompe électrique verticale et d’une autre pompe avec moteur
                    diesel en plus de la pompe Jockey
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Budget de réalisation : 0,9 M DH{" "}
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Délai de réalisation : 4 Mois
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>Année : 2016 </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "3%" }}>
          <div style={{ padding: "3%" }}>
            <h2 className={styles.font_}>
              Composteurs -Ministère de l’environnement
            </h2>
            <hr style={{ width: "67%" }} />
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <img src={prj4} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj5} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj3} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3%", backgroundColor: "#ABD473" }}>
            <div style={{ padding: "3%" }}>
              <ul>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Client : Ministère de l’environnement -Maroc
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Besoin : appel d’offre publique pour la conception et
                    livraison de 32 composteurs électriques dans le cadre des
                    mesures d’encouragement à la protection de l’environnement
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Solution de ATIS : Conception , industrialisation et
                    production de 32 composteurs électriques .
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Budget de réalisation : Plus de 5 M DH{" "}
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Délai de réalisation : 4 Mois
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>Année : 2018 </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "3%" }}>
          <div style={{ padding: "3%" }}>
            <h2 className={styles.font_}>
              OCP –Centre de recherche –JORF LASFER
            </h2>
            <hr style={{ width: "67%" }} />
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <img src={prj4} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj5} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj3} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3%", backgroundColor: "#ABD473" }}>
            <div style={{ padding: "3%" }}>
              <ul>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Client : OCP –Centre de recherche –JORF LASFER
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Besoin : Mise en place d’un pilote qui permettra la
                    simulation des opérations de lavage des gaz pour
                    amélioration du process au niveau des laveurs des colonnes
                    de lavage des usine
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Solution de ATIS : conception de la solution ,Livraison des
                    analyseurs et mise en marche du circuit
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Budget de réalisation : Plus de 0,4 M DH{" "}
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Délai de réalisation : 3 Mois
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>Année : 2020 </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "3%" }}>
          <div style={{ padding: "3%" }}>
            <h2 className={styles.font_}>OCP –KHOURIBGA</h2>
            <hr style={{ width: "67%" }} />
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <img src={prj4} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj5} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj3} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3%", backgroundColor: "#ABD473" }}>
            <div style={{ padding: "3%" }}>
              <ul>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Client : OCP –KHOURIBGA Station de pompage
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Besoin : changement des pompes hauts débits KSB
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Solution de ATIS : livraison des pompes KSB hauts débits «
                    pompes à plan de joint « débit 1152 m3/H , HMT 140m cl ,
                    Puissance 710 KW , vérification du montage et assistance au
                    démarrage « analyse vibratoire , spectrale , tests des
                    performances » + formation
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Budget de réalisation : Plus de 3,2 M DH{" "}
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Délai de réalisation : 5 Mois
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>Année : 2019 </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "3%" }}>
          <div style={{ padding: "3%" }}>
            <h2 className={styles.font_}>SUEZ – STATION D’ALERTE</h2>
            <hr style={{ width: "67%" }} />
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <img src={prj4} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj5} style={{ width: "100%" }} />
              </div>
              <div className="col">
                <img src={prj3} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3%", backgroundColor: "#ABD473" }}>
            <div style={{ padding: "3%" }}>
              <ul>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Client : SUEZ –Barrage de Sidi Maachou
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Besoin : Une solution complète pour l’analyse et le suivi
                    des eaux de barrage de Sidi Macchou et qui évitera le suivi
                    manuel de ces opérations
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Solution de ATIS : : développement d’une solution complète
                    qui permettra le suivi automatique de plusieurs paramètres
                    des eaux « niveau , PH , turbidité, oxygène ….etc » et
                    communication en ligne de ces données par télécommunication
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Budget de réalisation : Plus de 1,6 M DH{" "}
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Délai de réalisation : 3 Mois{" "}
                  </h4>
                </li>
                <li style={{ listStyleType: "circle" }}>
                  <h4 className={styles.font_}>
                    Année : en cours de réalisation{" "}
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
