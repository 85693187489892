import styles from "./testmonial.module.css";

const pic1 = require("../../assets/1531837903669.jpg");
const pic2 = require("../../assets/100.png");
const pic3 = require("../../assets/04.jpg");
const man = require("../../assets/man.png");

export default function Testimonial(params) {

  
  const testimonials = [
    {
      name: "Eva Sawyer",
      job: "CEO, Fashworks",
      image: "https://i.postimg.cc/gJDkZrNn/profile-image-1.png",
      testimonial:
        "Neque volutpat ac tincidunt vitae semper quis lectus nulla at volutpat diam ut venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur"
    },
    {
      name: "Katey Topaz",
      job: "Developer, TechCrew",
      image: "https://i.postimg.cc/8kZzkJ7Y/profile-image-2.png",
      testimonial:
        "Elementum tempus egestas sed sed risus pretium quam vulputate dignissim suspendisse in est ante in nibh mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet nulla"
    },
    {
      name: "Jae Robin",
      job: "UI Designer, Affinity Agency",
      image: "https://i.postimg.cc/90gmLK32/profile-image-3.png",
      testimonial:
        "Orci nulla pellentesque dignissim enim sit amet venenatis urna cursus eget nunc scelerisque viverra mauris in aliquam sem fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque massa placerat duis ultricies lacus sed turpis"
    },
    {
      name: "Nicola Blakely",
      job: "CEO,Zeal Wheels",
      image: "https://i.postimg.cc/6qp6Lwmz/profile-image-4.png",
      testimonial:
        "Sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit"
    }
  ];
  let i = 0; // current slide
  let j = testimonials.length; // total slides
  let testimonialContainer = document.getElementById("testimonial-container");
  function next() {
    i = (j + i + 1) % j;
    displayTestimonial();
  }
  
  function prev() {
    i = (j + i - 1) % j;
    displayTestimonial();
  }

  let displayTestimonial = () => {
    testimonialContainer.innerHTML = `
          <p>${testimonials[i].testimonial}</p>
           <img src=${testimonials[i].image}></img>
          <h3>${testimonials[i].name}</h3>
          <h6>${testimonials[i].job}</h6>
          `;
  };
  window.onload = displayTestimonial;


  return (
    <>
      <div id="loader"></div>
      <div id="box">
        <div class="img-con">
          <img src="https://i.postimg.cc/gJDkZrNn/profile-image-1.png" />
          <img src="https://i.postimg.cc/8kZzkJ7Y/profile-image-2.png" />
          <img src="https://i.postimg.cc/90gmLK32/profile-image-3.png" />
          <img src="https://i.postimg.cc/6qp6Lwmz/profile-image-4.png" />
        </div>
        <div class="wrapper">
          <div class="testimonial-container" id="testimonial-container">
            <div id="testimonial1" class="active">
              <img src="" />
            </div>
          </div>
          <button id="prev" onclick={prev()}>
            &lt;
          </button>
          <button id="next" onclick={next()}>
            &gt;
          </button>
        </div>
        <a href="https://youtu.be/sRJXGtM72KQ" target="_blank">
          Watch Me Code &nbsp;<i class="fab fa-youtube"></i>
        </a>
      </div>
    </>
  );
  return (
    <div class="container-xxl py-5">
      <div class="container">
        <div
          class="text-center mx-auto wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <p class="fw-medium text-uppercase text-primary mb-2">Testimonial</p>
          <h1 class="display-5 mb-5">What Our Clients Say!</h1>
        </div>
        <div
          class="owl-carousel testimonial_carousel wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="testimonial_item text-center">
            <div class="testimonial-img position-relative">
              <img
                class="img-fluid rounded-circle mx-auto mb-5"
                src="img/testimonial-1.jpg"
              />
              <div class="btn-square bg-primary rounded-circle">
                <i class="fa fa-quote-left text-white"></i>
              </div>
            </div>
            <div class="testimonial_text text_center rounded p-4">
              <p>
                Clita clita tempor justo dolor ipsum amet kasd amet duo justo
                duo duo labore sed sed. Magna ut diam sit et amet stet eos sed
                clita erat magna elitr erat sit sit erat at rebum justo sea
                clita.
              </p>
              <h5 class="mb-1">Client Name</h5>
              <span class="fst-italic">Profession</span>
            </div>
          </div>
          <div class="testimonial_item text_center">
            <div class="testimonial-img position-relative">
              <img
                class="img-fluid rounded-circle mx-auto mb-5"
                src="img/testimonial-2.jpg"
              />
              <div class="btn_square bg-primary rounded-circle">
                <i class="fa fa-quote-left text-white"></i>
              </div>
            </div>
            <div class="testimonial-text text-center rounded p-4">
              <p>
                Clita clita tempor justo dolor ipsum amet kasd amet duo justo
                duo duo labore sed sed. Magna ut diam sit et amet stet eos sed
                clita erat magna elitr erat sit sit erat at rebum justo sea
                clita.
              </p>
              <h5 class="mb-1">Client Name</h5>
              <span class="fst-italic">Profession</span>
            </div>
          </div>
          <div class="testimonial-item text-center">
            <div class="testimonial-img position-relative">
              <img
                class="img-fluid rounded-circle mx-auto mb-5"
                src="img/testimonial-3.jpg"
              />
              <div class="btn-square bg-primary rounded-circle">
                <i class="fa fa-quote-left text-white"></i>
              </div>
            </div>
            <div class="testimonial-text text-center rounded p-4">
              <p>
                Clita clita tempor justo dolor ipsum amet kasd amet duo justo
                duo duo labore sed sed. Magna ut diam sit et amet stet eos sed
                clita erat magna elitr erat sit sit erat at rebum justo sea
                clita.
              </p>
              <h5 class="mb-1">Client Name</h5>
              <span class="fst-italic">Profession</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      id="carouselExampleFade"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="testimonial-item text-center">
            <div class="testimonial-img">
              <img
                class="img-fluid rounded-circle mx-auto mb-5"
                src={man}
                style={{ width: "9%" }}
              />
              <div class={styles.btn_square}>
                <i class="fa fa-quote-left text-white"></i>
              </div>
            </div>
            <div className={styles.testimonial_text}>
              <p>
                Clita clita tempor justo dolor ipsum amet kasd amet duo justo
                duo duo labore sed sed. <br />
                Magna ut diam sit et amet stet eos sed clita erat magna elitr
                erat sit sit erat at rebum justo sea clita.
              </p>
              <h5 class="mb-1">Client Name</h5>
              <span class="fst-italic">Profession</span>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="testimonial-item text-center">
            <div class="testimonial-img">
              <img
                class="img-fluid rounded-circle mx-auto mb-5"
                src={man}
                style={{ width: "9%" }}
              />
              <div class={styles.btn_square}>
                <i class="fa fa-quote-left text-white"></i>
              </div>
            </div>
            <div className={styles.testimonial_text}>
              <p>
                Clita clita tempor justo dolor ipsum amet kasd amet duo justo
                duo duo labore sed sed. <br />
                Magna ut diam sit et amet stet eos sed clita erat magna elitr
                erat sit sit erat at rebum justo sea clita.
              </p>
              <h5 class="mb-1">Client Name</h5>
              <span class="fst-italic">Profession</span>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="testimonial-item text-center">
            <div class="testimonial-img">
              <img
                class="img-fluid rounded-circle mx-auto mb-5"
                src={man}
                style={{ width: "9%" }}
              />
              <div class={styles.btn_square}>
                <i class="fa fa-quote-left text-white"></i>
              </div>
            </div>
            <div className={styles.testimonial_text}>
              <p>
                Clita clita tempor justo dolor ipsum amet kasd amet duo justo
                duo duo labore sed sed. <br />
                Magna ut diam sit et amet stet eos sed clita erat magna elitr
                erat sit sit erat at rebum justo sea clita.
              </p>
              <h5 class="mb-1">Client Name</h5>
              <span class="fst-italic">Profession</span>
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="prev"
        style={{ color: "red" }}
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="next"
        style={{ color: "red" }}
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  );
}
