import { Breadcrumbs, Typography } from "@mui/material";
import styles from "./agitateur.module.css";
import { Link } from "react-router-dom";

const pic = require("../../assets/9.png");
const ag_toit = require("../../assets/ag-toit-.png");
const ag_lit = require("../../assets/ag-lett.png");
const ag_fond = require("../../assets/ag-fond.png");
const melg = require("../../assets/mlg.png");
const hilec = require("../../assets/helice.png");
const turb = require("../../assets/turbine.png");
const ancre = require("../../assets/ancre.png");
const ag_con = require("../../assets/ag-con.png");
const agt = require("../../assets/agit.png");
const agitateur = require("../../assets/agitateur.gif");

export default function Agitateur(params) {
  return (
  
      
        <div class="container-xxl py-6">
          <div class="container">
            <div
              class="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <div style={{ marginTop: "2%" }}>
                <h1 className={styles.font_}>Agitateur</h1>
                <hr />
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator=">"
                  sx={{
                    "& ol": {
                      justifyContent: "center",
                      margin: "auto",
                    },
                  }}
                >
                  <Link
                    to="/equipement-process"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    Produits
                  </Link>

                  <Link
                    to="/equipement-process"
                    style={{ textDecoration: "none", color: "#3367ec" }}
                  >
                    equipement process
                  </Link>

                  <Typography color="text.primary">Agitateur</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div>
              <h2
                className={styles.font}
                style={{ textAlign: "center", marginTop: "3%" }}
              >
                Découvrez notre large sélection d'agitateurs de haute qualité
                sur le site de notre approvisionneur technique
              </h2>
            </div>
            <div class="row g-4 mt-5">
              <div>
                <p style={{ fontSize: "20px" }}>
                  Que vous travailliez dans l'industrie chimique, pharmaceutique
                  ou alimentaire, nous avons les agitateurs adaptés à vos
                  besoins spécifiques.
                </p>
              </div>
              <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div
                  className={styles.team_item}
                  style={{
                    width: "50%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-100 mb-4"
                    style={{
                      padding: "10px",
                      border: "1px solid #abd473",
                      marginTop: "",
                    }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "60%", scale: "1" }}
                      src={agitateur}
                      alt=""
                    />
                  </div>

                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <ul className={styles.ul}>
                  <div className="row">
                    <li className={styles.li}>AGITATEUR TOIT DE CUVE</li>
                    <li className={styles.li}>AGITATEUR LATÉRAL DE CUVE</li>
                    <li className={styles.li}>AGITATEUR FOND DE CUVE</li>
                    <li className={styles.li}>MÉLANGEURS STATIQUES</li>

                    <li className={styles.li}>AGITATEUR CONTRAROTATIF</li>
                    <li className={styles.li}>AGITATEUR HORIZONTAL</li>
                    <li className={styles.li}>
                      Agitateur industriel de type rotor-stator
                    </li>
                    <li className={styles.li}>ANCE</li>
                  </div>
                </ul>
              </div>
              {/* <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={ag_lit}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>
                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={ag_fond}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={ag_con}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div class="row g-4" style={{ marginTop: "3%" }}>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>
                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "90%", scale: "0.95" }}
                      src={hilec}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>
                  <div
                    className="img-fluid rounded-circle w-120 mb-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "38%", scale: "0.95" }}
                      src={agt}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>

                  <div
                    className="img-fluid rounded-circle w-120 mt-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "93%", scale: "0.95" }}
                      src={ancre}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className={styles.team_item}>
                  <h5 className={styles.team_item_h5}></h5>
                  <div
                    className="img-fluid rounded-circle w-120  mt-4"
                    style={{ padding: "10px", border: "1px solid #abd473" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ width: "100%", scale: "0.95" }}
                      src={melg}
                      alt=""
                    />
                  </div>
                  <div class="d-flex justify-content-center">
                    <p
                      className={styles.team_item_h5}
                      style={{ fontSize: "15px" }}
                    ></p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/*  <div style={{ marginTop: "3%" }}>
            <div>
              <p style={{ fontSize: "20px" }}>Zone paragraph</p>
            </div>
            <ul className={styles.ul}>
              <div className="row">
                <div className="col-md-6">
                  <li className={styles.li}>AGITATEUR TOIT DE CUVE</li>
                  <li className={styles.li}>AGITATEUR LATÉRAL DE CUVE</li>
                  <li className={styles.li}>AGITATEUR FOND DE CUVE</li>
                  <li className={styles.li}>MÉLANGEURS STATIQUES</li>
                </div>
                <div className="col-md-6">
                  <li className={styles.li}>AGITATEUR CONTRAROTATIF</li>
                  <li className={styles.li}>AGITATEUR HORIZONTAL</li>
                  <li className={styles.li}>
                    Agitateur industriel de type rotor-stator
                  </li>
                  <li className={styles.li}>ANCE</li>
                </div>
              </div>
            </ul>
          </div> */}
        </div>
      
    
  );
}
