import { Link } from "react-router-dom";
import styles from "./project.module.css";

const er = require("../../assets/prj1.png");
const pr1 = require("../../assets/prj2.png");
const pr2 = require("../../assets/prj3.png");
const pr3 = require("../../assets/prj4.png");
const pr4 = require("../../assets/compr.png");
const pr5 = require("../../assets/ocp.png");
const pr6 = require("../../assets/suez.png");
const pr7 = require("../../assets/jorf.png");

export default function Project(params) {
  return (
    <div class="container-xxl  py-5 my-5" style={{ backgroundColor: "white" }}>
      <div class="container py-5">
        <div
          class="text-center mx-auto wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "500px" }}
        >
          <h1 className={styles.font_}>Nos projets</h1>
          <hr
            style={{
              marginRight: "auto",
              marginLeft: "auto",
              display: "block",
            }}
          />
        </div>
        <p className={styles.second_font} style={{ marginTop: "3%" }}>
          L'APPROVISIONNEUR TECHNIQUE INTERNATIONAL SA a réalisé de nombreux
          projets passionnants pour ses clients. Nous sommes fiers de partager
          avec vous certaines de nos réalisations les plus importantes, dans le
          cadre desquelles nous avons fourni des solutions innovantes pour
          répondre aux besoins de nos clients. Découvrez comment nous avons aidé
          nos clients à atteindre leurs objectifs grâce à notre expertise en
          matière d'approvisionnement technique :
        </p>
        <div class="row g-4 " style={{ marginTop: "5%" }}>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className={styles.service_item}>
              <div class=" p-5" style={{ backgroundColor: "#abd473" }}>
                <img
                  class="img-fluid mb-4"
                  src={er}
                  style={{ width: "100%" }}
                  alt=""
                />
                <h5 class="mb-3" style={{ textAlign: "center" }}>
                  Aspiration industrielle centralisée d’une unité de concassage
                  OCP
                </h5>

                <p>
                  Solution de ATIS : Développement et mise en place d’un système
                  d’aspiration centralisé...
                </p>
                <Link to="/details-projets" style={{ textDecoration: "none" }}>
                  <div className={styles.btn_5} style={{ textAlign: "center" }}>
                    EN SAVOIRE PLUS
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className={styles.service_item}>
              <div class=" p-5" style={{ backgroundColor: "#abd473" }}>
                <img
                  class="img-fluid mb-4"
                  src={pr1}
                  style={{ width: "100%" }}
                  alt=""
                />
                <h5 class="mb-3" style={{ textAlign: "center" }}>
                  Véhicules d’intervention Derichebourg
                </h5>

                <p>
                  Solution de ATIS : Equipement des véhicules déjà existants
                  chez DERICHEBOURG d’un système...
                </p>
                <Link to="/details-projets" style={{ textDecoration: "none" }}>
                  <div className={styles.btn_5} style={{ textAlign: "center" }}>
                    EN SAVOIRE PLUS
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className={styles.service_item}>
              <div class=" p-5" style={{ backgroundColor: "#abd473" }}>
                <img
                  class="img-fluid mb-4"
                  src={pr2}
                  style={{ width: "100%" }}
                  alt=""
                />
                <h5 class="mb-3" style={{ textAlign: "center" }}>
                  Station de lavage des isolateurs HTB
                </h5>

                <p>
                  <b>Solution de ATIS</b> : mise en place des station mobile qui
                  permettent le lavage isolateurs HTB...
                </p>
                <Link to="/details-projets" style={{ textDecoration: "none" }}>
                  <div className={styles.btn_5} style={{ textAlign: "center" }}>
                    EN SAVOIRE PLUS
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className={styles.service_item}>
              <div class="p-5" style={{ backgroundColor: "#abd473" }}>
                <img
                  class="img-fluid mb-4"
                  src={pr3}
                  style={{ width: "100%" }}
                  alt=""
                />
                <h5 class="mb-3" style={{ textAlign: "center" }}>
                  Pompes anti incendie
                  <br /> UNILEVER
                </h5>

                <p>
                  Solution de ATIS : Mise en place d’une station de pompage
                  certifiée NFP20 ,développement et choix...
                </p>
                <Link to="/details-projets" style={{ textDecoration: "none" }}>
                  <div className={styles.btn_5} style={{ textAlign: "center" }}>
                    EN SAVOIRE PLUS
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className={styles.service_item}>
              <div class="p-5" style={{ backgroundColor: "#abd473" }}>
                <img
                  class="img-fluid mb-4"
                  src={pr4}
                  style={{ width: "100%" }}
                  alt=""
                />
                <h5 class="mb-3" style={{ textAlign: "center" }}>
                  Composteurs -Ministère de l’environnement
                </h5>
                <p>
                  Solution de ATIS : Conception , industrialisation et
                  production de 32 composteurs électriques...{" "}
                </p>
                <Link to="/details-projets" style={{ textDecoration: "none" }}>
                  <div className={styles.btn_5} style={{ textAlign: "center" }}>
                    EN SAVOIRE PLUS
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className={styles.service_item}>
              <div class="p-5" style={{ backgroundColor: "#abd473" }}>
                <img
                  class="img-fluid mb-4"
                  src={pr7}
                  style={{ width: "100%" }}
                  alt=""
                />
                <h5 class="mb-3" style={{ textAlign: "center" }}>
                  OCP –Centre de recherche –JORF LASFER
                </h5>
                <p>
                  Solution de ATIS : conception de la solution ,Livraison des
                  analyseurs et mise en marche du circuit...
                </p>
                <Link to="/details-projets" style={{ textDecoration: "none" }}>
                  <div className={styles.btn_5} style={{ textAlign: "center" }}>
                    EN SAVOIRE PLUS
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className={styles.service_item}>
              <div class="p-5" style={{ backgroundColor: "#abd473" }}>
                <img
                  class="img-fluid mb-4"
                  src={pr6}
                  style={{ width: "100%" }}
                  alt=""
                />
                <h5 class="mb-3" style={{ textAlign: "center" }}>
                  SUEZ – STATION D’ALERTE{" "}
                </h5>

                <p>
                  Solution de ATIS : : développement d’une solution complète qui
                  permettra le suivi automatique de plusieurs...
                </p>
                <Link to="/details-projets" style={{ textDecoration: "none" }}>
                  <div className={styles.btn_5} style={{ textAlign: "center" }}>
                    EN SAVOIRE PLUS
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className={styles.service_item}>
              <div class="p-5" style={{ backgroundColor: "#abd473" }}>
                <img
                  class="img-fluid mb-4"
                  src={pr5}
                  style={{ width: "100%" }}
                  alt=""
                />
                <h5 class="mb-3" style={{ textAlign: "center" }}>
                  OCP –KHOURIBGA
                </h5>
                <p>
                  Solution de ATIS : livraison des pompes KSB hauts débits «
                  pompes à plan de joint « débit 1152 m3/H...
                </p>
                <Link to="/details-projets" style={{ textDecoration: "none" }}>
                  <div className={styles.btn_5} style={{ textAlign: "center" }}>
                    EN SAVOIRE PLUS
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
