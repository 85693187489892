import React from "react";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

                                     
export default function Bread(name1,name2,name3) {

  return (

    
    <Breadcrumbs aria-label="breadcrumb" sx={{
      "& ol": {
        justifyContent: "center",
        margin: "auto"
      }
    }} 
    >  
          
      <Link underline="hover" color="inherit" href="/">
        {name1}
      </Link>

      <Link
        underline="hover"
        color="inherit"
        href="/material-ui/getting-started/installation/"
      >
        {name2}
      </Link>

      <Typography color="text.primary">{name3}</Typography>

    </Breadcrumbs>

  );
}
