import styles from "./page.module.css"
import { Link } from "react-router-dom";

const card1 = require("../../assets/maxresdefault.jpg");
const bg_ag = require("../../assets/bg-ag.png")
const bg_ech = require("../../assets/bg-ech.png")
const bg_filter = require("../../assets/bg-filter.png")
const pompe = require("../../assets/gif pompe.gif");
const ech = require("../../assets/gif-echan.gif")
const agi = require("../../assets/agitateur-gif.gif")
const bro = require("../../assets/broy-gif.gif")
const van = require("../../assets/van-gif.gif")
const comp = require("../../assets/compresseur-gif.gif")
const vent = require("../../assets/ventilateur.gif")
const filtre = require("../../assets/filtres-gif.gif")
const dimotor = require("../../assets/moteur.gif")

export default function Category1(params) {
    return(
        <>
        <div style={{marginTop:"3%"}}>
            <h1 className={styles.font_} style={{textAlign:"center"}}>LES ÉQUIPEMENTS PROCESS</h1>
            <h4 className={styles.second_font} style={{textAlign:"center"}}>La puissance derrière votre process</h4>
            <hr style={{marginLeft:"auto",marginRight:"auto",display:"block",width:"50%"}}/>
            
        <div class="container" style={{marginTop:"5%"}}>
            <div class="row" >
              <div class="col-md-4">
                <div className={styles.card}>
                <div class="card" style={{backgroundColor:"#ABD473"}}>
                  <img src={pompe} className="lazy" style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      POMPES
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Link to="/pompe-details" class="btn btn-primary">
                        EN SAVOIRE PLUS
                      </Link>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card" style={{ backgroundColor: "#ABD473" }}>
                  <img src={dimotor} className="lazy" style={{ width: "100%",height:"370px", padding: "2%" }} />
                  <div class="card-body">
                    <h5 class="card-title" style={{textAlign:"center",color:"white"}}>MOTEURS</h5>
                    <div style={{ display: "flex", justifyContent: "center" }}> 
                    <Link to="/moteur" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card" style={{ backgroundColor: "#ABD473" }}>
                  <img src={ech} className="lazy" style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5 class="card-title" style={{textAlign:"center",color:"white"}}>ECHANGEURS</h5>
                    <div style={{ display: "flex", justifyContent: "center" }}> 
                    <Link to="/echangeur" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
              
              
            </div>
            <div class="row" style={{marginTop:"2%"}}>
              
              <div class="col-md-4">
                <div class="card" style={{backgroundColor: "#ABD473"}}>
                <img src={filtre} className="lazy" style={{ width: "100%",height:"370px",padding:"2%" }} />
                  <div class="card-body">
                    <h5 class="card-title" style={{ textAlign: "center",color:"white" }}>Filtres</h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/filter" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card" style={{backgroundColor: "#ABD473"}}>
                <img src={van} className="lazy" style={{ width: "100%",padding:"2%" }} />
                  <div class="card-body">
                    <h5 class="card-title" style={{ textAlign: "center",color:"white" }}>Vannes</h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/vannes" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card" style={{backgroundColor: "#ABD473"}}>
                <img className="lazy" src={comp} style={{ width: "100%",padding:"2%"}} />
                  <div class="card-body">
                    <h5 class="card-title" style={{ textAlign: "center",color:"white" }}>Compresseur</h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/compresseur" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
              
              
            </div>
            <div className="row" style={{marginTop:"2%"}}>
            <div class="col-md-4">
                <div class="card" style={{backgroundColor: "#ABD473"}}>
                <img className="lazy" src={vent} style={{ width: "100%",padding:"2%" }} />
                  <div class="card-body">
                    <h5 class="card-title" style={{ textAlign: "center",color:"white" }}>Ventilateur</h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/ventilateur" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card" style={{backgroundColor: "#ABD473"}}>
                <img src={bro} className="lazy" style={{ width: "100%",padding:"2%" }} />
                  <div class="card-body">
                    <h5 class="card-title" style={{ textAlign: "center",color:"white" }}>BROYEURS</h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to="/broyeur" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div className="card"  style={{backgroundColor: "#ABD473"}}>
                  <img src={agi} className="lazy" style={{ width: "100%",padding:"2%" }} />
                  <div class="card-body">
                    
                    <h5 class="card-title" style={{ textAlign: "center",color:"white" }}>
                      AGITATEURS
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}> 
                    <Link to="/agitateur" class="btn btn-primary">
                      EN SAVOIRE PLUS
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}