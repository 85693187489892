import styles from "./page.module.css";

const b = require("../../assets/inst-bg.png");
const w = require("../../assets/INSTRUMENTATION.png");
const c = require("../../assets/INSTRUMENTATION1.png");
const d = require("../../assets/INSTRUMENTATION2.png");
const a = require("../../assets/INSTRUMENTATION3.png");
const e = require("../../assets/INSTRUMENTATION4.png");
const ut1 = require("../../assets/bg1.png");
const ut2 = require("../../assets/bg2.png");
const ut3 = require("../../assets/bg3.png");
const bg = require("../../assets/back1.png");

export default function Catagory3(params) {

  return (
    <>
      <div className={styles.divise}>
        <div style={{ textAlign: "center" }}>
          <h1 className={styles.font_}>
            L’APPROVISIONNEUR TECHNIQUE INTERNATIONAL SA
          </h1>
          <h5 className={styles.second_font}>
            votre partenaire de confiance pour la fourniture de
            l’instrumentation et l’automatisme.
          </h5>
          <hr
            style={{
              width: "70%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          
        </div><img src={bg} style={{width:"100%"}} />
      </div>
      <div className={styles.divise}>
        <div style={{ textAlign: "left", padding: "5%" }}>
          <h3 className={styles.font_}>INSTRUMENTATION DE MESURE</h3>
          <hr style={{ width: "44%" }} />
        </div>
        
        <img src={b} style={{width:"100%"}} />

        {/* <div class="container" style={{marginTop:"5%"}}>
            <div class="row" >
              <div class="col-md-3">
                <div className={styles.card}>
                <div class="card" >
                  <img src={c} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style={{ textAlign: "center", color: "white" }}
                    >
                      MESURE DE NIVEAU <br/><p style={{textAlign:"center",fontSize:"13px"}}>POUR LIQUIDES ET SOLIDES</p>
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p>
                      Transmetteurs de niveau radar, à ultrasons, à tube
plongeur, par pression différentielle. Détecteurs à lame
vibrateur... etc
                      </p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card" style={{ backgroundColor: "gray" }}>
                  <img src={a} style={{ width: "100%", padding: "2%" }} />
                  <div class="card-body">
                    <h5 class="card-title" style={{textAlign:"center",color:"white"}}>MESURE DE PRESSION</h5>
                    <div style={{ display: "flex", justifyContent: "center" }}> 
                    Transmetteurs de pression pour mesure de pression
absolue, relative et différentielle... etc.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div className="card"  style={{backgroundColor: "gray"}}>
                  <img src={e} style={{ width: "100%",padding:"2%" }} />
                  <div class="card-body">
                    
                    <h5 class="card-title" style={{ textAlign: "center",color:"white" }}>
                    MESURE DE DÉBIT
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}> 
                    Débimètres électromagnétiques, à effet Coriolis, à ultrasson, à section variable... etc.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div className="card"  style={{backgroundColor: "gray"}}>
                  <img src={d} style={{ width: "100%",padding:"2%" }} />
                  <div class="card-body">
                    
                    <h5 class="card-title" style={{ textAlign: "center",color:"white" }}>
                    MESURE DE TEMPÉRATURE
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}> 
                       <p>
                        Sondes et capteurs de température pour différentes
applications industrielles “HEAVY DUTY”, haute température, hygiéniques... etc. Avec transmetteurs et accessoires
</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div> */}
        <div className="container-xxl py-6 ">
      <div class="container">
      <div class="row" style={{marginTop:"6%"}}>
        <div class="col-lg-3">
          <div className={styles.service_item}>
            <div>
                <img src={e} style={{width:"100%"}} />
            </div>
            <div style={{marginTop:"10%"}}>
            <h4 className={styles.service_item_h4}>MESURE DE DÉBIT</h4>
            <p className={styles.service_item_p}>Débimètres électromagnétiques, à effet Coriolis, à ultrasson, à section variable... etc.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div className={styles.service_item}>
            <div>
              <img src={a} style={{width:"100%"}} />
            </div>
            <div style={{marginTop:"10%"}}>
            <h4 className={styles.service_item_h4}>MESURE DE PRESSION</h4>
            <p className={styles.service_item_p}>You are allowed to use the Chain App Dev HTML template. Feel free to modify or edit this layout.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div className={styles.service_item}>
            <div>
               <img src={c} style={{width:"100%"}} />
            </div>
            <div style={{marginTop:"10%"}}>
            <h4 className={styles.service_item_h4}>MESURE DE NIVEAU <br/><p style={{fontSize:"13px"}}>POUR LIQUIDES ET SOLIDES</p></h4>
            <p className={styles.service_item_p}>Transmetteurs de niveau radar, à ultrasons, à tube plongeur, par pression différentielle. Détecteurs à lame vibrateur... etc</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
        <div className={styles.service_item}>
            <div>
               <img src={d} style={{width:"100%"}} />
            </div>
            <div style={{marginTop:"10%"}}>
            <h4 className={styles.service_item_h4}>MESURE DE TEMPÉRATURE</h4>
            <p className={styles.service_item_p}> Sondes et capteurs de température pour différentes
applications industrielles “HEAVY DUTY”, haute température, hygiéniques... etc. Avec transmetteurs et accessoires</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
      </div>



      <div className={styles.divise}>
        <div style={{ textAlign: "left", padding: "5%" }}>
          <h3 className={styles.font_}>COMPOSANTS ET SYSTEMES D’AUTOMATISME INDUSTRIEL</h3>
          <hr style={{ width: "80%" }} />
        </div>
        
        <img src={w} style={{width:"100%"}} />

        <div className="container-xxl py-6">
      <div class="container">
      <div class="row" style={{marginTop:"6%"}}>
        <div class="col-lg-4">
          <div className={styles.service_item1}>
            <div>
                <img src={ut1} style={{width:"100%"}} />
            </div>
            <div style={{marginTop:"10%"}}>
            <h4 className={styles.service_item_h4}>Composants pour automatisation industrielle</h4>
            <p className={styles.service_item_p}>
              <ul style={{listStyleType:"circle"}}>
                <li>Alimentations industrielles.</li>
                <li>commutateurs.</li>
                <li>Controlleurs.</li>
                <li>Relais et solénoides industriels.</li>
                <li>Compteurs et tachymetres.</li>
                <li>Modules E/S .</li>
                <li>Relais.</li>
              </ul>
            </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div className={styles.service_item1}>
            <div>
              <img src={ut2} style={{width:"100%"}} />
            </div>
            <div style={{marginTop:"10%"}}>
            <h4 className={styles.service_item_h4}>Capteurs</h4>
            <p className={styles.service_item_p}>
            <ul style={{listStyleType:"circle"}}>
                <li>Magnétiques.</li>
                <li>Capacitifs.</li>
                <li>Optiques.</li>
                <li>De déplacement linéaire.</li>
                <li>De mouvement et de position.</li>
                <li>De force et cellules de charges.</li>
              </ul>
            </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div className={styles.service_item1}>
            <div>
               <img src={ut3} style={{width:"100%"}} />
            </div>
            <div style={{marginTop:"10%"}}>
            <h4 className={styles.service_item_h4}>Composants passifs</h4>
            <p className={styles.service_item_p}>
            <ul style={{listStyleType:"circle"}}>
                <li>Codeurs.</li>
                <li>Condensateurs.</li>
                <li>Contrôleurs de fréquence.</li>
                <li>Potentiomètres.</li>
                <li>Résistances.</li>
                <li>Thermistances.</li>
              </ul>
            </p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    </div>
      </div>
      

    </>
  );
}
