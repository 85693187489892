import "./App.css";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import Societe from "./components/societe/Societe";
import Contact from "./components/contact/Contact";
import Service from "./components/service/Service";
import Marque from "./components/marque/Marque";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Acceuil from "./components/acceuil/Acceuil";
import Footer from "./components/footer/Fouter";
import PageNotFound from "./components/NotFound/PageNotFound";
import About from "./components/about/About";
import Product from "./components/product/Product";
import Category1 from "./components/categories/Category1";
import Category2 from "./components/categories/Catagory2";
import PompeDetails from "./components/details/PompeDetails";
import Agitateur from "./components/details/Agitateur";
import Prelev from "./components/eqp-labo/prelev";
import TestEng from "./components/test/TestEng";
import Echangeur from "./components/details/Echangeur";
import Broyeur from "./components/details/Broyeur";
import Filter from "./components/details/Filter";
import Project from "./components/test/Project";
import Mesure from "./components/eqp-labo/Mesure";
import Didac from "./components/eqp-labo/Didac";
import Catagory3 from "./components/categories/Category3";
import Category4 from "./components/categories/Category4";
import Chqdevir from "./components/chq/Chqdiver";
import DetailsProject from "./components/test/DetailsProject";
import Testimonial from "./components/test/Testmonial";
import Epi from "./components/epi/Epi";
import Vanne from "./components/details/Vannes";
import Compresseur from "./components/details/Compresseur";
import Ventilateur from "./components/details/Ventilateur";
import Iso from "./components/Iso/Iso";
import Bread from "./components/Breadcrumbs/Bread";
import Button_top from "./components/buttontop/Button_Top";
import Flex from "./components/test/testFlex";
import TestProjet from "./components/test/TestProject";
import React from "react";
import Parag from "./components/test/parag";
import Moteur from "./components/details/Moteur";

function App() {
  return (
    <Router>
      <div>
        <Header />
      </div>

      <Routes>
        <Route exact path="/error" element={<Home />}>
          {" "}
        </Route>

        <Route exact path="/Societes" element={<Societe />}>
          {" "}
        </Route>

        <Route exact path="/contact-us" element={<Contact />}></Route>

        <Route exact path="/services" element={<Service />}></Route>

        <Route exact path="/marques" element={<Marque />}></Route>

        <Route exact path="/" element={<Acceuil />}></Route>

        <Route exact path="/about" element={<About />}></Route>

        <Route path="*" element={<PageNotFound />}></Route>

        <Route path="/products" element={<Product />}></Route>

        <Route path="/equipement-process" element={<Category1 />}></Route>

        <Route path="/equipements-laboratoire" element={<Category2 />}></Route>

        <Route path="/pompe-details" element={<PompeDetails />}></Route>

        <Route path="/agitateur" element={<Agitateur />}></Route>

        <Route path="/materiels-de-prelevement" element={<Prelev />}></Route>

        <Route path="/test" element={<TestEng />}></Route>

        <Route path="echangeur" element={<Echangeur />}></Route>

        <Route path="broyeur" element={<Broyeur />}></Route>

        <Route path="filter" element={<Filter />}></Route>

        <Route path="nos-projets" element={<Project />}></Route>

        <Route path="mesure" element={<Mesure />}></Route>

        <Route path="didactique" element={<Didac />}></Route>

        <Route path="equipement-instrumenstation" element={<Catagory3 />} ></Route>

        <Route path="cat4" element={<Category4 />}></Route>

        <Route path="chimique-diver" element={<Chqdevir />}></Route>

        <Route path="details-projets" element={<DetailsProject />}></Route>

        <Route path="testmonial" element={<Testimonial />}></Route>

        <Route path="epi" element={<Epi />}></Route>

        <Route path="vannes" element={<Vanne />}></Route>

        <Route path="compresseur" element={<Compresseur />}></Route>

        <Route path="ventilateur" element={<Ventilateur />}></Route>

        <Route path="iso" element={<Iso />}></Route>

        <Route path="bread" element={<Bread />}></Route>

        <Route path="ert" element={<Flex />}></Route>

        <Route path="test-projet" element={<TestProjet />}></Route>

        <Route path="parag" element={<Parag />}></Route>

        <Route path="moteur" element={<Moteur />}></Route>
      </Routes>

      <Button_top />
      <Footer />
    </Router>
  );
}

export default App;
